import { z } from 'zod';
import { BaseClaimSchema, ExternalSystemIdSchema, ExternalSystemRecordSchema, } from '../model/base.js';
import { ClaimEventSchema, claimEventType } from './claim.js';
export const ExternalSystemIdUpsertedEventSchema = ClaimEventSchema.merge(z.object({
    type: claimEventType('externalSystemIdUpserted'),
    metadata: ClaimEventSchema.shape.metadata.and(z.object({
        upserted: ExternalSystemIdSchema,
        // indicates that this value is "added" vs "updated"
        wasUpdated: z.boolean(),
    })),
    claim: BaseClaimSchema.merge(z.object({
        externalSystemIds: ExternalSystemRecordSchema,
    })),
}));
export const ExternalSystemIdRemovedEventSchema = ClaimEventSchema.merge(z.object({
    type: claimEventType('externalSystemIdRemoved'),
    metadata: ClaimEventSchema.shape.metadata.and(z.object({
        removed: ExternalSystemIdSchema,
    })),
    claim: BaseClaimSchema.merge(z.object({
        externalSystemIds: ExternalSystemRecordSchema,
    })),
}));
