import z from 'zod';
export const StepFunctionTaskContextSchema = z.object({
    Execution: z.object({
        Id: z.string(),
        Name: z.string(),
        RoleArn: z.string(),
        StartTime: z.string(),
    }),
    State: z.object({
        EnteredTime: z.string(),
        Name: z.string(),
        RetryCount: z.number(),
    }),
    StateMachine: z.object({
        Id: z.string(),
        Name: z.string(),
    }),
    Task: z.object({
        Token: z.string(),
    }),
});
