import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { JobIdSchema, JobSchema } from '../../base/index.js';
import { WorkDetailsSchema } from '../../base/workDetails.js';
export const ReceiveWorkDetailsRequestBodySchema = z.object({
    workDetails: WorkDetailsSchema,
});
export const ReceiveWorkDetailsRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
});
export const ReceiveWorkDetailsRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const ReceiveWorkDetailsRequestSchema = ReceiveWorkDetailsRequestBodySchema.merge(ReceiveWorkDetailsRequestHeadersSchema).merge(ReceiveWorkDetailsRequestPathSchema);
export const ReceiveWorkDetailsResponseSchema = z.object({ job: JobSchema });
export const ReceiveWorkDetailsApiSchema = {
    body: ReceiveWorkDetailsRequestBodySchema,
    headers: ReceiveWorkDetailsRequestHeadersSchema,
    params: ReceiveWorkDetailsRequestPathSchema,
    response: ReceiveWorkDetailsResponseSchema,
};
