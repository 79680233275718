import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { CornSchema } from '@eigtech/shared-corn';
import { z } from 'zod';
import { DocumentSchemaV2 } from '../aggregates/index.js';
export const UpdateDocumentApiSchema = {
    headers: ApiLambdalerAuthHeaderSchema,
    params: z.object({
        entityId: CornSchema,
        documentCorn: z.string(),
    }),
    body: DocumentSchemaV2.pick({
        description: true,
        notes: true,
    }).deepPartial(),
};
export const UpdateDocumentRequestSchema = UpdateDocumentApiSchema.body
    .merge(UpdateDocumentApiSchema.params)
    .merge(UpdateDocumentApiSchema.headers);
