import { z } from 'zod';
import { BookCornSchema, BookSchema } from '../../../base/books.js';
import { ContactSchema } from '../../../base/contacts.js';
export const GetBookRequestPathParametersSchema = z.object({
    bookId: BookCornSchema,
});
export const GetBookRequestSchema = GetBookRequestPathParametersSchema;
export const GetBookResponseSchema = BookSchema.extend({
    contacts: ContactSchema.array(),
});
export const GetBookApiSchema = {
    params: GetBookRequestPathParametersSchema,
    response: GetBookResponseSchema,
};
