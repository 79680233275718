import { ContactCornSchema } from '@eigtech/contacts-types';
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { JobExternalIdSchema, JobIdSchema } from '../../base/index.js';
export const RecordDepositPaidRequestBodySchema = z.object({
    paidBy: ContactCornSchema.or(z.string()),
    paidDate: z.string().datetime(),
    paidInFull: z.boolean(),
    externalId: JobExternalIdSchema.optional(),
});
export const RecordDepositPaidRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
});
export const RecordDepositPaidRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const RecordDepositPaidRequestSchema = RecordDepositPaidRequestBodySchema.merge(RecordDepositPaidRequestHeadersSchema).merge(RecordDepositPaidRequestPathSchema);
export const RecordDepositPaidResponseSchema = z.void();
export const RecordDepositPaidApiSchema = {
    body: RecordDepositPaidRequestBodySchema,
    headers: RecordDepositPaidRequestHeadersSchema,
    params: RecordDepositPaidRequestPathSchema,
};
