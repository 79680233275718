import { DocumentCornSchema } from '@eigtech/documents-types';
import { eventType } from '@eigtech/event-stream-types';
import { NoteSchema } from '@eigtech/notes-types';
import z from 'zod';
import { XAImportAssignmentRequestSchema } from './assignment.js';
import { XAStatusEnum } from './status.js';
export const VediImportStateSchema = z.enum(['sent', 'failed', 'confirmed', 'pending']);
export const VediImportState = VediImportStateSchema.Enum;
export const VediImportTypeSchema = z.enum([
    'assignment',
    'assignRecipient',
    'document',
    'note',
    'status',
]);
export const VediImportType = VediImportTypeSchema.Enum;
// Base import
export const VediImportSchema = z.object({
    claimNumber: z.string(),
    dateRecorded: z.string().datetime(),
    expiresAt: z.number().optional(),
    error: z.string().optional(),
    importState: VediImportStateSchema,
    importType: VediImportTypeSchema,
    lastUpdated: z.string().datetime(),
    originatingEvent: eventType().optional(),
    transactionId: z.string().optional(),
    user: z.string(),
});
export const isVediImport = (result) => {
    return VediImportSchema.safeParse(result).success;
};
// Assignment import
export const VediAssignmentImportSchema = VediImportSchema.merge(XAImportAssignmentRequestSchema).merge(z.object({
    importType: z.literal(VediImportType.assignment),
}));
export const isVediAssignmentImport = (result) => {
    return VediAssignmentImportSchema.safeParse(result).success;
};
// Assign Recipient import
export const VediAssignRecipientImportSchema = VediImportSchema.required({
    transactionId: true,
}).merge(z.object({ importType: z.literal(VediImportType.assignRecipient), xactnetId: z.string() }));
export const isVediAssignRecipientImport = (result) => {
    return VediAssignRecipientImportSchema.safeParse(result).success;
};
// Document import
export const VediDocumentImportSchema = VediImportSchema.required({
    transactionId: true,
}).merge(z.object({
    description: z.string(),
    documentId: DocumentCornSchema,
    fileName: z.string(),
    importType: z.literal(VediImportType.document),
    mimeBoundary: z.string(),
    mimeType: z.string().optional(),
    preApprove: z.boolean(),
    s3Location: z.object({ bucket: z.string(), key: z.string() }),
    sendToXm8: z.boolean(),
    transactionId: z.string(),
}));
export const isVediDocumentImport = (result) => {
    return VediDocumentImportSchema.safeParse(result).success;
};
// Note import
export const VediNoteImportSchema = VediImportSchema.required({ transactionId: true }).merge(z.object({
    importType: z.literal(VediImportType.note),
    note: NoteSchema,
    pages: z
        .object({
        pageContent: z.string(),
        pageError: z.string().optional(),
        pageNumber: z.number(),
        pageStatus: VediImportStateSchema,
    })
        .array(),
}));
export const isVediNoteImport = (result) => {
    return VediNoteImportSchema.safeParse(result).success;
};
// Status import
export const VediStatusImportSchema = VediImportSchema.required({ transactionId: true }).merge(z.object({
    importType: z.literal(VediImportType.status),
    note: z.string(),
    status: XAStatusEnum,
    timestamp: z.string().datetime(),
}));
export const isVediStatusImport = (result) => {
    return VediStatusImportSchema.safeParse(result).success;
};
export const VediImportFilterOptionsSchema = VediImportSchema.omit({ importState: true })
    .partial()
    .merge(z.object({
    importStates: VediImportStateSchema.array().optional(),
}));
