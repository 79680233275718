import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { JobIdSchema, JobSchema } from '../../base/index.js'
import { WorkDetailsSchema } from '../../base/workDetails.js'

export const ReceiveWorkDetailsRequestBodySchema = z.object({
  workDetails: WorkDetailsSchema,
})
export interface ReceiveWorkDetailsRequestBody
  extends z.infer<typeof ReceiveWorkDetailsRequestBodySchema> {}

export const ReceiveWorkDetailsRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})
export interface ReceiveWorkDetailsRequestHeaders
  extends z.infer<typeof ReceiveWorkDetailsRequestHeadersSchema> {}

export const ReceiveWorkDetailsRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export interface ReceiveWorkDetailsRequestPath
  extends z.infer<typeof ReceiveWorkDetailsRequestPathSchema> {}

export const ReceiveWorkDetailsRequestSchema = ReceiveWorkDetailsRequestBodySchema.merge(
  ReceiveWorkDetailsRequestHeadersSchema
).merge(ReceiveWorkDetailsRequestPathSchema)
export interface ReceiveWorkDetailsRequest
  extends z.infer<typeof ReceiveWorkDetailsRequestSchema> {}

export const ReceiveWorkDetailsResponseSchema = z.object({ job: JobSchema })
export type ReceiveWorkDetailsResponse = z.infer<typeof ReceiveWorkDetailsResponseSchema>

export const ReceiveWorkDetailsApiSchema: ApiSchema = {
  body: ReceiveWorkDetailsRequestBodySchema,
  headers: ReceiveWorkDetailsRequestHeadersSchema,
  params: ReceiveWorkDetailsRequestPathSchema,
  response: ReceiveWorkDetailsResponseSchema,
}

export interface ReceiveWorkDetailsHandler
  extends Handler<ReceiveWorkDetailsRequest, ReceiveWorkDetailsResponse> {}
