import { z } from 'zod';
import { ClaimEventType } from '../events/claim.js';
import { EstimateEventType, } from '../events/estimate.js';
// These types are specific to claim statuses in the read model
export const ClaimStatusRelatedEventTypeSchema = z.enum([
    ClaimEventType.claimCreated,
    ClaimEventType.inspectionCanceled,
    ClaimEventType.inspectionCompleted,
    ClaimEventType.inspectionScheduled,
    ClaimEventType.closed,
    ClaimEventType.reInspectionRequested,
    ClaimEventType.reopened,
    EstimateEventType.received,
    EstimateEventType.reviewed,
]);
export const ClaimStatusRelatedEventType = ClaimStatusRelatedEventTypeSchema.Enum;
export const isClaimStatusRelatedEvent = (eventType) => ClaimStatusRelatedEventTypeSchema.safeParse(eventType).success;
export var ClaimStatus;
(function (ClaimStatus) {
    ClaimStatus["carrier"] = "Carrier Review";
    ClaimStatus["coordinator"] = "Coordinator Review";
    ClaimStatus["closed"] = "Closed";
    ClaimStatus["inspection"] = "Inspection";
    ClaimStatus["qa"] = "QA Review";
    ClaimStatus["rejected"] = "Rejected";
})(ClaimStatus || (ClaimStatus = {}));
export const ClaimStatusSchema = z.enum([
    ClaimStatus.carrier,
    ClaimStatus.closed,
    ClaimStatus.coordinator,
    ClaimStatus.inspection,
    ClaimStatus.qa,
    ClaimStatus.rejected,
]);
