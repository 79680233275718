import { z } from 'zod';
import { BaseClaimSchema, ClaimNumberSchema } from '../model/base.js';
import { CustomerCommunicationSchema } from '../model/customerCommunication.js';
import { PublicationTargetsSchema } from '../model/publishable.js';
import { ClaimEventSchema, ClaimEventType, claimEventType } from './claim.js';
export const CustomerCommunicationAddedEventSchema = ClaimEventSchema.merge(z.object({
    claim: BaseClaimSchema.merge(z.object({
        customerCommunication: CustomerCommunicationSchema,
    })),
    metadata: ClaimEventSchema.shape.metadata.and(z.object({
        publishToXa: z.boolean(),
    })),
}));
export const CustomerContactedByEmailEventSchema = CustomerCommunicationAddedEventSchema.merge(z.object({
    type: claimEventType(ClaimEventType.customerContactedEmail),
}));
export const CustomerContactedByLvmEventSchema = CustomerCommunicationAddedEventSchema.merge(z.object({
    type: claimEventType(ClaimEventType.customerContactedLvm),
}));
export const CustomerNotContactedEventSchema = CustomerCommunicationAddedEventSchema.merge(z.object({
    type: claimEventType(ClaimEventType.customerNoContact),
}));
export const InitialCustomerContactAttemptedEventSchema = CustomerCommunicationAddedEventSchema.merge(z.object({
    type: claimEventType(ClaimEventType.initialCustomerContactAttempted),
}));
export const InitialCustomerContactFailedEventSchema = CustomerCommunicationAddedEventSchema.merge(z.object({
    type: claimEventType(ClaimEventType.initialCustomerContactFailed),
}));
export const InitialCustomerContactSuccessEventSchema = CustomerCommunicationAddedEventSchema.merge(z.object({
    type: claimEventType(ClaimEventType.initialCustomerContactSuccess),
}));
export const InvalidContactInformationEventSchema = CustomerCommunicationAddedEventSchema.merge(z.object({
    type: claimEventType(ClaimEventType.invalidContactInformation),
}));
export const CustomerContactedEventInputSchema = z
    .object({
    claimNumber: ClaimNumberSchema,
    publicationTargets: PublicationTargetsSchema.array().optional(),
    requestActor: z.string(),
})
    .merge(CustomerCommunicationSchema);
export const CustomerContactedEventMarthaSchema = CustomerContactedEventInputSchema.omit({
    type: true,
});
