import z from 'zod';
import { StepFunctionTaskContextSchema } from './context.js';
import { IncrementPageResponseSchema } from './increment.js';
export const BuildTimelinesInputSchema = z.object({
    incrementPageResults: IncrementPageResponseSchema,
});
export const BuildTimelinesRequestSchema = z.object({
    context: StepFunctionTaskContextSchema,
    input: BuildTimelinesInputSchema,
});
