import { z } from 'zod';
import { ScreenshotSchema } from '../base/screenshot.js';
import { MeetingsServiceEventBaseSchema, serviceEventType } from './service.js';
export const screenshotEventType = (eventName) => serviceEventType('screenshot', eventName);
export const ScreenshotEventSchema = MeetingsServiceEventBaseSchema.merge(z.object({
    type: screenshotEventType(),
    screenshot: z.unknown(),
}));
export const ScreenshotAddedEventSchema = ScreenshotEventSchema.merge(z.object({
    type: screenshotEventType('added'),
    screenshot: ScreenshotSchema.merge(z.object({
        entityId: z.string(),
        startTime: z.string(),
    })),
}));
