import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
export const GetRegionsBulkUploadUrlApiSchema = {
    headers: ApiLambdalerAuthHeaderSchema,
    response: z.object({
        fields: z.record(z.string()),
        url: z.string(),
    }),
};
export const GetRegionsBulkUploadUrlRequestSchema = GetRegionsBulkUploadUrlApiSchema.headers;
export const GetRegionsBulkUploadUrlResponseSchema = GetRegionsBulkUploadUrlApiSchema.response;
