import { InspectionDetailsSchema } from '@eigtech/claims-v2-types';
import z from 'zod';
export const GetInspectionsApiSchema = {
    response: z.object({
        inspections: InspectionDetailsSchema.array(),
    }),
    query: z.object({ filter: z.string().optional() }),
};
export const GetInspectionsInternalApiSchema = {
    response: GetInspectionsApiSchema.response,
    query: GetInspectionsApiSchema.query,
};
export const GetInspectionsRequestSchema = GetInspectionsApiSchema.query;
export const GetInspectionsResponseSchema = GetInspectionsApiSchema.response;
