import { Schema, defineTable } from 'squid';
const lossLocationTable = defineTable('loss_location', {
    claim_number: Schema.String,
    address_type: Schema.nullable(Schema.String),
    street_1: Schema.String,
    street_2: Schema.nullable(Schema.String),
    city: Schema.String,
    state: Schema.String,
    county: Schema.nullable(Schema.String),
    zipcode: Schema.String,
    longitude: Schema.nullable(Schema.String),
    latitude: Schema.nullable(Schema.String),
    raw_address: Schema.String,
});
