import { ContactCornSchema } from '@eigtech/contacts-types';
import z from 'zod';
import { ClaimNumberSchema, CompletedInspectionSchema, PublicationTargetsSchema, } from '../model/index.js';
import { BaseHeaderSchema } from './base.js';
export const CompleteClaimInspectionApiSchema = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: z.object({
        completedBy: ContactCornSchema.or(z.string()),
        dateCompleted: z.string().datetime(),
        inspectionId: z.string(),
        notes: z.string().optional(),
        publicationTargets: PublicationTargetsSchema.array().optional(),
    }),
    headers: BaseHeaderSchema,
    response: z
        .object({
        claimNumber: ClaimNumberSchema,
    })
        .merge(CompletedInspectionSchema),
};
export const CompleteClaimInspectionRequestSchema = CompleteClaimInspectionApiSchema.params
    .merge(CompleteClaimInspectionApiSchema.body)
    .merge(CompleteClaimInspectionApiSchema.headers);
