import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
export const GetVendorRatesBulkUploadUrlApiSchema = {
    headers: ApiLambdalerAuthHeaderSchema,
    response: z.object({
        fields: z.record(z.string()),
        url: z.string(),
    }),
};
export const GetVendorRatesBulkUploadUrlRequestSchema = GetVendorRatesBulkUploadUrlApiSchema.headers;
export const GetVendorRatesBulkUploadUrlResponseSchema = GetVendorRatesBulkUploadUrlApiSchema.response;
