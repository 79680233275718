import { CohortEnumSchema } from '@eigtech/auth0-types';
import { CornSchema } from '@eigtech/shared-corn';
import { z } from 'zod';
import { documentsServiceCorn } from '../base/index.js';
export const DocumentCornSchema = documentsServiceCorn('document');
export const DocumentMetadataSchema = z.object({
    name: z.string(),
    mimeType: z.string(),
    fileSizeBytes: z.number(),
    createdByCohort: CohortEnumSchema.optional(),
});
export const DocumentSourceSchema = z.enum([
    'Verisk',
    'CSR',
    'TPLAP',
    'Estimator',
    'Meetings',
    'Technician',
    'Symbility',
    'Documator',
]);
export const DocumentSource = DocumentSourceSchema.Enum;
export const PublicationTargetsSchema = z.enum([
    'XactAnalysis',
    'documents',
    'Xactimate',
    'Symbility',
    'meetings',
]);
export const PublicationTargets = PublicationTargetsSchema.Values;
export const DocumentPublicationAttributesSchema = z.object({
    entityId: CornSchema,
    additionalCohorts: CohortEnumSchema.array(),
});
export const DocumentSchemaV1 = z.object({
    id: z.string(),
    claimNumber: z.string(),
    filename: z.string(),
    notes: z.string(),
    description: z.string().optional(),
    type: z.string().optional(),
    userCohorts: CohortEnumSchema.array(),
    parentFilename: z.string().optional(),
    source: DocumentSourceSchema,
    metaData: DocumentMetadataSchema.optional(),
    createdDate: z.string().optional(),
    createdBy: z.string().optional(),
    modifiedBy: z.string().optional(),
    modifiedDate: z.string().optional(),
});
export const DocumentSchemaV2 = DocumentSchemaV1.merge(z.object({
    entityId: CornSchema,
    createdByUserId: z.string().optional(),
    externalSystemId: z.string().optional(),
})).omit({
    claimNumber: true,
});
export const DocumentDataSourceSchema = z.enum(['Verisk', 'Symbility']);
export const DocumentDataSource = DocumentDataSourceSchema.Values;
export const DocumentRecordSchemaV1 = DocumentSchemaV1.merge(z.object({
    PK: z.string(),
    SK: z.string(),
}));
export const DocumentRecordSchemaV2 = DocumentSchemaV2.merge(z.object({
    PK: z.string(),
    SK: z.string(),
    userCohorts: z.set(CohortEnumSchema),
}));
export const DocumentPublicationRecordSchema = z.object({
    PK: z.string(),
    SK: z.string(),
    target: PublicationTargetsSchema,
    publicationAttributes: DocumentPublicationAttributesSchema.or(z.record(z.string(), z.unknown()).optional()),
    publishedAt: z.string(),
});
