import { z } from 'zod';
export const DocumentCornParamSchema = z.object({
    documentCorn: z.string(),
});
export const S3KeySchema = z.object({
    key: z.string(),
});
export const S3BucketNameSchema = z.object({
    bucketName: z.string(),
});
export const S3BucketWithPrefixSchema = S3BucketNameSchema.merge(z.object({ prefix: z.string() }));
export const DocumentCountSchema = z.object({ success: z.number(), failures: z.string().array() });
