import { Schema, defineTable } from 'squid';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const subcontractorDocumentTable = defineTable('subcontractor_document', {
    id: Schema.String,
    job_id: Schema.String,
    subcontractor_id: Schema.String,
    external_id: Schema.nullable(Schema.String),
    kind: Schema.String,
    received_date: Schema.String,
    received_method: Schema.String,
    issued_date: Schema.nullable(Schema.String),
    issued_method: Schema.nullable(Schema.String),
});
