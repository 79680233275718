import { ContactCornSchema } from '@eigtech/contacts-types';
import z from 'zod';
import { ClaimNumberSchema, InspectorRoleSchema, RequestedInspectionSchema, } from '../model/index.js';
import { BaseHeaderSchema } from './base.js';
export const RequestClaimReInspectionApiSchema = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: z.object({
        requestedBy: ContactCornSchema.or(z.string()),
        inspectorRole: InspectorRoleSchema,
        notes: z.string().optional(),
    }),
    headers: BaseHeaderSchema,
    response: z
        .object({
        claimNumber: ClaimNumberSchema,
    })
        .merge(RequestedInspectionSchema),
};
export const RequestClaimReInspectionRequestSchema = RequestClaimReInspectionApiSchema.params
    .merge(RequestClaimReInspectionApiSchema.body)
    .merge(RequestClaimReInspectionApiSchema.headers);
