import z from 'zod';
import { NotebookCornSchema } from './corn.js';
import { NoteSchema } from './note.js';
export const NotebookSchema = z.object({
    id: NotebookCornSchema,
    cohort: z.string(), // Get this as enum from Auth0 service
    entityId: z.string(),
    notes: z.array(NoteSchema),
    createdOn: z.string().datetime(),
    modifiedOn: z.string().datetime().optional(),
    modifiedBy: z.string().optional(),
});
