import z from 'zod';
import { ClaimNumberSchema } from '../model/base.js';
import { LossOfUseSchema } from '../model/lossOfUse.js';
import { BaseHeaderSchema } from './base.js';
const UpdateLossOfUseStatusSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
        id: z.string(),
    }),
    body: z.object({
        justification: z.string().optional(),
    }),
    headers: BaseHeaderSchema,
    response: LossOfUseSchema,
};
export const UpdateLossOfUseStatusRequestSchema = UpdateLossOfUseStatusSchemas.params
    .merge(UpdateLossOfUseStatusSchemas.headers)
    .merge(UpdateLossOfUseStatusSchemas.body);
export const UpdateLossOfUseStatusApiSchema = UpdateLossOfUseStatusSchemas;
