import { z } from 'zod';
import { MeetingConcatenationSchema } from '../base/meetingConcatenation.js';
import { MeetingCaptureSchema, MeetingDetailsSchema } from '../index.js';
import { MeetingsServiceEventBaseSchema, serviceEventType } from './service.js';
export const mediaPipelineEntityName = 'mediaPipeline';
const mediaPipelineEventType = (eventName) => serviceEventType(mediaPipelineEntityName, eventName);
export const MediaPipelineEventSchema = MeetingsServiceEventBaseSchema.merge(z.object({
    type: mediaPipelineEventType(),
    pipeline: z.unknown(),
}));
export const MediaCaptureStartedEventSchema = MediaPipelineEventSchema.merge(z.object({
    type: mediaPipelineEventType('captureStarted'),
    pipeline: MeetingCaptureSchema,
    meetingDetails: MeetingDetailsSchema,
}));
export const MediaCaptureStoppedEventSchema = MediaPipelineEventSchema.merge(z.object({
    type: mediaPipelineEventType('captureStopped'),
    pipeline: MeetingCaptureSchema,
    meetingDetails: MeetingDetailsSchema,
}));
export const MediaConcatenationStartedEventSchema = MediaPipelineEventSchema.merge(z.object({
    type: mediaPipelineEventType('concatenationStarted'),
    pipeline: MeetingConcatenationSchema,
    meetingDetails: MeetingDetailsSchema,
}));
export const MediaConcatenationStoppedEventSchema = MediaPipelineEventSchema.merge(z.object({
    type: mediaPipelineEventType('concatenationStopped'),
    pipeline: MeetingConcatenationSchema,
    meetingDetails: MeetingDetailsSchema,
}));
export const isMediaCaptureStartedEvent = (event) => MediaCaptureStartedEventSchema.safeParse(event).success;
export const isMediaCaptureStoppedEvent = (event) => MediaCaptureStoppedEventSchema.safeParse(event).success;
export const isMediaConcatenationStartedEvent = (event) => MediaConcatenationStartedEventSchema.safeParse(event).success;
export const isMediaConcatenationStoppedEvent = (event) => MediaConcatenationStoppedEventSchema.safeParse(event).success;
