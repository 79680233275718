import { z } from 'zod';
import { BookCornSchema, BookSchema } from '../base/books.js';
import { ServiceEventBaseSchema, serviceEventType } from './service.js';
const bookEventType = (eventName) => serviceEventType('book', eventName);
export const BookEventSchema = ServiceEventBaseSchema.merge(z.object({
    book: z.unknown(),
    metadata: ServiceEventBaseSchema.shape.metadata.and(z.object({
        requestActor: z.string().optional(),
    })),
    schemaVersion: z.literal(1),
    type: bookEventType(),
}));
export const BookCreatedEventSchema = BookEventSchema.merge(z.object({
    type: bookEventType('created'),
    book: BookSchema,
}));
export const BookDeletedEventSchema = BookEventSchema.merge(z.object({
    type: bookEventType('deleted'),
    book: z.object({
        bookId: BookCornSchema,
    }),
}));
export const BookMetadataUpdatedEventSchema = BookEventSchema.merge(z.object({
    type: bookEventType('metadataUpdated'),
    book: BookSchema,
}));
export const isBookCreatedEvent = (event) => BookCreatedEventSchema.safeParse(event).success;
export const isBookDeletedEvent = (event) => BookDeletedEventSchema.safeParse(event).success;
export const isBookMetadataUpdatedEvent = (event) => BookMetadataUpdatedEventSchema.safeParse(event).success;
