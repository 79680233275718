import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { BookCornSchema, BookMetadataSchema } from '../../../base/books.js';
export const CreateBookRequestBodySchema = BookMetadataSchema.extend({
    bookId: BookCornSchema.optional(),
});
export const CreateBookRequestHeadersSchema = ApiLambdalerAuthHeaderWithRequestActorSchema.pick({
    requestActor: true,
});
export const CreateBookRequestSchema = CreateBookRequestBodySchema.merge(CreateBookRequestHeadersSchema);
export const CreateBookResponseSchema = z.object({
    bookId: BookCornSchema,
});
export const CreateBookApiSchema = {
    body: CreateBookRequestSchema,
    headers: CreateBookRequestHeadersSchema,
    response: CreateBookResponseSchema,
};
