import { CohortEnumSchema } from '@eigtech/auth0-types';
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { CornSchema } from '@eigtech/shared-corn';
import { z } from 'zod';
export const GetUploadScreenshotUrlRequestPathSchema = z.object({
    cohort: CohortEnumSchema,
    entityId: CornSchema,
    meetingId: z.string(),
});
export const GetUploadScreenshotUrlRequestHeaderSchema = ApiLambdalerAuthHeaderSchema;
export const GetUploadScreenshotUrlRequestSchema = GetUploadScreenshotUrlRequestPathSchema.merge(GetUploadScreenshotUrlRequestHeaderSchema);
export const GetUploadScreenshotUrlResponseSchema = z.object({
    fields: z.record(z.string()),
    url: z.string(),
});
