import z from 'zod';
import { summarizerServiceCorn } from '../base/index.js';
import { TimelineEntityType, TimelineSchema, TimelineType } from './timeline.js';
export const ClaimTimelineTypeSchema = z.enum([
    TimelineType.default,
    TimelineType.insured,
    TimelineType.status,
    TimelineType.estimator,
    TimelineType.reviewer,
    TimelineType.reporting,
]);
export const ClaimTimelineType = ClaimTimelineTypeSchema.Enum;
export const ClaimTimelineSchema = TimelineSchema.merge(z.object({
    entityType: z.literal(TimelineEntityType.claim),
    timelineType: ClaimTimelineTypeSchema,
}));
export const ClaimInsuredTimelineSchema = ClaimTimelineSchema.merge(z.object({
    timelineId: summarizerServiceCorn('claimInsuredTimeline'),
    timelineType: z.literal(ClaimTimelineType.insured),
}));
export const ClaimDefaultTimelineSchema = ClaimTimelineSchema.merge(z.object({
    timelineId: summarizerServiceCorn('claimDefaultTimeline'),
    timelineType: z.literal(ClaimTimelineType.default),
}));
export const ClaimStatusTimelineSchema = ClaimTimelineSchema.merge(z.object({
    timelineId: summarizerServiceCorn('claimStatusTimeline'),
    timelineType: z.literal(ClaimTimelineType.status),
}));
export const ClaimEstimatorTimelineSchema = ClaimTimelineSchema.merge(z.object({
    timelineId: summarizerServiceCorn('claimEstimatorTimeline'),
    timelineType: z.literal(ClaimTimelineType.estimator),
}));
export const ClaimReviewerTimelineSchema = ClaimTimelineSchema.merge(z.object({
    timelineId: summarizerServiceCorn('claimReviewerTimeline'),
    timelineType: z.literal(ClaimTimelineType.reviewer),
}));
export const ClaimReportingTimelineSchema = ClaimTimelineSchema.merge(z.object({
    timelineId: summarizerServiceCorn('claimReportingTimeline'),
    timelineType: z.literal(ClaimTimelineType.reporting),
}));
