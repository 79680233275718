import { cornType } from '@eigtech/shared-corn';
import { z } from 'zod';
import { serviceName } from '../service.js';
import { TarpingCostSchema } from './cost.js';
const resourceType = 'vendorRates';
export const VendorRatesCornSchema = cornType(serviceName, resourceType);
export const isVendorRatesCorn = (corn) => VendorRatesCornSchema.safeParse(corn).success;
export const makeVendorRatesCorn = (resourceId) => `corn:${serviceName}:${resourceType}:${resourceId}`;
export const VendorRatesSchema = z.object({
    id: VendorRatesCornSchema,
    name: z.string(),
    rates: TarpingCostSchema,
    isTemplate: z.boolean().optional(),
});
