import z from 'zod';
import { AssignableTypeEnum, AssigneeTypeEnumSchema, AssignmentLaxSchema, ClaimAssigneeRelationshipLaxSchema, JobAssigneeRelationshipEnumSchema, VendorRatesAssigneeRelationshipEnumSchema, } from '../entities/assignment.js';
import { AssignmentsServiceEventBaseSchema, serviceEventType } from './service.js';
export const assignedEventType = (entityType) => serviceEventType(entityType, 'assigned');
export const EntityAssignedEventSchema = AssignmentsServiceEventBaseSchema.merge(z.object({
    type: assignedEventType(),
    metadata: z.discriminatedUnion('assignableType', [
        z.object({
            assignableType: z.literal(AssignableTypeEnum.claim),
            assigneeType: AssigneeTypeEnumSchema,
            assigneeRelationship: ClaimAssigneeRelationshipLaxSchema,
            requestActor: z.string(),
        }),
        z.object({
            assignableType: z.literal(AssignableTypeEnum.job),
            assigneeType: AssigneeTypeEnumSchema,
            assigneeRelationship: JobAssigneeRelationshipEnumSchema,
            requestActor: z.string(),
        }),
        z.object({
            assignableType: z.literal(AssignableTypeEnum.vendorRates),
            assigneeType: AssigneeTypeEnumSchema,
            assigneeRelationship: VendorRatesAssigneeRelationshipEnumSchema,
            requestActor: z.string(),
        }),
    ]),
    assignment: AssignmentLaxSchema,
}));
