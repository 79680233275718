import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { JobExternalIdSchema, JobIdSchema } from '../../base/index.js';
export const RecordBillSentToCollectionsRequestBodySchema = z.object({
    billCollection: z.object({
        externalId: JobExternalIdSchema.optional(),
        sentDate: z.string().datetime(),
        collectorName: z.string().optional(),
    }),
});
export const RecordBillSentToCollectionsRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
});
export const RecordBillSentToCollectionsRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const RecordBillSentToCollectionsRequestSchema = RecordBillSentToCollectionsRequestBodySchema.merge(RecordBillSentToCollectionsRequestHeadersSchema).merge(RecordBillSentToCollectionsRequestPathSchema);
export const RecordBillSentToCollectionsResponseSchema = z.void();
export const RecordBillSentToCollectionsApiSchema = {
    body: RecordBillSentToCollectionsRequestBodySchema,
    headers: RecordBillSentToCollectionsRequestHeadersSchema,
    params: RecordBillSentToCollectionsRequestPathSchema,
};
