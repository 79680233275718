import { z } from 'zod';
import { LossDetailsSchema } from '../aggregates/lossDetails.js';
import { ClaimsServiceEventBaseSchema, claimsServiceEventType } from './service.js';
/** @deprecated */
export const lossDetailsEntityName = 'lossDetails';
const lossDetailsEventType = (eventName) => claimsServiceEventType(lossDetailsEntityName, eventName);
/** @deprecated */
export const LossDetailsEventSchema = ClaimsServiceEventBaseSchema.merge(z.object({
    type: lossDetailsEventType(),
    lossDetails: z.unknown(),
}));
/** @deprecated */
export const LossDetailsCreatedEventSchema = LossDetailsEventSchema.merge(z.object({
    type: lossDetailsEventType('created'),
    lossDetails: LossDetailsSchema,
}));
/** @deprecated */
export const LossDetailsUpdatedEventSchema = LossDetailsEventSchema.merge(z.object({
    type: lossDetailsEventType('updated'),
    lossDetails: LossDetailsSchema.partial().merge(LossDetailsSchema.pick({ id: true, claimNumber: true })),
}));
/** @deprecated */
export const isLossDetailsCreatedEvent = (event) => LossDetailsCreatedEventSchema.safeParse(event).success;
/** @deprecated */
export const isLossDetailsUpdatedEvent = (event) => LossDetailsUpdatedEventSchema.safeParse(event).success;
