import { z } from 'zod';
const mergeObjectAndUnion = (x, y) => {
    const [y0, y1, ...ys] = y.options;
    // assert(y0)
    if (!y0) {
        throw new Error('missing y0');
    }
    // assert(y1)
    if (!y1) {
        throw new Error('missing y1');
    }
    const params = [
        y0.merge(x),
        y1.merge(x),
        ...ys.map((yi) => yi.merge(x)),
    ];
    return z.union(params);
};
const mergeUnionAndUnion = (x, y) => {
    const [first, second, ...rest] = x.options.flatMap((xi) => y.options.map((yi) => [xi, yi]));
    // assert(first, 'missing first')
    if (!first) {
        throw new Error('missing first');
    }
    // assert(second, 'missing second')
    if (!second) {
        throw new Error('missing second');
    }
    const [x0, y0] = first;
    const [x1, y1] = second;
    return z.union([
        x0.merge(y0),
        x1.merge(y1),
        ...rest.map(([xi, yi]) => xi.merge(yi)),
    ]);
};
export const zodMerge = (x, y) => 'shape' in x
    ? 'shape' in y
        ? x.merge(y)
        : mergeObjectAndUnion(x, y)
    : 'shape' in y
        ? mergeObjectAndUnion(y, x)
        : mergeUnionAndUnion(x, y);
