import { CornSchema } from '@eigtech/shared-corn';
import z from 'zod';
export const GetScreenshotLabelsRequestPathSchema = z.object({
    entityId: CornSchema,
    meetingId: z.string(),
    startTime: z.string().datetime(),
});
export const GetScreenshotLabelsRequestSchema = GetScreenshotLabelsRequestPathSchema;
export const GetScreenshotLabelsResponseSchema = z.object({
    labels: z.array(z.object({
        label: z.string(),
        value: z.string(),
    })),
});
