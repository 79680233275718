import { Handler } from 'aws-lambda'
import z from 'zod'
import { SuccessApiOpDetailsSchema } from './base/auth0Events/SuccessApiOperationDetails.js'

// path /api/v2/connections
export const ConnectionCreatedBodySchema = SuccessApiOpDetailsSchema.extend({
  request: SuccessApiOpDetailsSchema.shape.request.extend({
    method: z.literal('post'),
    path: z.string(),
    query: z.object({}),
    body: z.object({
      strategy: z.string(),
      name: z.string(),
    }),
  }),
  response: SuccessApiOpDetailsSchema.shape.response.extend({
    body: z.object({
      id: z.string(),
      strategy: z.string(),
      name: z.string(),
      enabled_clients: z.array(z.string()),
      realms: z.array(z.string()),
    }),
  }),
})
export const ConnectionCreatedPathSchema = z.object({
  connectionId: z.string(),
})
export const ConnectionCreatedRequestSchema = ConnectionCreatedBodySchema.merge(
  ConnectionCreatedPathSchema
)

export type ConnectionCreatedRequest = z.infer<typeof ConnectionCreatedRequestSchema>
export type ConnectionCreatedResponse = void

export type ConnectionCreatedHandler = Handler<ConnectionCreatedRequest, ConnectionCreatedResponse>
