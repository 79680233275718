import { z } from 'zod';
import { BookCornSchema } from '../../../base/books.js';
import { ContactCornSchema } from '../../../base/contacts.js';
export const ListBooksByContactRequestPathSchema = z.object({
    contactId: ContactCornSchema,
});
export const ListBooksByContactRequestSchema = ListBooksByContactRequestPathSchema;
export const ListBooksByContactResponseSchema = z.object({
    bookCorns: BookCornSchema.array(),
});
export const ListBooksByContactApiSchema = {
    params: ListBooksByContactRequestPathSchema,
    response: ListBooksByContactResponseSchema,
};
