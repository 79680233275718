import z from 'zod';
import { AttendeeSchema, MeetingSchema } from '../../base/chime.js';
export const RequestMeetingQueryStringSchema = z.object({
    m: z.string().optional(),
    u: z.string().optional(),
});
export const RequestMeetingRequestSchema = RequestMeetingQueryStringSchema;
export const RequestMeetingResponseSchema = z.object({
    meeting: MeetingSchema,
    attendee: AttendeeSchema,
});
