import { z } from 'zod';
import { OrganizationMemberSchema } from '../../logStreamEvents/base/base.js';
export const GetOrgMembersRequestPathSchema = z.object({
    orgId: z.string(),
});
export const GetOrgMembersRequestSchema = GetOrgMembersRequestPathSchema;
export const GetOrgMembersResponseSchema = z.object({
    members: OrganizationMemberSchema.array(),
});
export const GetOrgMembersApiSchema = {
    params: GetOrgMembersRequestPathSchema,
    response: GetOrgMembersResponseSchema,
};
