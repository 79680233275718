import z from 'zod';
import { StepFunctionTaskContextSchema } from './context.js';
import { IncrementPageResponseSchema } from './increment.js';
import { SetupTimelineSyncResponseSchema } from './setup.js';
export const ErrorInputSchema = z.object({
    Cause: z.string(),
    Error: z.string(),
});
export const CompleteTimelineSyncInputSchema = z
    .object({
    setupResults: SetupTimelineSyncResponseSchema,
    incrementPageResults: IncrementPageResponseSchema,
})
    .or(ErrorInputSchema);
export const CompleteTimelineSyncRequestSchema = z.object({
    context: StepFunctionTaskContextSchema,
    input: CompleteTimelineSyncInputSchema,
});
