import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { ClaimNumberSchema, LocationSchema } from '../model/index.js';
export const UpdateLossLocationSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: LocationSchema,
    headers: ApiLambdalerAuthHeaderSchema.pick({ requestActor: true }).required({
        requestActor: true,
    }),
};
export const UpdateLossLocationRequestSchema = UpdateLossLocationSchemas.params
    .merge(UpdateLossLocationSchemas.body)
    .merge(UpdateLossLocationSchemas.headers);
export const UpdateLossLocationResponseSchema = z.object({
    claimNumber: ClaimNumberSchema,
    lossLocation: LocationSchema,
});
export const UpdateLossLocationApiSchema = UpdateLossLocationSchemas;
