import { AssigneeTypeEnumSchema, AssignmentLaxSchema, ClaimAssigneeRelationshipLaxSchema, } from '@eigtech/assignments-types';
import { BaseEstimateSchema, CanceledInspectionSchema, ClaimDataSourceSchema, ClosedOutcomeSchema, CompletedInspectionSchema, CompletedReviewSchema, CustomerCommunicationSchema, DateOfLossSchema, EstimateReviewSchema, LossOfUseReceiptRemovedSchema, LossOfUseReceiptSchema, LossOfUseSchema, PendingReviewSchema, ReopenedOutcomeSchema, RequestedInspectionSchema, ScheduledInspectionSchema, } from '@eigtech/claims-v2-types';
import { VediImportStateSchema } from '@eigtech/verisk-edi-interface-types';
import { z } from 'zod';
export const ClaimAssignmentDetailsSchema = AssignmentLaxSchema.omit({ requestActor: true }).merge(z.object({
    assigneeRelationship: ClaimAssigneeRelationshipLaxSchema.optional(),
    recordedDate: z.string().datetime(),
}));
export const ClaimUnassignmentDetailsSchema = z.object({
    assigneeRelationship: ClaimAssigneeRelationshipLaxSchema.optional(),
    assigneeType: AssigneeTypeEnumSchema.optional(),
    assignableId: z.string(),
    assigneeId: z.string(),
    recordedDate: z.string().datetime(),
});
export const ClaimBasicDetailsSchema = z
    .object({
    recordedDate: z.string().datetime(),
    dataSource: ClaimDataSourceSchema.optional(),
    xaStatus: VediImportStateSchema.optional(),
})
    .passthrough();
export const ClaimClosedDetailsSchema = z.object({
    outcome: ClosedOutcomeSchema,
});
export const ClaimReopenedDetailsSchema = z.object({
    outcome: ReopenedOutcomeSchema,
});
export const ClaimCreatedDetailsSchema = z.object({
    lossDate: DateOfLossSchema,
});
export const ClaimDateReceivedDetailsSchema = z.object({
    dateReceived: z.string().datetime(),
});
export const ClaimLossDateDetailsSchema = z.object({
    lossDate: DateOfLossSchema,
});
export const ClaimEstimateReceivedDetailsSchema = BaseEstimateSchema.merge(z.object({
    recordedDate: z.string().datetime(),
}));
export const ClaimEstimateReviewedDetailsSchema = z.object({
    review: EstimateReviewSchema,
    estimate: BaseEstimateSchema,
    recordedDate: z.string().datetime(),
});
export const ClaimInspectionCompletedDetailsSchema = z.object({
    inspection: CompletedInspectionSchema.partial({ inspectionId: true }),
});
export const ClaimInspectionCanceledDetailsSchema = z.object({
    inspection: CanceledInspectionSchema,
});
export const ClaimInspectionScheduledDetailsSchema = z.object({
    inspection: ScheduledInspectionSchema.partial({ inspectionId: true }),
});
export const ClaimReInspectionRequestedDetailsSchema = z.object({
    inspection: RequestedInspectionSchema,
});
export const ClaimLossOfUseDetailsSchema = z.object({
    lossOfUse: LossOfUseSchema,
});
export const ClaimLossOfUseReceiptAddedDetailsSchema = z.object({
    lossOfUse: LossOfUseSchema.omit({ receipts: true }).merge(z.object({
        receipt: LossOfUseReceiptSchema,
    })),
});
export const ClaimLossOfUseReceiptRemovedDetailsSchema = z.object({
    lossOfUse: LossOfUseSchema.omit({ receipts: true }).merge(z.object({
        receipt: LossOfUseReceiptRemovedSchema,
    })),
});
export const ClaimReviewRequestedDetailsSchema = z.object({
    review: PendingReviewSchema,
});
export const ClaimReviewCompletedDetailsSchema = z.object({
    review: CompletedReviewSchema,
});
export const ClaimCustomerCommunicationDetailsSchema = z.object({
    customerCommunication: CustomerCommunicationSchema,
    xaStatus: VediImportStateSchema.optional(),
    dataSource: ClaimDataSourceSchema.optional(),
});
