import z from 'zod';
import { AssignableTypeEnum, AssigneeTypeEnumSchema, ClaimAssigneeRelationshipLaxSchema, JobAssigneeRelationshipEnumSchema, VendorRatesAssigneeRelationshipEnumSchema, } from '../entities/assignment.js';
import { AssignmentsServiceEventBaseSchema, serviceEventType } from './service.js';
export const unassignedEventType = (entityType) => serviceEventType(entityType, 'unassigned');
export const EntityUnassignedEventMetadataSchema = z.discriminatedUnion('assignableType', [
    z.object({
        assignableType: z.literal(AssignableTypeEnum.claim),
        assigneeType: AssigneeTypeEnumSchema,
        assigneeRelationship: ClaimAssigneeRelationshipLaxSchema,
        requestActor: z.string(),
    }),
    z.object({
        assignableType: z.literal(AssignableTypeEnum.job),
        assigneeType: AssigneeTypeEnumSchema,
        assigneeRelationship: JobAssigneeRelationshipEnumSchema,
        requestActor: z.string(),
    }),
    z.object({
        assignableType: z.literal(AssignableTypeEnum.vendorRates),
        assigneeType: AssigneeTypeEnumSchema,
        assigneeRelationship: VendorRatesAssigneeRelationshipEnumSchema,
        requestActor: z.string(),
    }),
]);
export const EntityUnassignedEventSchema = AssignmentsServiceEventBaseSchema.merge(z.object({
    type: unassignedEventType(),
    metadata: EntityUnassignedEventMetadataSchema,
    assignment: z.object({
        assignableId: z.string(),
        assigneeId: z.string(),
        requestActor: z.string(),
    }),
}));
