import { ClaimStatusEventLaxSchema } from '@eigtech/claims-types';
import { ClaimEventSchema, EstimateEventSchema } from '@eigtech/claims-v2-types';
import { MeetingEndedEventSchema } from '@eigtech/meetings-types';
import { VeriskStatusPublishConfirmedEventSchema } from '@eigtech/verisk-edi-interface-types';
import z from 'zod';
import { TimelineAssignmentsEventSchema } from './assignments.js';
export const ClaimTimelineEventSchema = z.union([
    ClaimEventSchema,
    ClaimStatusEventLaxSchema,
    EstimateEventSchema,
    MeetingEndedEventSchema,
    TimelineAssignmentsEventSchema,
    VeriskStatusPublishConfirmedEventSchema,
]);
