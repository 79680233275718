import { ContactCornSchema } from '@eigtech/contacts-types';
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { DocumentTransitMethodSchema, JobIdSchema } from '../../base/index.js';
export const RecordWorkAuthorizationSentRequestBodySchema = z.object({
    workAuthorization: z.object({
        sentDate: z.string().datetime(),
        recipient: ContactCornSchema.or(z.string()),
        requestId: z.string(),
        sentMethod: DocumentTransitMethodSchema,
    }),
});
export const RecordWorkAuthorizationSentRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
});
export const RecordWorkAuthorizationSentRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const RecordWorkAuthorizationSentRequestSchema = RecordWorkAuthorizationSentRequestBodySchema.merge(RecordWorkAuthorizationSentRequestHeadersSchema).merge(RecordWorkAuthorizationSentRequestPathSchema);
export const RecordWorkAuthorizationSentResponseSchema = z.object({
    jobId: JobIdSchema,
    workAuthorization: z.object({
        workAuthorizationId: z.string(),
        sentDate: z.string().datetime(),
        recipient: ContactCornSchema.or(z.string()),
        sentMethod: DocumentTransitMethodSchema,
    }),
});
export const RecordWorkAuthorizationSentApiSchema = {
    body: RecordWorkAuthorizationSentRequestBodySchema,
    headers: RecordWorkAuthorizationSentRequestHeadersSchema,
    params: RecordWorkAuthorizationSentRequestPathSchema,
    response: RecordWorkAuthorizationSentResponseSchema,
};
