import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { RegionRatesSchema, RegionSchema } from '../../base/index.js';
export const CreateRegionApiSchema = {
    body: z.object({
        name: z.string().min(1),
        regionRates: RegionRatesSchema,
    }),
    headers: ApiLambdalerAuthHeaderWithRequestActorSchema,
    response: z.object({
        region: RegionSchema,
    }),
};
