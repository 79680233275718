import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { JobSummaryFieldSchema, JobSummarySchema } from '../../queryModels/index.js';
import { PageResponsePropertiesSchema, makePageRequestSchema } from '../pagination.js';
export const GetJobSummariesInternalSchemas = {
    query: makePageRequestSchema(JobSummaryFieldSchema),
    response: z
        .object({
        jobs: JobSummarySchema.array(),
    })
        .merge(PageResponsePropertiesSchema),
};
export const GetJobSummariesSchemas = {
    ...GetJobSummariesInternalSchemas,
    headers: ApiLambdalerAuthHeaderSchema.pick({ requestToken: true }).required({
        requestToken: true,
    }),
    response: z
        .object({
        jobs: JobSummarySchema.partial().array(),
    })
        .merge(PageResponsePropertiesSchema),
};
export const GetJobSummariesInternalRequestSchema = GetJobSummariesInternalSchemas.query;
export const GetJobSummariesRequestSchema = GetJobSummariesSchemas.query.merge(GetJobSummariesSchemas.headers);
export const GetJobSummariesInternalResponseSchema = GetJobSummariesInternalSchemas.response;
export const GetJobSummariesResponseSchema = GetJobSummariesSchemas.response;
export const GetJobSummariesApiSchema = GetJobSummariesSchemas;
export const GetJobSummariesInternalApiSchema = GetJobSummariesInternalSchemas;
