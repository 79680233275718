import z from 'zod';
export const CarrierNameEnumSchema = z.enum([
    'Kin Insurance - Evans Claims Contractor',
    'American Integrity - Evans Claims',
    'Universal - Evans Claims',
    'Evans Claims - Staff',
    'TECH - Evans Claims',
    'Security First - EIG',
    'Harbor Claims - Evans Claims',
    'Kin Insurance - Evans Claims',
]);
export const CarrierCodeEnumSchema = z.enum([
    'KIN',
    'KINC',
    'AII',
    'UPC',
    'EIG',
    'EIGT',
    'SFI',
    'HC',
]);
export const CarrierNameEnum = CarrierNameEnumSchema.Values;
export const CarrierCodeEnum = CarrierCodeEnumSchema.Values;
