import z from 'zod';
import { ClaimV1ChronicleType, ClaimV2ChronicleType, EstimateChronicleType, } from './claimChronicles.js';
export const ClaimStatusChronicleTypeSchema = z.enum([
    // #region claim v2 events
    ClaimV2ChronicleType.claimCreated,
    ClaimV2ChronicleType.closed,
    ClaimV2ChronicleType.inspectionCanceled,
    ClaimV2ChronicleType.inspectionCompleted,
    ClaimV2ChronicleType.inspectionScheduled,
    ClaimV2ChronicleType.reInspectionRequested,
    ClaimV2ChronicleType.reopened,
    // #endregion claim v2 events
    // #region claim v1 status events
    ClaimV1ChronicleType.carrierApprovedEstimate,
    ClaimV1ChronicleType.carrierRejectedEstimate,
    ClaimV1ChronicleType.clientApproved,
    ClaimV1ChronicleType.clientRejected,
    ClaimV1ChronicleType.estimateRevisionRequested,
    ClaimV1ChronicleType.fieldAdjusterCorrectionUploaded,
    ClaimV1ChronicleType.fieldAdjusterEstimateUploaded,
    // ClaimV1ChronicleType.fieldAdjusterInspectionCompleted,
    // ClaimV1ChronicleType.fieldAdjusterInspectionScheduled,
    ClaimV1ChronicleType.inAssignQueue,
    ClaimV1ChronicleType.jobCompleted,
    ClaimV1ChronicleType.jobContracted,
    ClaimV1ChronicleType.jobNotSold,
    ClaimV1ChronicleType.jobSold,
    ClaimV1ChronicleType.jobStarted,
    ClaimV1ChronicleType.qaApproved,
    ClaimV1ChronicleType.qaRejected,
    ClaimV1ChronicleType.reInspectionRequested,
    // #endregion claim v1 status events
    // #region estimate events
    EstimateChronicleType.carrierRejected,
    EstimateChronicleType.carrierReviewed,
    EstimateChronicleType.carrierReviewedWithExceptions,
    EstimateChronicleType.clientApproved,
    EstimateChronicleType.clientRejected,
    EstimateChronicleType.qaApproved,
    EstimateChronicleType.qaRejected,
    // #endregion estimate events
]);
export const ClaimStatusChronicleType = ClaimStatusChronicleTypeSchema.Enum;
export const isClaimStatusChronicle = (chronicle) => ClaimStatusChronicleTypeSchema.safeParse(chronicle.type).success;
