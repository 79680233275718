import { z } from 'zod';
import { ContactCornSchema, ContactSchema } from '../../../base/contacts.js';
import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
export const CreateContactApiSchema = {
    body: ContactSchema.omit({ contactId: true }),
    headers: ApiLambdalerAuthHeaderWithRequestActorSchema.pick({ requestActor: true }),
    response: z.object({ contactId: ContactCornSchema }),
};
export const CreateContactRequestBodySchema = CreateContactApiSchema.body;
export const CreateContactRequestSchema = CreateContactApiSchema.body.merge(CreateContactApiSchema.headers);
export const CreateContactResponseSchema = CreateContactApiSchema.response;
