import { ContactCornSchema } from '@eigtech/contacts-types';
import z from 'zod';
import { CanceledInspectionSchema, ClaimNumberSchema, PublicationTargetsSchema, } from '../model/index.js';
import { BaseHeaderSchema } from './base.js';
export const CancelClaimInspectionApiSchema = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: z.object({
        canceledBy: ContactCornSchema.or(z.string()),
        dateCanceled: z.string().datetime(),
        inspectionId: z.string(),
        notes: z.string().optional(),
        publicationTargets: PublicationTargetsSchema.array().optional(),
    }),
    headers: BaseHeaderSchema,
    response: z.object({ claimNumber: ClaimNumberSchema }).merge(CanceledInspectionSchema),
};
export const CancelClaimInspectionRequestSchema = CancelClaimInspectionApiSchema.params
    .merge(CancelClaimInspectionApiSchema.body)
    .merge(CancelClaimInspectionApiSchema.headers);
