import { z } from 'zod';
import { ClaimStatusCanonSchema, ClaimStatusLaxSchema, } from '../aggregates/claimStatus.js';
import { ClaimsServiceEventBaseSchema, claimsServiceEventType } from './service.js';
/** @deprecated */
const statusEventType = (eventName) => claimsServiceEventType('status', eventName);
/** @deprecated */
export const ClaimStatusEventSchema = ClaimsServiceEventBaseSchema.merge(z.object({
    type: statusEventType(),
    status: ClaimStatusCanonSchema.and(z.record(z.unknown())),
}));
/** @deprecated */
export const ClaimStatusEventLaxSchema = ClaimsServiceEventBaseSchema.merge(z.object({
    type: statusEventType(),
    status: ClaimStatusLaxSchema.and(z.record(z.unknown())),
}));
/** @deprecated */
export const LossDateEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('lossDate'),
}));
/** @deprecated */
export const InitialCustomerContactAttemptedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('initialCustomerContactAttempted'),
}));
/** @deprecated */
export const InitialCustomerContactSuccessEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('initialCustomerContactSuccess'),
}));
/** @deprecated */
export const InitialCustomerContactFailedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('initialCustomerContactFailed'),
}));
/** @deprecated */
export const InvalidContactInformationEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('invalidContactInformation'),
}));
/** @deprecated */
export const CustomerNoContactEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('customerNoContact'),
}));
/** @deprecated */
export const FieldAdjusterAssignedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('fieldAdjusterAssigned'),
}));
/** @deprecated */
export const FieldAdjusterReassignedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('fieldAdjusterReassigned'),
}));
/** @deprecated */
export const FieldAdjusterRemovedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('fieldAdjusterRemoved'),
}));
/** @deprecated */
export const FieldAdjusterContactedCustomerEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('fieldAdjusterContactedCustomer'),
}));
/** @deprecated */
export const FieldAdjusterCorrectionUploadedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('fieldAdjusterCorrectionUploaded'),
}));
/** @deprecated */
export const FieldAdjusterInspectionScheduledEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('fieldAdjusterInspectionScheduled'),
    status: ClaimStatusEventSchema.shape.status.and(z.object({
        scheduledDate: z.string().optional(),
    })),
}));
/** @deprecated */
export const FieldAdjusterInspectionCompletedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('fieldAdjusterInspectionCompleted'),
}));
/** @deprecated */
export const JobNotSoldEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('jobNotSold'),
}));
/** @deprecated */
export const FieldAdjusterEstimateUploadedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('fieldAdjusterEstimateUploaded'),
}));
/** @deprecated */
export const ClientApprovedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('clientApproved'),
}));
/** @deprecated */
export const ClientRejectedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('clientRejected'),
}));
/** @deprecated */
export const DateReceivedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('dateReceived'),
}));
/** @deprecated */
export const QaApprovedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('qaApproved'),
}));
/** @deprecated */
export const QaApprovedPreliminaryReportEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('qaApprovedPreliminaryReport'),
}));
/** @deprecated */
export const QaApprovedSupplementReportEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('qaApprovedSupplementReport'),
}));
/** @deprecated */
export const QARejectedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('qaRejected'),
}));
/** @deprecated */
export const EstimateSentToCarrierEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('estimateSentToCarrier'),
}));
/** @deprecated */
export const ClaimInvoiceCreatedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('claimInvoiceCreated'),
}));
/** @deprecated */
export const ReInspectionRequestedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('reInspectionRequested'),
}));
/** @deprecated */
export const EstimateRevisionRequestedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('estimateRevisionRequested'),
}));
/** @deprecated */
export const OTRInvokedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('otrInvoked'),
}));
/** @deprecated */
export const WADSentToCustomerEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('wadSentToCustomer'),
}));
/** @deprecated */
export const CarrierApprovedEstimateEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('carrierApprovedEstimate'),
}));
/** @deprecated */
export const CarrierApprovedEstimateWithExceptionsEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('carrierApprovedEstimateWithExceptions'),
}));
/** @deprecated */
export const CarrierRejectedEstimateEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('carrierRejectedEstimate'),
}));
/** @deprecated */
export const JobContractedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('jobContracted'),
}));
/** @deprecated */
export const JobCompletedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('jobCompleted'),
}));
/** @deprecated */
export const CanceledEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('canceled'),
}));
/** @deprecated */
export const CoordinatorAssignedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('coordinatorAssigned'),
}));
/** @deprecated */
export const CoordinatorRemovedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('coordinatorRemoved'),
}));
/** @deprecated */
export const InAssignQueueEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('inAssignQueue'),
}));
/** @deprecated */
export const SurveySentToCustomerEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('surveySentToCustomer'),
}));
/** @deprecated */
export const CustomerContactedEmailEventSchema = ClaimsServiceEventBaseSchema.merge(z.object({
    type: statusEventType('customerContactedEmail'),
    status: ClaimStatusCanonSchema,
}));
/** @deprecated */
export const CustomerContactedLvmEventSchema = ClaimsServiceEventBaseSchema.merge(z.object({
    type: statusEventType('customerContactedLvm'),
    status: ClaimStatusCanonSchema,
}));
/** @deprecated */
export const JobSoldEventSchema = ClaimsServiceEventBaseSchema.merge(z.object({
    type: statusEventType('jobSold'),
    status: ClaimStatusCanonSchema,
}));
/** @deprecated */
export const JobStartedEventSchema = ClaimsServiceEventBaseSchema.merge(z.object({
    type: statusEventType('jobStarted'),
    status: ClaimStatusCanonSchema,
}));
/** @deprecated */
export const StatusIdUpdatedEventSchema = ClaimsServiceEventBaseSchema.merge(z.object({
    type: statusEventType('idUpdated'),
    status: z.object({
        previous: ClaimStatusCanonSchema,
        current: ClaimStatusCanonSchema,
    }),
}));
/** @deprecated */
export const StatusDeletedEventSchema = ClaimsServiceEventBaseSchema.merge(z.object({
    type: statusEventType('deleted'),
    status: ClaimStatusCanonSchema,
}));
/** @deprecated */
export const ProjectManagerAssignedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('projectManagerAssigned'),
}));
/** @deprecated */
export const ProjectManagerRemovedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('projectManagerRemoved'),
}));
/** @deprecated */
export const ReviewerAssignedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('reviewerAssigned'),
}));
/** @deprecated */
export const ReviewerRemovedEventSchema = ClaimStatusEventSchema.merge(z.object({
    type: statusEventType('reviewerRemoved'),
}));
////////////////
// TypeGuards //
////////////////
/** @deprecated */
export const isFieldAdjusterInspectionScheduledEvent = (event) => FieldAdjusterInspectionScheduledEventSchema.safeParse(event).success;
/** @deprecated */
export const isQaApprovedEvent = (event) => QaApprovedEventSchema.safeParse(event).success;
/** @deprecated */
export const isQaRejectedEvent = (event) => QARejectedEventSchema.safeParse(event).success;
/** @deprecated */
export const isCustomerContactedEmailEvent = (event) => CustomerContactedEmailEventSchema.safeParse(event).success;
/** @deprecated */
export const isCustomerContactedLvmEvent = (event) => CustomerContactedLvmEventSchema.safeParse(event).success;
/** @deprecated */
export const isJobSoldEvent = (event) => JobSoldEventSchema.safeParse(event).success;
/** @deprecated */
export const isJobStartedEvent = (event) => JobStartedEventSchema.safeParse(event).success;
