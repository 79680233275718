import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { ClaimNumberSchema, SerializableReadModelClaimSchema } from '../model/index.js';
export const GetClaimDetailsInternalSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    response: SerializableReadModelClaimSchema.partial(),
};
export const GetClaimDetailsSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    headers: ApiLambdalerAuthHeaderSchema.pick({ requestActor: true, requestToken: true }).required({
        requestActor: true,
        requestToken: true,
    }),
    response: SerializableReadModelClaimSchema.partial().merge(z.object({
        isReadOnly: z.boolean(),
    })),
};
export const GetClaimDetailsInternalRequestSchema = GetClaimDetailsInternalSchemas.params;
export const GetClaimDetailsRequestSchema = GetClaimDetailsSchemas.params.merge(GetClaimDetailsSchemas.headers);
export const GetClaimDetailsResponseSchema = GetClaimDetailsSchemas.response;
export const GetClaimDetailsApiSchema = GetClaimDetailsSchemas;
export const GetClaimDetailsInternalApiSchema = GetClaimDetailsInternalSchemas;
