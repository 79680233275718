import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { CornSchema } from '@eigtech/shared-corn';
import z from 'zod';
import { MeetingDetailsSchema } from '../base/meetingDetails.js';
export const ListMeetingsApiSchema = {
    headers: ApiLambdalerAuthHeaderSchema,
    params: z.object({
        entityId: CornSchema,
    }),
    response: z.array(MeetingDetailsSchema),
};
export const ListMeetingsRequestSchema = ListMeetingsApiSchema.params.merge(ListMeetingsApiSchema.headers);
