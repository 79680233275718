import z from 'zod';
import { RestorationServiceEventBaseSchema, restorationServiceEventType } from './service.js';
export const jobNumberEventType = (eventName) => restorationServiceEventType('jobNumber', eventName);
export const JobNumberEventSchema = RestorationServiceEventBaseSchema.merge(z.object({
    type: jobNumberEventType(),
    jobNumber: z.number(),
}));
// This event is never emitted from the service and is used for internal aggregate tracking only
export const JobNumberIncrementedEventSchema = JobNumberEventSchema.merge(z.object({
    type: jobNumberEventType('incremented'),
    jobNumber: z.number(),
}));
