import { ContactCornSchema } from '@eigtech/contacts-types';
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { JobIdSchema, WorkAuthorizationIdSchema } from '../../base/index.js';
export const RecordWorkAuthorizationOverrideApprovedRequestBodySchema = z.object({
    workAuthorization: z.object({
        effectiveDate: z.string().datetime(),
        approvedBy: ContactCornSchema.or(z.string()),
        workAuthorizationId: WorkAuthorizationIdSchema,
    }),
});
export const RecordWorkAuthorizationOverrideApprovedRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({ requestActor: true });
export const RecordWorkAuthorizationOverrideApprovedRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const RecordWorkAuthorizationOverrideApprovedRequestSchema = RecordWorkAuthorizationOverrideApprovedRequestBodySchema.merge(RecordWorkAuthorizationOverrideApprovedRequestHeadersSchema).merge(RecordWorkAuthorizationOverrideApprovedRequestPathSchema);
export const RecordWorkAuthorizationOverrideApprovedResponseSchema = z.void();
export const RecordWorkAuthorizationOverrideApprovedApiSchema = {
    body: RecordWorkAuthorizationOverrideApprovedRequestBodySchema,
    headers: RecordWorkAuthorizationOverrideApprovedRequestHeadersSchema,
    params: RecordWorkAuthorizationOverrideApprovedRequestPathSchema,
};
