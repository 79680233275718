import { CornSchema } from '@eigtech/shared-corn';
import z from 'zod';
export const AddScreenshotLabelRequestPathSchema = z.object({
    meetingId: z.string(),
    entityId: CornSchema,
    startTime: z.string().datetime(),
});
export const AddScreenshotLabelRequestBodySchema = z.object({
    label: z.string(),
    value: z.string().optional(),
});
export const AddScreenshotLabelRequestSchema = AddScreenshotLabelRequestBodySchema.merge(AddScreenshotLabelRequestPathSchema);
export const AddScreenshotLabelResponseSchema = z.void();
