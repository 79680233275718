import { ClaimStatus as Label } from '@eigtech/claims-v2-types';
export const claimStatusChronicleLabelMap = {
    carrierApprovedEstimate: Label.coordinator,
    carrierRejected: Label.rejected,
    carrierRejectedEstimate: Label.rejected,
    carrierReviewed: Label.coordinator,
    carrierReviewedWithExceptions: Label.coordinator,
    claimCreated: Label.coordinator,
    clientApproved: Label.coordinator,
    clientRejected: Label.rejected,
    closed: Label.closed,
    estimateRevisionRequested: Label.rejected,
    fieldAdjusterCorrectionUploaded: Label.qa,
    fieldAdjusterEstimateUploaded: Label.qa,
    inAssignQueue: Label.coordinator,
    inspectionCanceled: Label.inspection,
    inspectionCompleted: Label.inspection,
    inspectionScheduled: Label.inspection,
    jobCompleted: Label.closed,
    jobContracted: Label.closed,
    jobNotSold: Label.closed,
    jobSold: Label.closed,
    jobStarted: Label.closed,
    qaApproved: Label.carrier,
    qaRejected: Label.rejected,
    reInspectionRequested: Label.inspection,
    reopened: Label.coordinator,
};
