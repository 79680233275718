import z from 'zod';
import { BaseJobSchema } from '../../base/baseJob.js';
import { JobSchema, JobTypeSchema } from '../../base/job.js';
import { JobServicesSchema } from '../../base/jobService.js';
export const ReceiveJobInternalRequestBodySchema = BaseJobSchema.omit({
    jobId: true,
    jobNumber: true,
    status: true,
}).merge(z.object({
    jobType: JobTypeSchema,
    services: JobServicesSchema,
}));
export const ReceiveJobInternalRequestSchema = ReceiveJobInternalRequestBodySchema;
export const ReceiveJobInternalResponseSchema = JobSchema;
export const ReceiveJobInternalApiSchema = {
    body: ReceiveJobInternalRequestBodySchema,
};
