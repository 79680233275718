import { CohortEnumSchema } from '@eigtech/auth0-types';
import { z } from 'zod';
import { DocumentPublicationAttributesSchema, DocumentSchemaV1, DocumentSchemaV2, PublicationTargetsSchema, } from '../aggregates/documents.js';
import { DocumentServiceEventBaseSchema, documentServiceEventType } from './service.js';
const documentEventType = (eventName) => documentServiceEventType('document', eventName);
export const DocumentEventSchema = DocumentServiceEventBaseSchema.merge(z.object({
    type: documentEventType(),
    document: z.unknown(),
    metadata: z.object({
        requestActor: z.string(),
        createdByCohort: CohortEnumSchema.optional(),
    }),
}));
export const DocumentUploadedEventSchemaV1 = DocumentEventSchema.merge(z.object({
    type: documentEventType('uploaded'),
    schemaVersion: z.literal(1),
    document: DocumentSchemaV1,
}));
export const DocumentMarkedForPublicationEventSchemaV1 = DocumentEventSchema.merge(z.object({
    type: documentEventType('markedForPublication'),
    schemaVersion: z.literal(1),
    document: DocumentSchemaV1.merge(z.object({
        publicationTargets: PublicationTargetsSchema.array().optional(),
        s3Location: z.object({ bucket: z.string(), key: z.string() }),
    })),
}));
export const DocumentUploadedEventSchemaV2 = DocumentEventSchema.merge(z.object({
    type: documentEventType('uploaded'),
    schemaVersion: z.literal(2),
    document: DocumentSchemaV2,
}));
export const DocumentMarkedForPublicationEventSchemaV2 = DocumentEventSchema.merge(z.object({
    type: documentEventType('markedForPublication'),
    schemaVersion: z.literal(2),
    document: DocumentSchemaV2.merge(z.object({
        publicationTarget: PublicationTargetsSchema,
        publicationAttributes: DocumentPublicationAttributesSchema.or(z.record(z.string(), z.unknown()).optional()),
        s3Location: z.object({ bucket: z.string(), key: z.string() }),
    })),
    metadata: DocumentEventSchema.shape.metadata.merge(z.object({
        publicationTarget: PublicationTargetsSchema.optional(),
    })),
}));
