import z from 'zod';
import { ProducerUserTypesEnumSchema } from '../../base/index.js';
import { Auth0ZoneTypeSchema } from '../../base/zones.js';
import { Auth0UserSchema } from '../../logStreamEvents/base/base.js';
export const CreateUserRequestBodySchema = z.object({
    auth0Id: z.string().optional(),
    email: z.string(),
    message: z.string().optional(),
    name: z.string(),
    userType: ProducerUserTypesEnumSchema,
    zoneType: Auth0ZoneTypeSchema,
});
export const CreateUserRequestSchema = CreateUserRequestBodySchema;
export const CreateUserResponseSchema = Auth0UserSchema;
export const CreateUserApiSchema = {
    body: CreateUserRequestBodySchema,
    response: CreateUserResponseSchema,
};
