import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { CornSchema } from '@eigtech/shared-corn';
import { z } from 'zod';
export const IssueSdkTokenApiSchema = {
    params: z.object({
        entityId: CornSchema,
        startTime: z.string().datetime(),
    }),
    headers: ApiLambdalerAuthHeaderSchema,
    response: z.object({
        accessKeyId: z.string(),
        expiration: z.string(),
        secretAccessKey: z.string(),
        sessionToken: z.string(),
    }),
};
export const IssueSdkTokenRequestSchema = IssueSdkTokenApiSchema.params.merge(IssueSdkTokenApiSchema.headers);
export const IssueSdkTokenResponseSchema = IssueSdkTokenApiSchema.response;
