import { z } from 'zod';
import { ClaimDataSourceSchema } from './base.js';
export const CustomerCommunicationTypeSchema = z.enum([
    'customerContactedEmail',
    'customerContactedLvm',
    'customerNoContact',
    'initialCustomerContactAttempted',
    'initialCustomerContactFailed',
    'initialCustomerContactSuccess',
    'invalidContactInformation',
]);
export const CustomerCommunicationSchema = z.object({
    createdBy: z.string().optional(),
    dataSource: ClaimDataSourceSchema.or(z.string()).optional(),
    date: z.string().datetime(),
    type: CustomerCommunicationTypeSchema,
});
export const CustomerCommunicationTypeEnum = CustomerCommunicationTypeSchema.Enum;
