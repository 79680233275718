import { CohortEnumSchema } from '@eigtech/auth0-types';
import { CornSchema } from '@eigtech/shared-corn';
import { z } from 'zod';
import { DocumentSchemaV2 } from '../aggregates/documents.js';
// list documents
export const ListDocumentsApiSchema = {
    params: z.object({
        entityId: CornSchema,
        cohort: CohortEnumSchema,
    }),
    response: z.array(DocumentSchemaV2),
};
export const ListDocumentsRequestSchema = ListDocumentsApiSchema.params;
export const ListDocumentsResponseSchema = ListDocumentsApiSchema.response;
