// This code is ripped from [typeid-js](https://github.com/jetpack-io/typeid-js/tree/main)
/* eslint-disable no-bitwise */
export function parseUUID(uuid) {
    let v;
    const arr = new Uint8Array(16);
    // Block 1
    arr[0] = (v = parseInt(uuid.slice(0, 8), 16)) >>> 24;
    arr[1] = (v >>> 16) & 0xff;
    arr[2] = (v >>> 8) & 0xff;
    arr[3] = v & 0xff;
    // Block 2
    arr[4] = (v = parseInt(uuid.slice(9, 13), 16)) >>> 8;
    arr[5] = v & 0xff;
    // Block 3
    arr[6] = (v = parseInt(uuid.slice(14, 18), 16)) >>> 8;
    arr[7] = v & 0xff;
    // Block 4
    arr[8] = (v = parseInt(uuid.slice(19, 23), 16)) >>> 8;
    arr[9] = v & 0xff;
    // Block 5
    arr[10] = ((v = parseInt(uuid.slice(24, 36), 16)) / 0x10000000000) & 0xff;
    arr[11] = (v / 0x100000000) & 0xff;
    arr[12] = (v >>> 24) & 0xff;
    arr[13] = (v >>> 16) & 0xff;
    arr[14] = (v >>> 8) & 0xff;
    arr[15] = v & 0xff;
    return arr;
}
