import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { ContactCornSchema } from '../../../base/contacts.js';
export const ActivateContactRequestPathSchema = z.object({ contactId: ContactCornSchema });
export const ActivateContactRequestHeadersSchema = ApiLambdalerAuthHeaderWithRequestActorSchema.pick({ requestActor: true });
export const ActivateContactRequestSchema = ActivateContactRequestPathSchema.merge(ActivateContactRequestHeadersSchema);
export const ActivateContactApiSchema = {
    headers: ActivateContactRequestHeadersSchema,
    params: ActivateContactRequestPathSchema,
};
