import { z } from 'zod';
import { Auth0UserSchema } from '../../logStreamEvents/base/base.js';
export const GetUserFromIdRequestPathSchema = z.object({
    userId: z.string(),
});
export const GetUserFromIdRequestSchema = GetUserFromIdRequestPathSchema;
export const GetUserFromIdResponseSchema = z.object({
    user: Auth0UserSchema,
});
export const GetUserFromIdApiSchema = {
    params: GetUserFromIdRequestPathSchema,
    response: GetUserFromIdResponseSchema,
};
