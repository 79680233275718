import { z } from 'zod';
import { RegionSchema } from '../base/region.js';
import { serviceEventType, TarpulatorEntityTypeName, TarpulatorServiceEventBaseSchema, } from './service.js';
export const tarpulatorRegionEventType = (eventName) => serviceEventType(TarpulatorEntityTypeName.region, eventName);
export const TarpulatorRegionEventTypeNameSchema = z.enum(['created', 'deleted', 'updated']);
export const TarpulatorRegionEventTypeName = TarpulatorRegionEventTypeNameSchema.Values;
export const TarpulatorRegionEventSchema = TarpulatorServiceEventBaseSchema.merge(z.object({
    type: tarpulatorRegionEventType(),
    region: z.unknown(),
}));
export const TarpulatorRegionCreatedEventSchema = TarpulatorRegionEventSchema.merge(z.object({
    type: tarpulatorRegionEventType(TarpulatorRegionEventTypeName.created),
    region: RegionSchema,
}));
export const TarpulatorRegionUpdatedEventSchema = TarpulatorRegionEventSchema.merge(z.object({
    type: tarpulatorRegionEventType(TarpulatorRegionEventTypeName.updated),
    region: RegionSchema,
}));
export const TarpulatorRegionDeletedEventSchema = TarpulatorRegionEventSchema.merge(z.object({
    type: tarpulatorRegionEventType(TarpulatorRegionEventTypeName.deleted),
    region: RegionSchema,
}));
