import { defineTable, Schema } from 'squid';
const inspectionsTable = defineTable('inspections', {
    entity_id: Schema.String,
    inspection_id: Schema.String,
    date_completed: Schema.nullable(Schema.String),
    date_canceled: Schema.nullable(Schema.String),
    inspector_role: Schema.nullable(Schema.String),
    notes: Schema.nullable(Schema.String),
    scheduled_date: Schema.nullable(Schema.String),
    status: Schema.String,
});
