import { CornSchema } from '@eigtech/shared-corn';
import z from 'zod';
export const GetMeetingVideoRequestPathSchema = z.object({
    entityId: CornSchema,
    meetingId: z.string(),
    startTime: z.string().datetime(),
});
export const GetMeetingVideoRequestSchema = GetMeetingVideoRequestPathSchema;
export const GetMeetingVideoResponseSchema = z.object({
    videoUrl: z.string(),
});
