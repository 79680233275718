import z from 'zod';
import { JobAssignmentChronicleType, JobRestorationChronicleType } from './jobChronicles.js';
export const JobDefaultChronicleTypeSchema = z.enum([
    // #region restoration events
    JobRestorationChronicleType.appointmentCompleted,
    JobRestorationChronicleType.appointmentScheduled,
    JobRestorationChronicleType.billSentToCollections,
    JobRestorationChronicleType.cancelled,
    JobRestorationChronicleType.carrierInvoiced,
    JobRestorationChronicleType.carrierMadePayment,
    JobRestorationChronicleType.completed,
    JobRestorationChronicleType.customerContactAttempted,
    JobRestorationChronicleType.depositOverrideApproved,
    JobRestorationChronicleType.depositPaid,
    JobRestorationChronicleType.descriptionUpdated,
    JobRestorationChronicleType.descriptionUpdated,
    JobRestorationChronicleType.locationUpdated,
    JobRestorationChronicleType.received,
    JobRestorationChronicleType.reopened,
    JobRestorationChronicleType.servicesAdded,
    JobRestorationChronicleType.servicesRemoved,
    JobRestorationChronicleType.started,
    JobRestorationChronicleType.subcontractorPaid,
    JobRestorationChronicleType.subdocumentIssued,
    JobRestorationChronicleType.subdocumentReceived,
    JobRestorationChronicleType.withdrawn,
    JobRestorationChronicleType.workAuthorizationCompleted,
    JobRestorationChronicleType.workAuthorizationDeclined,
    JobRestorationChronicleType.workAuthorizationExpired,
    JobRestorationChronicleType.workAuthorizationOverrideApproved,
    JobRestorationChronicleType.workAuthorizationReassigned,
    JobRestorationChronicleType.workAuthorizationRecalled,
    JobRestorationChronicleType.workAuthorizationSent,
    JobRestorationChronicleType.workAuthorizationRecipientSigned,
    JobRestorationChronicleType.workDetailsReceived,
    // #endregion restoration events
    // #region job assignment events
    JobAssignmentChronicleType.approverAssignedToJob,
    JobAssignmentChronicleType.approverUnassignedFromJob,
    JobAssignmentChronicleType.coordinatorAssignedToJob,
    JobAssignmentChronicleType.coordinatorUnassignedFromJob,
    JobAssignmentChronicleType.dispatcherAssignedToJob,
    JobAssignmentChronicleType.dispatcherUnassignedFromJob,
    JobAssignmentChronicleType.fieldTechnicianAssignedToJob,
    JobAssignmentChronicleType.fieldTechnicianUnassignedFromJob,
    JobAssignmentChronicleType.jobAssignedToClaim,
    JobAssignmentChronicleType.jobUnassignedFromClaim,
    JobAssignmentChronicleType.primaryContactAssignedToJob,
    JobAssignmentChronicleType.primaryContactUnassignedFromJob,
    JobAssignmentChronicleType.projectManagerAssignedToJob,
    JobAssignmentChronicleType.projectManagerUnassignedFromJob,
    JobAssignmentChronicleType.subcontractorAssignedToJob,
    JobAssignmentChronicleType.subcontractorUnassignedFromJob,
    // #endregion job assignment events
]);
export const JobDefaultChronicleType = JobDefaultChronicleTypeSchema.Enum;
export const isJobDefaultChronicle = (chronicle) => JobDefaultChronicleTypeSchema.safeParse(chronicle.type).success;
