import { DocumentCornSchema } from '@eigtech/documents-types';
import z from 'zod';
import { ClaimNumberSchema } from '../model/index.js';
import { BaseHeaderSchema } from './base.js';
const RemoveLossOfUseReceiptSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
        documentCorn: DocumentCornSchema,
        id: z.string(),
    }),
    headers: BaseHeaderSchema,
};
export const RemoveLossOfUseReceiptRequestSchema = RemoveLossOfUseReceiptSchemas.params.merge(RemoveLossOfUseReceiptSchemas.headers);
const RemoveLossOfUseReceiptInputSchema = RemoveLossOfUseReceiptRequestSchema.merge(z.object({
    documentCorn: DocumentCornSchema,
    receiptDateRemoved: z.string().datetime(),
}));
export const RemoveLossOfUseReceiptApiSchema = RemoveLossOfUseReceiptSchemas;
