import z from 'zod';
import { ClaimNumberSchema } from '../model/base.js';
import { LossOfUseSchema } from '../model/lossOfUse.js';
import { BaseHeaderSchema } from './base.js';
const AddLossOfUseSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: LossOfUseSchema.omit({
        lastModified: true,
        justification: true,
        status: true,
        id: true,
    }),
    headers: BaseHeaderSchema,
    response: LossOfUseSchema,
};
export const AddLossOfUseRequestSchema = AddLossOfUseSchemas.params
    .merge(AddLossOfUseSchemas.body)
    .merge(AddLossOfUseSchemas.headers);
const AddLossOfUseInputSchema = AddLossOfUseRequestSchema.merge(LossOfUseSchema);
export const AddLossOfUseApiSchema = AddLossOfUseSchemas;
