import { z } from 'zod';
export const RegionCsvHeaderSchema = z.enum([
    'Name',
    'Id (Blank = New)',
    'Base Rate After Hours',
    'Base Rate During Hours',
    'High Roof Rate',
    'Under 7/12',
    '7/12 to 9/12',
    '10/12 to 12/12',
    'Over 12/12',
    'Mechanical After Hours',
    'Mechanical During Hours',
    'Mechanical Material Cost',
    'Sandbag After Hours',
    'Sandbag During Hours',
    'Sandbag Material Cost',
    'Surtax',
]);
export const RegionCsvRecordSchema = z.object({
    [RegionCsvHeaderSchema.enum['Name']]: z.string(),
    [RegionCsvHeaderSchema.enum['Id (Blank = New)']]: z.string().optional(),
    [RegionCsvHeaderSchema.enum['Base Rate After Hours']]: z.number(),
    [RegionCsvHeaderSchema.enum['Base Rate During Hours']]: z.number(),
    [RegionCsvHeaderSchema.enum['High Roof Rate']]: z.number(),
    [RegionCsvHeaderSchema.enum['Under 7/12']]: z.number(),
    [RegionCsvHeaderSchema.enum['7/12 to 9/12']]: z.number(),
    [RegionCsvHeaderSchema.enum['10/12 to 12/12']]: z.number(),
    [RegionCsvHeaderSchema.enum['Over 12/12']]: z.number(),
    [RegionCsvHeaderSchema.enum['Mechanical After Hours']]: z.number(),
    [RegionCsvHeaderSchema.enum['Mechanical During Hours']]: z.number(),
    [RegionCsvHeaderSchema.enum['Mechanical Material Cost']]: z.number(),
    [RegionCsvHeaderSchema.enum['Sandbag After Hours']]: z.number(),
    [RegionCsvHeaderSchema.enum['Sandbag During Hours']]: z.number(),
    [RegionCsvHeaderSchema.enum['Sandbag Material Cost']]: z.number(),
    [RegionCsvHeaderSchema.enum['Surtax']]: z.number().or(z.literal('')).optional(),
});
export const VendorRateCsvHeaderSchema = z.enum([
    'Name',
    'Id (Blank = New)',
    'Mechanical After Hours',
    'Mechanical During Hours',
    'Mechanical Material Cost',
    'Sandbag After Hours',
    'Sandbag During Hours',
    'Sandbag Material Cost',
    'Is Template?',
]);
export const VendorRateCsvRecordSchema = z.object({
    [VendorRateCsvHeaderSchema.enum['Name']]: z.string(),
    [VendorRateCsvHeaderSchema.enum['Id (Blank = New)']]: z.string().optional(),
    [VendorRateCsvHeaderSchema.enum['Mechanical After Hours']]: z.number(),
    [VendorRateCsvHeaderSchema.enum['Mechanical During Hours']]: z.number(),
    [VendorRateCsvHeaderSchema.enum['Mechanical Material Cost']]: z.number(),
    [VendorRateCsvHeaderSchema.enum['Sandbag After Hours']]: z.number(),
    [VendorRateCsvHeaderSchema.enum['Sandbag During Hours']]: z.number(),
    [VendorRateCsvHeaderSchema.enum['Sandbag Material Cost']]: z.number(),
    [VendorRateCsvHeaderSchema.enum['Is Template?']]: z.string().optional(),
});
