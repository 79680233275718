import { z } from 'zod';
export const LineItemTypeSchema = z.enum(['taxLineItem', 'salesLineItem', 'subtotalLineItem']);
export const LineItemTypeEnum = LineItemTypeSchema.enum;
export const LineItemSchema = z.object({
    amount: z.number(),
    type: LineItemTypeSchema,
    label: z.string(),
});
export const SalesLineItemSchema = LineItemSchema.merge(z.object({
    type: z.literal(LineItemTypeEnum.salesLineItem),
    detail: z
        .object({
        unitPrice: z.number().optional(),
        qty: z.number().optional(),
    })
        .optional(),
}));
export const SubtotalLineItemSchema = LineItemSchema.merge(z.object({
    type: z.literal(LineItemTypeEnum.subtotalLineItem),
    detail: z.object({
        lines: LineItemSchema.array(),
    }),
}));
export const TaxLineItemSchema = LineItemSchema.merge(z.object({
    type: z.literal(LineItemTypeEnum.taxLineItem),
    detail: z.object({
        lines: LineItemSchema.array(),
        taxRate: z.number(),
    }),
}));
