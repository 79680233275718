import { z } from 'zod';
import { CohortEnumSchema } from '../base/cohorts.js';
import { ProducerUserTypesEnumSchema } from '../base/userTypes.js';
import { Auth0UserSchema } from '../logStreamEvents/base/base.js';
import { ServiceEventBaseSchema, serviceEventType, serviceName } from './service.js';
const userEventEntity = 'user';
const userEventType = (eventName) => serviceEventType(userEventEntity, eventName);
export const userEventTypePrefix = `${serviceName}:${userEventEntity}`;
export const UserEventSchema = ServiceEventBaseSchema.merge(z.object({
    type: userEventType(),
    user: z.unknown(),
    schemaVersion: z.literal(1),
}));
export const UserCreatedEventSchema = UserEventSchema.merge(z.object({
    type: userEventType('created'),
    user: Auth0UserSchema.and(z.object({
        cohorts: CohortEnumSchema.array(),
        userTypes: ProducerUserTypesEnumSchema.array(),
    })),
}));
export const UserRemovedEventSchema = UserEventSchema.merge(z.object({
    type: userEventType('removed'),
    user: Auth0UserSchema.and(z.object({
        cohorts: CohortEnumSchema.array(),
        userTypes: ProducerUserTypesEnumSchema.array(),
    })),
}));
export const UserUpdatedEventSchema = UserEventSchema.merge(z.object({
    type: userEventType('updated'),
    user: Auth0UserSchema.and(z.object({
        cohorts: CohortEnumSchema.array(),
        userTypes: ProducerUserTypesEnumSchema.array(),
    })),
}));
export const UserPhotoUpdatedEventSchema = UserEventSchema.merge(z.object({
    type: userEventType('photoUploaded'),
    user: z.object({
        userId: z.string(),
        pictureUrl: z.string(),
    }),
}));
export const UserInvitationSentEventSchema = UserEventSchema.merge(z.object({
    type: userEventType('invitationSent'),
    invitation: z.object({
        user: Auth0UserSchema,
        ticket: z.string(),
        message: z.string().optional(),
        redirectUrl: z.string(),
    }),
}));
export const UserWelcomeSentEventSchema = UserEventSchema.merge(z.object({
    type: userEventType('welcomeSent'),
    invitation: z.object({
        user: Auth0UserSchema,
        tplapDomain: z.string(),
    }),
}));
