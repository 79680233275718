import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { BookCornSchema, BookMetadataSchema } from '../../../base/books.js';
export const UpdateBookMetadataRequestBodySchema = BookMetadataSchema;
export const UpdateBookMetadataRequestPathParametersSchema = z.object({
    bookId: BookCornSchema,
});
export const UpdateBookMetadataRequestHeadersSchema = ApiLambdalerAuthHeaderWithRequestActorSchema.pick({ requestActor: true });
export const UpdateBookMetadataRequestSchema = UpdateBookMetadataRequestBodySchema.and(UpdateBookMetadataRequestPathParametersSchema).and(UpdateBookMetadataRequestHeadersSchema);
export const UpdateBookMetadataApiSchema = {
    body: UpdateBookMetadataRequestBodySchema,
    headers: UpdateBookMetadataRequestHeadersSchema,
    params: UpdateBookMetadataRequestPathParametersSchema,
};
