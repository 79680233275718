import z from 'zod';
import { NoteSchema } from '../../base/note.js';
import { NoteCommandSchema } from '../note.js';
import { MarkNoteForPublicationBodyRequestSchema } from './markNoteForPublication.js';
// Add Note to Claim
export const AddNoteBodyRequestSchema = NoteCommandSchema.omit({
    createdBy: true,
    createdOn: true,
    entityId: true,
}).merge(MarkNoteForPublicationBodyRequestSchema.partial());
export const AddNoteHeaderRequestSchema = z.object({
    requestActor: z.string().optional(),
});
export const AddNotePathRequestSchema = z.object({
    entityId: z.string(),
});
export const AddNoteRequestSchema = AddNoteBodyRequestSchema.merge(AddNotePathRequestSchema).merge(AddNoteHeaderRequestSchema);
export const AddNoteResponseSchema = z.object({
    noteId: NoteSchema.shape.id,
});
