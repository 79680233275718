import { JobAssigneeRelationshipEnumSchema, } from '@eigtech/assignments-types';
import { DailyServicesServiceSchema, EmergencyServicesServiceSchema, JobIdSchema, JobLocationAddressTypeSchema, JobLocationGeoCoordinatesSchema, JobStatusSchema, JobTypeSchema, WorkAuthorizationSchema, WorkAuthorizationStatusEnumSchema, } from '@eigtech/restoration-types';
import z from 'zod';
import { makeSummaryFilterSchema, makeSummaryParamsSchema } from './pagination.js';
export const GenericJobAssigneeSchema = z.object({
    id: z.string(),
    name: z.string(),
    email: z.optional(z.string()),
    phone: z.optional(z.string()),
    rawAddress: z.optional(z.string()),
    street1: z.optional(z.string()),
    street2: z.optional(z.string()),
    city: z.optional(z.string()),
    state: z.optional(z.string()),
    zipcode: z.optional(z.string()),
});
export const JobLocationSchema = z.object({
    addressLine1: z.string(),
    addressLine2: z.optional(z.string()),
    addressLine3: z.optional(z.string()),
    city: z.optional(z.string()),
    stateOrProvince: z.optional(z.string()),
    zipOrPostalCode: z.optional(z.string()),
    country: z.optional(z.string()),
    addressType: z.optional(JobLocationAddressTypeSchema),
    countyOrParishCode: z.optional(z.string()),
    geoAccuracy: z.optional(JobLocationGeoCoordinatesSchema.shape['accuracy']),
    latitude: z.optional(z.string()),
    longitude: z.optional(z.string()),
});
export const JobWorkAuthSchema = WorkAuthorizationSchema.omit({
    workAuthorizationId: true,
    approvedBy: true,
    signer: true,
    effectiveDate: true,
}).merge(z.object({
    workAuthId: WorkAuthorizationSchema.shape.workAuthorizationId,
    approvedBy: WorkAuthorizationSchema.shape.approvedBy.optional(),
    signer: WorkAuthorizationSchema.shape.signer.optional(),
    effectiveDate: WorkAuthorizationSchema.shape.effectiveDate.optional(),
}));
export const JobSummarySchema = z.object({
    // workAuth: SummarizerJobWorkAuthSchema.optional(),
    closedReason: z.string().optional(),
    description: z.string().optional(),
    fieldTechnicians: GenericJobAssigneeSchema.array().optional(),
    id: JobIdSchema,
    isClosed: z.boolean(),
    jobLocation: JobLocationSchema.optional(),
    latestTimelineEvent: z.string().optional(),
    latestWorkAuthStatus: WorkAuthorizationStatusEnumSchema.optional(),
    primaryContact: GenericJobAssigneeSchema.optional(),
    projectManager: GenericJobAssigneeSchema.optional(),
    relatedClaimNumber: z.string().optional(),
    services: z
        .enum([...EmergencyServicesServiceSchema.options, ...DailyServicesServiceSchema.options])
        .array(),
    status: JobStatusSchema.optional(),
    subcontractors: GenericJobAssigneeSchema.array().optional(),
    type: JobTypeSchema,
});
export const JobSummaryFieldSchema = z.enum([
    'approverEmail',
    'approverId',
    'approverName',
    'approverPhone',
    'city',
    'closedReason',
    'coordinatorEmail',
    'coordinatorId',
    'coordinatorName',
    'coordinatorPhone',
    'county',
    'description',
    'dispatcherEmail',
    'dispatcherId',
    'dispatcherName',
    'dispatcherPhone',
    'id',
    'isClosed',
    'latestTimelineEvent',
    'latestWorkAuthStatus',
    'primaryContactEmail',
    'primaryContactName',
    'primaryContactPhone',
    'projectManagerEmail',
    'projectManagerId',
    'projectManagerName',
    'projectManagerPhone',
    'relatedClaimNumber',
    'services',
    'state',
    'status',
    'type',
    'workAuthSignedOn',
    //'workAuthApprovedBy',
    //'workAuthEffectiveDate',
    //'workAuthRecipient',
    //'workAuthSentDate',
    //'workAuthSentMethod',
    //'workAuthSigner',
    //'workAuthId',
]);
export const JobSummaryFilterSchema = makeSummaryFilterSchema(JobSummaryFieldSchema);
export const JobSummariesParamsSchema = makeSummaryParamsSchema(JobSummaryFieldSchema, JobSummaryFilterSchema);
export const JobMonoAssignmentRelationshipSchema = JobAssigneeRelationshipEnumSchema.extract([
    'approver',
    'coordinator',
    'dispatcher',
    'primaryContact',
    'projectManager',
]);
export const isJobMonoAssignmentRelationship = (relationship) => JobMonoAssignmentRelationshipSchema.safeParse(relationship).success;
export const JobPolyAssignmentRelationshipSchema = JobAssigneeRelationshipEnumSchema.extract([
    'fieldTechnician',
    'subcontractor',
]);
export const isJobPolyAssignmentRelationship = (relationship) => JobPolyAssignmentRelationshipSchema.safeParse(relationship).success;
