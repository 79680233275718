import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { ContactCornSchema, ContactMergeStrategySchema, ContactSchema, } from '../../../base/contacts.js';
export const MergeContactRequestBodySchema = z.object({
    base: ContactCornSchema,
    other: ContactCornSchema,
    dryRun: z.boolean().optional(),
    mergeStrategy: ContactMergeStrategySchema,
});
export const MergeContactHeadersSchema = ApiLambdalerAuthHeaderWithRequestActorSchema.pick({
    requestActor: true,
});
export const MergeContactRequestSchema = MergeContactRequestBodySchema.merge(MergeContactHeadersSchema);
export const MergeContactResponseSchema = z.object({
    mergedContact: ContactSchema.optional(),
    mergedBooks: z.string().array(),
});
export const MergeContactApiSchema = {
    body: MergeContactRequestBodySchema,
    headers: MergeContactHeadersSchema,
    response: MergeContactResponseSchema,
};
