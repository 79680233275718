import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { BookCornSchema } from '../../../base/books.js';
export const CreateClaimBookRequestBodySchema = z.object({
    claimNumber: z.string(),
});
export const CreateClaimBookRequestHeadersSchema = ApiLambdalerAuthHeaderWithRequestActorSchema.pick({ requestActor: true });
export const CreateClaimBookRequestSchema = CreateClaimBookRequestBodySchema.merge(CreateClaimBookRequestHeadersSchema);
export const CreateClaimBookResponseSchema = z.object({
    bookId: BookCornSchema,
});
export const CreateClaimBookApiSchema = {
    body: CreateClaimBookRequestBodySchema,
    headers: CreateClaimBookRequestHeadersSchema,
    response: CreateClaimBookResponseSchema,
};
