import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { JobIdSchema } from '../../base/ids.js';
import { JobLocationSchema } from '../../base/jobLocation.js';
export const UpdateJobLocationRequestBodySchema = JobLocationSchema;
export const UpdateJobLocationRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const UpdateJobLocationRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
});
export const UpdateJobLocationRequestSchema = UpdateJobLocationRequestBodySchema.merge(UpdateJobLocationRequestHeadersSchema).merge(UpdateJobLocationRequestPathSchema);
export const UpdateJobLocationResponseSchema = z.object({
    jobId: JobIdSchema,
    jobLocation: JobLocationSchema,
});
export const UpdateJobLocationApiSchema = {
    body: UpdateJobLocationRequestBodySchema,
    headers: UpdateJobLocationRequestHeadersSchema,
    params: UpdateJobLocationRequestPathSchema,
    response: UpdateJobLocationResponseSchema,
};
