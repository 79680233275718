import { z } from 'zod';
export const ParsedAddressSchema = z.object({
    type: z.string(),
    street: z.string(),
    street2: z.string(),
    city: z.string(),
    state: z.string(),
    postal: z.string(),
    country: z.string().optional(),
});
export const ParsedContactSchema = z.object({
    type: z.string(),
    name: z.string(),
    companyName: z.string(),
    email: z.string().or(z.string().array()),
    phone: z.object({
        /**
         * 'Office', 'Mobile', 'SMS'
         */
        type: z.string(),
        number: z.string(),
        extension: z.string(),
    }),
    addresses: ParsedAddressSchema.array(),
});
