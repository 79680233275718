import { z } from 'zod';
import { Auth0UserSchema } from '../../logStreamEvents/base/base.js';
export const GetUserRequestPathSchema = z.object({
    userId: z.string(),
});
export const GetUserRequestSchema = GetUserRequestPathSchema;
export const GetUserResponseSchema = z.object({
    user: Auth0UserSchema,
});
export const GetUserApiSchema = {
    params: GetUserRequestPathSchema,
    response: GetUserResponseSchema,
};
