export var ClaimInsuredTimelineLabel;
(function (ClaimInsuredTimelineLabel) {
    ClaimInsuredTimelineLabel["carrier"] = "Carrier Review";
    ClaimInsuredTimelineLabel["closed"] = "Completed";
    ClaimInsuredTimelineLabel["inspection"] = "Inspection";
    ClaimInsuredTimelineLabel["preInspection"] = "Pre-inspection";
    ClaimInsuredTimelineLabel["qa"] = "Submitted to carrier";
})(ClaimInsuredTimelineLabel || (ClaimInsuredTimelineLabel = {}));
export const claimInsuredChronicleLabelMap = {
    carrierApprovedEstimate: ClaimInsuredTimelineLabel.carrier,
    carrierApprovedEstimateWithExceptions: ClaimInsuredTimelineLabel.carrier,
    carrierReviewed: ClaimInsuredTimelineLabel.carrier,
    carrierReviewedWithExceptions: ClaimInsuredTimelineLabel.carrier,
    claimCreated: ClaimInsuredTimelineLabel.preInspection,
    clientApproved: ClaimInsuredTimelineLabel.carrier,
    estimateSentToCarrier: ClaimInsuredTimelineLabel.qa,
    inAssignQueue: ClaimInsuredTimelineLabel.preInspection,
    inspectionCompleted: ClaimInsuredTimelineLabel.inspection,
    closed: ClaimInsuredTimelineLabel.closed,
    jobCompleted: ClaimInsuredTimelineLabel.closed,
    jobContracted: ClaimInsuredTimelineLabel.closed,
    jobNotSold: ClaimInsuredTimelineLabel.closed,
    jobSold: ClaimInsuredTimelineLabel.closed,
    jobStarted: ClaimInsuredTimelineLabel.closed,
    qaApproved: ClaimInsuredTimelineLabel.qa,
};
