import z from 'zod';
import { BaseJobSchema } from './baseJob.js';
import { JobService } from './baseJobService.js';
export const OtrJobTypeSchema = z.literal('otr');
export const JOB_TYPE_OTR = OtrJobTypeSchema.value;
export const OtrJobTypeAbbreviationSchema = z.literal('OTR');
export const JOB_TYPE_OTR_ABBREVIATION = OtrJobTypeAbbreviationSchema.value;
export const OtrServiceSchema = z.enum([JobService.notImplemented]);
export const OtrJobSchema = BaseJobSchema.merge(z.object({
    jobType: OtrJobTypeSchema,
    services: z.array(OtrServiceSchema).transform((val) => Array.from(new Set(val))),
}));
export const isOtrService = (s) => OtrServiceSchema.safeParse(s).success;
