import { Schema, defineTable } from 'squid';
const appointmentTable = defineTable('appointment', {
    id: Schema.String,
    job_id: Schema.String,
    channel: Schema.String,
    date: Schema.String,
    type: Schema.String,
    is_completed: Schema.Boolean,
    description: Schema.nullable(Schema.String),
    notes: Schema.nullable(Schema.String),
    participants: Schema.Array(Schema.String),
});
