import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { JobIdSchema } from '../../base/ids.js';
import { JobServicesSchema } from '../../base/jobService.js';
export const AddJobServicesRequestBodySchema = z.object({
    services: JobServicesSchema,
});
export const AddJobServicesRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const AddJobServicesRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
});
export const AddJobServicesRequestSchema = AddJobServicesRequestBodySchema.merge(AddJobServicesRequestHeadersSchema).merge(AddJobServicesRequestPathSchema);
export const AddJobServicesResponseSchema = z.object({
    jobId: JobIdSchema,
    services: JobServicesSchema,
});
export const AddJobServicesApiSchema = {
    body: AddJobServicesRequestBodySchema,
    headers: AddJobServicesRequestHeadersSchema,
    params: AddJobServicesRequestPathSchema,
    response: AddJobServicesResponseSchema,
};
