import { CornSchema } from '@eigtech/shared-corn';
import z from 'zod';
import { AttendeeSchema, MeetingSchema } from '../base/chime.js';
import { MeetingDetailsSchema } from '../base/meetingDetails.js';
import { ParticipantSchema } from '../base/participant.js';
export const JoinMeetingAsGuestRequestPathSchema = z.object({
    jwt: z.string(),
});
export const JoinMeetingAsGuestJwtPayloadSchema = z.object({
    entityId: CornSchema,
    meetingId: z.string(),
    participant: ParticipantSchema.omit({ auth0Id: true, attendeeId: true }),
});
export const JoinMeetingAsGuestRequestSchema = JoinMeetingAsGuestRequestPathSchema;
export const JoinMeetingAsGuestResponseSchema = z.object({
    meetingDetails: MeetingDetailsSchema,
    chimeMeeting: MeetingSchema,
    chimeAttendee: AttendeeSchema,
});
