import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { BaseJobSchema } from '../../base/baseJob.js';
import { JobSchema, JobTypeSchema } from '../../base/job.js';
import { JobServicesSchema } from '../../base/jobService.js';
export const ReceiveJobRequestBodySchema = BaseJobSchema.omit({
    jobId: true,
    jobNumber: true,
    status: true,
}).merge(z.object({
    jobType: JobTypeSchema,
    services: JobServicesSchema,
}));
export const ReceiveJobRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
});
export const ReceiveJobRequestSchema = ReceiveJobRequestBodySchema.merge(ReceiveJobRequestHeadersSchema);
export const ReceiveJobResponseSchema = JobSchema;
export const ReceiveJobApiSchema = {
    body: ReceiveJobRequestBodySchema,
    headers: ReceiveJobRequestHeadersSchema,
};
