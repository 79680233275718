import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { ContactCornSchema } from '../../../base/contacts.js';
export const RestoreContactRequestPathSchema = z.object({
    contactId: ContactCornSchema,
});
export const RestoreContactRequestHeaderSchema = ApiLambdalerAuthHeaderWithRequestActorSchema.pick({
    requestActor: true,
});
export const RestoreContactRequestSchema = RestoreContactRequestPathSchema.merge(RestoreContactRequestHeaderSchema);
export const RestoreContactApiSchema = {
    headers: RestoreContactRequestHeaderSchema,
    params: RestoreContactRequestPathSchema,
};
