import { SoftDateSchema } from '@eigtech/function-utils';
import { z } from 'zod';
import { AobDetailsSchema } from './aob.js';
import { BaseClaimSchema, BaseEstimateSchema, ClaimFactorsSchema, EstimateIdSchema, } from './base.js';
import { ClaimStatusSchema } from './claimStatus.js';
import { CustomerCommunicationSchema } from './customerCommunication.js';
import { EstimateReviewSchema } from './estimate.js';
import { InitialClaimActionsSchema } from './initialActions.js';
import { InspectionDetailsSchema } from './inspections.js';
import { LossOfUseSchema } from './lossOfUse.js';
import { OutcomeSchema } from './outcome.js';
import { PublicationTargetsSchema } from './publishable.js';
import { ClaimReviewDetailsSchema } from './reviews.js';
export const ClaimEventDateKeySchema = z.enum([
    'customerContactedDate',
    'fieldAdjusterScheduledDate',
    'qaApprovedDate',
    'carrierApprovedEstimate',
    'carrierApprovedEstimateWithExceptions',
]);
export const ClaimKeyDateValueSchema = SoftDateSchema;
export const ClaimKeyDatesSchema = z.record(ClaimEventDateKeySchema, ClaimKeyDateValueSchema);
export const ClaimEstimateSchema = z.object({
    estimate: BaseEstimateSchema,
    reviews: z.array(EstimateReviewSchema).optional(),
});
export const ClaimEstimateRecordSchema = z.record(EstimateIdSchema, ClaimEstimateSchema);
export const ReadModelClaimSchema = BaseClaimSchema.omit({ claimFactors: true }).merge(z.object({
    aobDetails: AobDetailsSchema.optional(),
    claimFactors: z.set(ClaimFactorsSchema.or(z.string())).optional(),
    claimStatus: ClaimStatusSchema.optional(),
    customerCommunication: CustomerCommunicationSchema.array().optional(),
    estimates: ClaimEstimateRecordSchema.optional(),
    initialClaimActions: z.set(InitialClaimActionsSchema).optional(),
    inspections: z.record(InspectionDetailsSchema).optional(),
    isReadOnly: z.boolean(),
    keyDates: ClaimKeyDatesSchema.optional(),
    lossOfUse: z.record(LossOfUseSchema).optional(),
    outcome: OutcomeSchema.optional(),
    publicationAttributes: PublicationTargetsSchema.array().optional(),
    reviews: ClaimReviewDetailsSchema.optional(),
}));
export const LegacyClaimSchema = ReadModelClaimSchema.partial()
    .required({
    claimNumber: true,
})
    .merge(z.object({
    isReadOnly: z.literal(true),
}));
export const SerializableReadModelClaimSchema = ReadModelClaimSchema.omit({
    claimFactors: true,
    initialClaimActions: true,
}).merge(z.object({
    claimFactors: z.array(ClaimFactorsSchema.or(z.string())).optional(),
    initialClaimActions: z.array(InitialClaimActionsSchema).optional(),
}));
export const SerializableLegacyClaimSchema = SerializableReadModelClaimSchema.partial()
    .required({
    claimNumber: true,
})
    .merge(z.object({
    isReadOnly: z.literal(true),
}));
export const UpdatableClaimPropertySchema = ReadModelClaimSchema.pick({
    carrier: true,
    carrierClaimNumber: true,
    claimFactors: true,
    claimStatus: true,
    dateReceived: true,
    externalSystemIds: true,
    initialClaimActions: true,
    inspections: true,
    lossDetails: true,
    lossLocation: true,
    lossOfUse: true,
    outcome: true,
    policyNumber: true,
    reviews: true,
}).keyof();
export const UpdatableClaimProperties = UpdatableClaimPropertySchema.options;
export const readModelClaimAsJsonSerializable = ({ claimFactors, initialClaimActions, ...readModelClaim }) => {
    const serializable = readModelClaim;
    if (claimFactors?.size)
        serializable.claimFactors = [...claimFactors];
    if (initialClaimActions?.size)
        serializable.initialClaimActions = [...initialClaimActions];
    return serializable;
};
export const legacyReadModelClaimAsJsonSerializable = ({ claimFactors, initialClaimActions, ...readModelClaim }) => {
    const serializable = readModelClaim;
    if (claimFactors?.size)
        serializable.claimFactors = [...claimFactors];
    if (initialClaimActions?.size)
        serializable.initialClaimActions = [...initialClaimActions];
    return serializable;
};
export const jsonSerializableClaimAsReadModel = ({ claimFactors, initialClaimActions, ...serializableClaim }) => {
    const readModel = serializableClaim;
    if (claimFactors?.length)
        readModel.claimFactors = new Set(claimFactors);
    if (initialClaimActions?.length)
        readModel.initialClaimActions = new Set(initialClaimActions);
    return readModel;
};
export const ClaimEventDateEnum = ClaimEventDateKeySchema.Enum;
