import { z } from 'zod';
import { VediImportSchema, VediImportTypeSchema } from '../ediImport/vediImport.js';
import { VeriskEDIEventSchema, veriskEdiServiceEventType } from './service.js';
const archiveEventType = (eventName) => veriskEdiServiceEventType('vediImport', eventName);
export const VediImportArchivedEventSchema = VeriskEDIEventSchema.merge(z.object({
    type: archiveEventType('archived'),
    metadata: z.object({
        importType: VediImportTypeSchema,
    }),
    vediImport: VediImportSchema.passthrough(),
}));
