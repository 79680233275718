import { ContactCornSchema } from '@eigtech/contacts-types';
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { JobExternalIdSchema, JobIdSchema } from '../../base/index.js';
export const RecordDepositOverrideApprovedRequestBodySchema = z.object({
    approvedBy: ContactCornSchema.or(z.string()),
    approvedDate: z.string().datetime(),
    externalId: JobExternalIdSchema.optional(),
});
export const RecordDepositOverrideApprovedRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
});
export const RecordDepositOverrideApprovedRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const RecordDepositOverrideApprovedRequestSchema = RecordDepositOverrideApprovedRequestBodySchema.merge(RecordDepositOverrideApprovedRequestHeadersSchema).merge(RecordDepositOverrideApprovedRequestPathSchema);
export const RecordDepositOverrideApprovedResponseSchema = z.void();
export const RecordDepositOverrideApprovedApiSchema = {
    body: RecordDepositOverrideApprovedRequestBodySchema,
    headers: RecordDepositOverrideApprovedRequestHeadersSchema,
    params: RecordDepositOverrideApprovedRequestPathSchema,
};
