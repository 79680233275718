import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { CornSchema } from '@eigtech/shared-corn';
import { z } from 'zod';
export const DeleteDocumentApiSchema = {
    headers: ApiLambdalerAuthHeaderSchema,
    params: z.object({
        entityId: CornSchema,
        documentCorn: z.string(),
    }),
};
export const DeleteDocumentRequestSchema = DeleteDocumentApiSchema.params.merge(DeleteDocumentApiSchema.headers);
