import { DocumentCornSchema } from '@eigtech/documents-types'
import { z } from 'zod'
import { JobServiceSchema } from './baseJobService.js'

// #region Tarping
export const FastenerTypeSchema = z.enum(['mechanical', 'sandbag'])
export type FastenerType = z.infer<typeof FastenerTypeSchema>

export const friendlyFastenerType: Record<FastenerType, string> = {
  mechanical: 'Mechanical',
  sandbag: 'Sandbag',
}

export const RoofPitchSchema = z.enum([
  '07_12AndUnder',
  '07_12To09_12',
  '10_12To12_12',
  'over12_12',
])
export type RoofPitch = z.infer<typeof RoofPitchSchema>
export const friendlyRoofPitch: Record<RoofPitch, string> = {
  '07_12AndUnder': '7:12 and Under',
  '07_12To09_12': '7:12 to 9:12',
  '10_12To12_12': '10:12 to 12:12',
  over12_12: 'Over 12:12',
}

export const TimeOfServiceSchema = z.enum(['afterBusinessHours', 'duringBusinessHours'])
export const TimeOfServiceEnum = TimeOfServiceSchema.Enum
export type TimeOfService = z.infer<typeof TimeOfServiceSchema>
export const friendlyTimeOfService: Record<TimeOfService, string> = {
  afterBusinessHours: 'After Business Hours',
  duringBusinessHours: 'During Business Hours',
}

export const friendlySingleStory = 'Single-Story'
export const friendlyMultiStory = 'Multi-Story'

export const TarpingWorkDetailsSchema = z.object({
  fastenerType: FastenerTypeSchema,
  isMultiStory: z.boolean(),
  photoReport: DocumentCornSchema,
  roofPitch: RoofPitchSchema,
  tarpSquareFeet: z.number(),
  timeOfService: TimeOfServiceSchema,
  workType: JobServiceSchema.exclude(['notImplemented']),
})
// #endregion

export const WorkDetailsSchema = z.discriminatedUnion('workType', [TarpingWorkDetailsSchema])
export type WorkDetails = z.infer<typeof WorkDetailsSchema>
