import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { VendorRatesSchema } from '../../base/vendor.js';
export const DeleteVendorRatesApiSchema = {
    params: z.object({
        vendorRatesId: VendorRatesSchema.shape.id,
    }),
    headers: ApiLambdalerAuthHeaderWithRequestActorSchema,
    response: z.object({
        vendorRatesId: VendorRatesSchema.shape.id,
    }),
};
