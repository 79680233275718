import { z } from 'zod';
import { EventBaseSchema } from '@eigtech/event-stream-types';
import { ParsedCustomDocumentExportSchema } from '../ediExport/customDocument.js';
import { ParsedNoteExportSchema } from '../ediExport/note.js';
import { ParsedStatusExportSchema } from '../ediExport/status.js';
import { VediAssignRecipientImportSchema, VediAssignmentImportSchema, VediImportState, VediImportType, VediImportTypeSchema, VediNoteImportSchema, VediStatusImportSchema, } from '../ediImport/vediImport.js';
import { VeriskEDIEventSchema, veriskEdiServiceEventType } from './service.js';
const publicationEventType = (eventName) => veriskEdiServiceEventType('publication', eventName);
const ConfirmedImportSchema = z.object({ importState: z.literal(VediImportState.confirmed) });
export const VediPublishConfirmedEventSchema = VeriskEDIEventSchema.merge(z.object({
    type: publicationEventType('importConfirmed'),
    metadata: EventBaseSchema.shape.metadata.and(z.object({
        importType: VediImportTypeSchema,
    })),
    publication: z.object({
        vediExport: z.unknown(),
        vediImport: z.unknown(),
    }),
}));
// #region Note
export const VediNotePublishConfirmedEventSchema = VediPublishConfirmedEventSchema.merge(z.object({
    metadata: VediPublishConfirmedEventSchema.shape.metadata.and(z.object({
        importType: z.literal(VediImportType.note),
    })),
    publication: z.object({
        vediExport: ParsedNoteExportSchema,
        vediImport: VediNoteImportSchema.merge(ConfirmedImportSchema),
    }),
}));
// #endregion Note
// #region AssignRecipient
export const VediAssignRecipientPublishConfirmedEventSchema = VediPublishConfirmedEventSchema.merge(z.object({
    metadata: VediPublishConfirmedEventSchema.shape.metadata.and(z.object({
        importType: z.literal(VediImportType.assignRecipient),
    })),
    publication: z.object({
        vediExport: ParsedStatusExportSchema,
        vediImport: VediAssignRecipientImportSchema.merge(ConfirmedImportSchema),
    }),
}));
// #endregion AssignRecipient
// #region Assignment
export const VediAssignmentImportPublishConfirmedEventSchema = VediPublishConfirmedEventSchema.merge(z.object({
    metadata: VediPublishConfirmedEventSchema.shape.metadata.and(z.object({
        importType: z.literal(VediImportType.assignment),
    })),
    publication: z.object({
        // vediExport: ParsedAssignmentExportSchema,
        vediExport: z.unknown(), // TODO: convert a lot of types to schemas...
        vediImport: VediAssignmentImportSchema.merge(ConfirmedImportSchema),
    }),
}));
// #endregion Assignment
// #region Document
export const VediDocumentImportPublishConfirmedEventSchema = VediPublishConfirmedEventSchema.merge(z.object({
    metadata: VediPublishConfirmedEventSchema.shape.metadata.and(z.object({
        importType: z.literal(VediImportType.document),
    })),
    publication: z.object({
        vediExport: ParsedCustomDocumentExportSchema,
        vediImport: VediAssignmentImportSchema.merge(ConfirmedImportSchema),
    }),
}));
// #region Status
export const VeriskStatusPublishConfirmedEventSchema = VediPublishConfirmedEventSchema.merge(z.object({
    metadata: VediPublishConfirmedEventSchema.shape.metadata.and(z.object({
        importType: z.literal(VediImportType.status),
    })),
    publication: z.object({
        vediExport: ParsedStatusExportSchema,
        vediImport: VediStatusImportSchema.merge(ConfirmedImportSchema),
    }),
}));
// #endregion Status
