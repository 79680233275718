import { zodRecordWithEnum } from '@eigtech/function-utils';
import { cornType } from '@eigtech/shared-corn';
import { z } from 'zod';
import { serviceName } from '../service.js';
import { HourlyCostSchema, RoofPitchSchema, TarpingCostSchema } from './cost.js';
const resourceType = 'region';
export const RegionRatesSchema = z.object({
    baseRate: HourlyCostSchema,
    highRoof: z.number(),
    roofPitch: zodRecordWithEnum(RoofPitchSchema, z.number()),
    tarping: TarpingCostSchema,
    surtax: z
        .number()
        .min(0)
        .max(1)
        .describe('Discretionary county surtax applied to the first $5000 of materials')
        .optional(),
});
export const RegionCornSchema = cornType(serviceName, resourceType);
export const isRegionCorn = (corn) => RegionCornSchema.safeParse(corn).success;
export const makeRegionCorn = (resourceId) => `corn:${serviceName}:${resourceType}:${resourceId}`;
export const RegionSchema = z.object({
    id: RegionCornSchema,
    name: z.string(),
    rates: RegionRatesSchema,
});
