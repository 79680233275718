import { LossOfUseStatusSchema, LossOfUseTypeSchema } from '@eigtech/claims-v2-types';
import { ContactCornSchema } from '@eigtech/contacts-types';
import { CurrentClaimCornSchema } from '@eigtech/shared-corn-helper';
import z from 'zod';
import { makeSummaryFilterSchema, makeSummaryParamsSchema } from './pagination.js';
export const ClaimSummarySchema = z.object({
    id: CurrentClaimCornSchema,
    claimNumber: z.string(),
    dateReceived: z.string().optional(),
    inAssignQueue: z.string().optional(),
    inspectionScheduled: z.string().optional(),
    inspectionCompleted: z.string().optional(),
    latestTimelineEvent: z.string().optional(),
    lossDate: z.string().optional(),
    lossLocation: z.object({
        city: z.string(),
        state: z.string(),
        county: z.string().optional(),
    }),
    primaryContact: z
        .object({
        name: z.string(),
        phone: z.string().optional(),
        email: z.string().optional(),
    })
        .optional(),
    policyHolder: z
        .object({
        name: z.string(),
        phone: z.string().optional(),
        email: z.string().optional(),
    })
        .optional(),
    carrier: z.string().optional(),
    coordinator: z
        .object({
        id: ContactCornSchema,
        name: z.string().optional(),
    })
        .optional(),
    fieldAdjuster: z
        .object({
        id: ContactCornSchema,
        name: z.string().optional(),
    })
        .optional(),
    hasLegalRep: z.boolean(),
    catCode: z.string().optional(),
    claimStatus: z.string().optional(),
    hasJob: z.boolean(),
    lossOfUseStatus: LossOfUseStatusSchema.array().optional(),
    lossOfUseType: LossOfUseTypeSchema.array().optional(),
    hasLossOfUseReceipts: z.boolean().optional(),
});
export const ClaimSummaryFieldSchema = z.enum([
    'addressType',
    'carrier',
    'carrierId',
    'catCode',
    'city',
    'claimNumber',
    'claimReviewers',
    'claimStatus',
    'coordinatorId',
    'coordinatorName',
    'county',
    'dateOfLoss',
    'dateReceived',
    'fieldAdjusterId',
    'fieldAdjusterName',
    'hasCatCode',
    'hasClaimReview',
    'hasCoordinator',
    'hasFieldAdjuster',
    'hasInspectionTechnician',
    'hasInitialClaimAction',
    'hasJob',
    'hasLegalRep',
    'hasLossOfUse',
    'inAssignQueue',
    'inspectionCompleted',
    'inspectionScheduled',
    'jobContracted',
    'jobNotSold',
    'latestTimelineEvent',
    'lossDate',
    'policyHolderEmail',
    'policyHolderName',
    'policyHolderPhone',
    'primaryContactEmail',
    'primaryContactName',
    'primaryContactPhone',
    'severity',
    'state',
    'inspectionsStatus',
]);
export const ClaimSummaryFilterSchema = makeSummaryFilterSchema(ClaimSummaryFieldSchema);
export const ClaimSummariesParamsSchema = makeSummaryParamsSchema(ClaimSummaryFieldSchema, ClaimSummaryFilterSchema);
