import { VediStatusImportSchema } from '../vediImport.js';
export const ImportStatusRequestBodySchema = VediStatusImportSchema.pick({
    claimNumber: true,
    transactionId: true,
    user: true,
    note: true,
    status: true,
    timestamp: true,
});
export const ImportStatusRequestSchema = ImportStatusRequestBodySchema;
