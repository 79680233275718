import z from 'zod';
import { SuccessApiOpDetailsSchema } from './base/auth0Events/SuccessApiOperationDetails.js';
// description: "Assign one or more roles to a given user that will be applied in the context of the provided organization"
// path: '/api/v2/organizations/org_Miqv9tEm8irQwL17/members/auth0%7C63377b17eb80942f6f0b4b3a/roles'
export const RoleAssignedToOrgUserBodySchema = SuccessApiOpDetailsSchema.extend({
    request: SuccessApiOpDetailsSchema.shape.request.extend({
        method: z.literal('post'),
        query: z.object({}),
        body: z.object({
            roles: z.string().array(),
        }),
    }),
    response: SuccessApiOpDetailsSchema.shape.response.extend({
        body: z.object({}),
    }),
});
export const RoleAssignedToOrgUserPathSchema = z.object({
    userId: z.string(),
    orgId: z.string(),
});
export const RoleAssignedToOrgUserRequestSchema = RoleAssignedToOrgUserBodySchema.merge(RoleAssignedToOrgUserPathSchema);
