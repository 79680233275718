import { ContactCornSchema } from '@eigtech/contacts-types';
import z from 'zod';
import { ClaimNumberSchema, InspectorRoleSchema, PublicationTargetsSchema, ScheduledInspectionSchema, } from '../model/index.js';
import { BaseHeaderSchema } from './base.js';
export const ScheduleClaimInspectionApiSchema = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: z.object({
        inspectionId: z.string().optional(),
        inspectorRole: InspectorRoleSchema,
        notes: z.string().optional(),
        publicationTargets: PublicationTargetsSchema.array().optional(),
        scheduledBy: ContactCornSchema.or(z.string()),
        scheduledDate: z.string().datetime(),
    }),
    headers: BaseHeaderSchema,
    response: z
        .object({
        claimNumber: ClaimNumberSchema,
    })
        .merge(ScheduledInspectionSchema),
};
export const ScheduleClaimInspectionRequestSchema = ScheduleClaimInspectionApiSchema.params
    .merge(ScheduleClaimInspectionApiSchema.body)
    .merge(ScheduleClaimInspectionApiSchema.headers);
