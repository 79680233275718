import { Schema, defineTable } from 'squid';
import z from 'zod';
const timelineTable = defineTable('timeline', {
    entity_id: Schema.String,
    entity_type: Schema.String,
    timeline_id: Schema.String,
    timeline_type: Schema.String,
    chronicles: Schema.Array(Schema.Object({
        created_by: Schema.nullable(Schema.String),
        domain: Schema.String,
        date: Schema.String,
        type: Schema.String,
        label: Schema.String,
    })),
});
export const ChroniclesColumnSchema = z
    .object({
    created_by: z.string().optional(),
    date: z.string(),
    details: z.record(z.unknown()).optional(),
    domain: z.string(),
    label: z.string(),
    type: z.string(),
})
    .array();
