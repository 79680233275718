import { z } from 'zod';
import { BaseClaimSchema } from '../model/base.js';
import { LossOfUseReceiptRemovedSchema, LossOfUseReceiptSchema, LossOfUseSchema, } from '../model/lossOfUse.js';
import { ClaimEventSchema, ClaimEventType, claimEventType } from './claim.js';
export const LossOfUseEventSchema = ClaimEventSchema.merge(z.object({
    claim: BaseClaimSchema.merge(z.object({
        lossOfUse: LossOfUseSchema.omit({ receipts: true }),
    })),
}));
export const LossOfUseAddedEventSchema = LossOfUseEventSchema.merge(z.object({
    type: claimEventType(ClaimEventType.lossOfUseAdded),
    claim: BaseClaimSchema.merge(z.object({
        lossOfUse: LossOfUseSchema,
    })),
}));
export const isLossOfUseAddedEvent = (evt) => LossOfUseAddedEventSchema.safeParse(evt).success;
export const LossOfUseCanceledEventSchema = LossOfUseEventSchema.merge(z.object({
    type: claimEventType(ClaimEventType.lossOfUseCanceled),
}));
export const LossOfUsePendingEventSchema = LossOfUseEventSchema.merge(z.object({
    type: claimEventType(ClaimEventType.lossOfUsePending),
}));
export const LossOfUseApprovedEventSchema = LossOfUseEventSchema.merge(z.object({
    type: claimEventType(ClaimEventType.lossOfUseApproved),
}));
export const LossOfUseDeniedEventSchema = LossOfUseEventSchema.merge(z.object({
    type: claimEventType(ClaimEventType.lossOfUseDenied),
}));
export const LossOfUseReceiptAddedEventSchema = LossOfUseEventSchema.merge(z.object({
    type: claimEventType(ClaimEventType.lossOfUseReceiptAdded),
    claim: LossOfUseEventSchema.shape.claim.merge(z.object({
        lossOfUse: LossOfUseEventSchema.shape.claim.shape.lossOfUse.merge(z.object({
            receipt: LossOfUseReceiptSchema,
        })),
    })),
}));
export const isLossOfUseReceiptAddedEvent = (evt) => LossOfUseReceiptAddedEventSchema.safeParse(evt).success;
export const LossOfUseReceiptRemovedEventSchema = LossOfUseEventSchema.merge(z.object({
    type: claimEventType(ClaimEventType.lossOfUseReceiptRemoved),
    claim: LossOfUseEventSchema.shape.claim.merge(z.object({
        lossOfUse: LossOfUseEventSchema.shape.claim.shape.lossOfUse.merge(z.object({
            receipt: LossOfUseReceiptRemovedSchema,
        })),
    })),
}));
export const isLossOfUseReceiptRemovedEvent = (evt) => LossOfUseReceiptRemovedEventSchema.safeParse(evt).success;
