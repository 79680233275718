import z from 'zod';
import { TimelineEntityTypeSchema } from '../../timeline/index.js';
export const SyncTimelinesApiSchema = {
    params: z.object({
        entityType: TimelineEntityTypeSchema,
    }),
    response: z.object({
        executionArn: z.string(),
    }),
};
