import { z } from 'zod';
import { summarizerServiceCorn } from '../base/index.js';
import { TimelineEntityType, TimelineSchema, TimelineType } from './timeline.js';
export const JobTimelineTypeSchema = z.enum([TimelineType.default, TimelineType.insured]);
export const JobTimelineType = JobTimelineTypeSchema.Enum;
export const JobTimelineSchema = TimelineSchema.merge(z.object({
    entityType: z.literal(TimelineEntityType.job),
    timelineType: JobTimelineTypeSchema,
}));
export const JobDefaultTimelineSchema = JobTimelineSchema.merge(z.object({
    timelineId: summarizerServiceCorn('jobDefaultTimeline'),
    timelineType: z.literal(JobTimelineType.default),
}));
export const JobInsuredTimelineSchema = JobTimelineSchema.merge(z.object({
    timelineId: summarizerServiceCorn('jobInsuredTimeline'),
    timelineType: z.literal(JobTimelineType.insured),
}));
