import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { JobAppointmentIdSchema, JobIdSchema } from '../../base/ids.js';
export const RecordAppointmentCompletedRequestBodySchema = z.object({
    appointmentId: JobAppointmentIdSchema,
    appointmentNotes: z.string().optional(),
});
export const RecordAppointmentCompletedRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
});
export const RecordAppointmentCompletedRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const RecordAppointmentCompletedRequestSchema = RecordAppointmentCompletedRequestBodySchema.merge(RecordAppointmentCompletedRequestHeadersSchema).merge(RecordAppointmentCompletedRequestPathSchema);
export const RecordAppointmentCompletedResponseSchema = z.void();
export const RecordAppointmentCompletedApiSchema = {
    body: RecordAppointmentCompletedRequestBodySchema,
    headers: RecordAppointmentCompletedRequestHeadersSchema,
    params: RecordAppointmentCompletedRequestPathSchema,
};
