import { z } from 'zod';
import { ClaimSchema } from '../aggregates/claim.js';
import { ClaimsServiceEventBaseSchema, claimsServiceEventType } from './service.js';
/** @deprecated */
const claimEventType = (eventName) => claimsServiceEventType('claim', eventName);
/** @deprecated */
export const ClaimEventSchema = ClaimsServiceEventBaseSchema.merge(z.object({
    type: claimEventType(),
    claim: z.unknown(),
    schemaVersion: z.literal(1).or(z.literal(2)),
}));
/** @deprecated */
export const ClaimCreatedEventSchema = ClaimEventSchema.merge(z.object({
    type: claimEventType('created'),
    claim: ClaimSchema,
}));
/** @deprecated */
export const ClaimUpdatedEventSchema = ClaimEventSchema.merge(z.object({
    type: claimEventType('updated'),
    claim: ClaimSchema,
}));
/** @deprecated */
export const isClaimCreatedEvent = (event) => ClaimCreatedEventSchema.safeParse(event).success;
/** @deprecated */
export const isClaimUpdatedEvent = (event) => ClaimUpdatedEventSchema.safeParse(event).success;
