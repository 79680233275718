import { z } from 'zod';
import { SuccessLoginDetailsSchema } from '../logStreamEvents/index.js';
import { ServiceEventBaseSchema, serviceEventType, serviceName } from './service.js';
const auth0LogEventEntity = 'login';
const auth0LogEventType = (eventName) => serviceEventType(auth0LogEventEntity, eventName);
export const auth0LoginEventTypePrefix = `${serviceName}:${auth0LogEventEntity}:`;
export const Auth0EventSchema = ServiceEventBaseSchema.merge(z.object({
    type: auth0LogEventType(),
    auth0: z.unknown(),
    schemaVersion: z.literal(1),
}));
export const SuccessLoginEventSchema = Auth0EventSchema.merge(z.object({
    type: auth0LogEventType('successLogin'),
    auth0: z.object({
        userId: z.string(),
        details: SuccessLoginDetailsSchema,
    }),
}));
export const SuccessLogoutEventSchema = Auth0EventSchema.merge(z.object({
    type: auth0LogEventType('successLogout'),
    auth0: z.object({
        userId: z.string(),
    }),
}));
