import { ErrorAlertEventSchema, WarnAlertEventSchema, } from '@eigtech/alerts-types';
import z, { discriminatedUnion } from 'zod';
export const StartCaptureFailedAlertEventSchema = ErrorAlertEventSchema.merge(z.object({
    type: z.literal('alert:error:startCaptureFailed'),
    metadata: z.object({
        service: z.literal('meetings'),
    }),
}));
export const StartConcatenationFailedAlertEventSchema = ErrorAlertEventSchema.merge(z.object({
    type: z.literal('alert:error:startConcatenationFailed'),
    metadata: z.object({
        service: z.literal('meetings'),
    }),
}));
export const StartTranscriptionFailedAlertEventSchema = ErrorAlertEventSchema.merge(z.object({
    type: z.literal('alert:error:startTranscriptionFailed'),
    metadata: z.object({
        service: z.literal('meetings'),
    }),
}));
export const StartMediaFailedAlertEventSchema = discriminatedUnion('type', [
    StartCaptureFailedAlertEventSchema,
    StartConcatenationFailedAlertEventSchema,
    StartTranscriptionFailedAlertEventSchema,
]);
export const EndMeetingFailedAlertEventSchema = WarnAlertEventSchema.merge(z.object({
    type: z.literal('alert:warn:endMeetingFailed'),
    metadata: z.object({
        service: z.literal('meetings'),
    }),
}));
