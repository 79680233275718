import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { ClaimSummaryFieldSchema, ClaimSummarySchema } from '../../queryModels/claimQueryModel.js';
import { PageResponsePropertiesSchema, makePageRequestSchema } from '../pagination.js';
export const GetClaimSummariesInternalSchemas = {
    query: makePageRequestSchema(ClaimSummaryFieldSchema),
    response: z
        .object({
        claims: ClaimSummarySchema.array(),
    })
        .merge(PageResponsePropertiesSchema),
};
export const GetClaimSummariesSchemas = {
    ...GetClaimSummariesInternalSchemas,
    headers: ApiLambdalerAuthHeaderSchema.pick({ requestToken: true }).required({
        requestToken: true,
    }),
    response: z
        .object({
        claims: ClaimSummarySchema.partial().array(),
    })
        .merge(PageResponsePropertiesSchema),
};
export const GetClaimSummariesInternalRequestSchema = GetClaimSummariesInternalSchemas.query;
export const GetClaimSummariesRequestSchema = GetClaimSummariesSchemas.query.merge(GetClaimSummariesSchemas.headers);
export const GetClaimSummariesInternalResponseSchema = GetClaimSummariesInternalSchemas.response;
export const GetClaimSummariesResponseSchema = GetClaimSummariesSchemas.response;
export const GetClaimSummariesApiSchema = GetClaimSummariesSchemas;
export const GetClaimSummariesInternalApiSchema = GetClaimSummariesInternalSchemas;
