import { z } from 'zod';
// https://zod.dev/?id=writing-generic-functions
export const makePageRequestSchema = (sortFields) => z.object({
    page: z.coerce.number().min(1),
    pageSize: z.coerce.number().min(10),
    sortBy: sortFields,
    // query string will come in as string value of `"true" | "false"`
    // we can't use z.coerce because `"false"` is truthy and will be
    // coerced to `true`. Using the transform allows us properly coerce
    // `"false"` to `false`
    sortAscending: z.boolean().or(z
        .enum(['true', 'false'])
        .transform((value) => JSON.parse(value))
        .pipe(z.boolean())),
    filter: z.string(),
});
export const PageResponsePropertiesSchema = z.object({
    page: z.number(),
    pageSize: z.number(),
    totalItems: z.number(),
    totalPages: z.number(),
});
