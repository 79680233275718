import z from 'zod';
export const LinkAccountsRequestBodySchema = z.object({
    primaryUserId: z.string(),
    secondaryUserId: z.string(),
});
export const LinkAccountsRequestSchema = LinkAccountsRequestBodySchema;
export const LinkAccountsResponseSchema = z.void();
export const LinkAccountsApiSchema = {
    body: LinkAccountsRequestBodySchema,
};
