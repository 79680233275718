import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { ClaimNumberSchema } from '../model/index.js';
export const UpdateLossDescriptionSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: z.object({
        lossDescription: z.string(),
    }),
    headers: ApiLambdalerAuthHeaderSchema.pick({ requestActor: true }).required({
        requestActor: true,
    }),
    response: z.object({
        claimNumber: ClaimNumberSchema,
        lossDetails: z.object({
            lossDescription: z.string(),
        }),
    }),
};
export const UpdateLossDescriptionRequestSchema = UpdateLossDescriptionSchemas.params
    .merge(UpdateLossDescriptionSchemas.body)
    .merge(UpdateLossDescriptionSchemas.headers);
export const UpdateLossDescriptionApiSchema = UpdateLossDescriptionSchemas;
