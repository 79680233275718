import { z } from 'zod';
import { claimsServiceCorn } from '../base/service.js';
/** @deprecated */
export const LossDetailsCornSchema = claimsServiceCorn('lossDetails');
/** @deprecated */
export const LossTypeEnumSchema = z.string();
/** @deprecated */
export const LossDetailsSchema = z.object({
    catCode: z.string().optional(),
    claimNumber: z.string(),
    dataSource: z.string().optional(),
    dateOfLoss: z.string().optional(),
    dateReceived: z.string().optional(),
    id: z.string(),
    lossDescription: z.string().optional(),
    lossType: z.string(),
    receivedBy: z.string().optional(),
    sourceId: z.string().optional(),
});
