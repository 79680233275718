import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { VendorRatesSchema } from '../../base/vendor.js';
export const CreateVendorRatesApiSchema = {
    body: z.object({
        vendorRates: VendorRatesSchema.omit({ id: true }),
    }),
    headers: ApiLambdalerAuthHeaderWithRequestActorSchema,
    response: z.object({
        vendorRates: VendorRatesSchema,
    }),
};
