import z from 'zod';
import { ClaimNumberSchema, ExternalSystemIdSchema, ExternalSystemRecordSchema, } from '../model/index.js';
import { BaseHeaderSchema } from './base.js';
const RemoveExternalSystemIdSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: z.object({
        key: ExternalSystemIdSchema,
    }),
    headers: BaseHeaderSchema,
    response: z.object({
        claimNumber: ClaimNumberSchema,
        externalSystemIds: ExternalSystemRecordSchema,
    }),
};
export const RemoveExternalSystemIdRequestSchema = RemoveExternalSystemIdSchemas.params
    .merge(RemoveExternalSystemIdSchemas.body)
    .merge(RemoveExternalSystemIdSchemas.headers);
export const RemoveExternalSystemIdApiSchema = RemoveExternalSystemIdSchemas;
