import z from 'zod';
import { ClaimNumberSchema, CompletedReviewSchema } from '../model/index.js';
import { BaseHeaderSchema } from './base.js';
const CompleteClaimReviewSchemas = {
    body: z.object({
        notes: z.string(),
    }),
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    headers: BaseHeaderSchema,
    response: z
        .object({
        claimNumber: ClaimNumberSchema,
    })
        .merge(CompletedReviewSchema),
};
export const CompleteClaimReviewRequestSchema = CompleteClaimReviewSchemas.body
    .merge(CompleteClaimReviewSchemas.params)
    .merge(CompleteClaimReviewSchemas.headers);
export const CompleteClaimReviewApiSchema = CompleteClaimReviewSchemas;
