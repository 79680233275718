import z from 'zod';
import { JobIdSchema } from './ids.js';
import { JobLocationSchema } from './jobLocation.js';
import { JobStatusSchema } from './jobStatus.js';
import { WorkDetailsSchema } from './workDetails.js';
/**
 * This is the base schema for different types of jobs (ie. EmergencyServicesJob, OtrJob, DailyJob).
 * Note: a job number is an incremental count of how jobs have been in the system, whereas job ID
 * can be anything.
 */
export const BaseJobSchema = z.object({
    jobDescription: z.string(),
    jobId: JobIdSchema,
    jobNumber: z.number(),
    jobLocation: JobLocationSchema,
    jobType: z.unknown(),
    services: z.unknown(),
    status: JobStatusSchema.optional(),
    workDetails: WorkDetailsSchema.optional(),
});
