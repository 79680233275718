import { z } from 'zod';
import { ContactCornSchema, ContactSchema } from '../../../base/contacts.js';
export const GetContactRequestPathParametersSchema = z.object({
    contactId: ContactCornSchema,
});
export const GetContactRequestSchema = GetContactRequestPathParametersSchema;
export const GetContactResponseSchema = ContactSchema;
export const GetContactApiSchema = {
    params: GetContactRequestPathParametersSchema,
    response: GetContactResponseSchema,
};
