export var JobInsuredTimelineLabel;
(function (JobInsuredTimelineLabel) {
    JobInsuredTimelineLabel["jobCreated"] = "Job Created";
    JobInsuredTimelineLabel["scheduledDate"] = "Scheduled Date";
    JobInsuredTimelineLabel["workCompleted"] = "Work Completed";
    JobInsuredTimelineLabel["workDetailsReceived"] = "Work Details Received";
    JobInsuredTimelineLabel["workStarted"] = "Work Started";
})(JobInsuredTimelineLabel || (JobInsuredTimelineLabel = {}));
export const jobInsuredChronicleLabelMap = {
    appointmentCompleted: JobInsuredTimelineLabel.workStarted,
    appointmentScheduled: JobInsuredTimelineLabel.scheduledDate,
    cancelled: JobInsuredTimelineLabel.workCompleted,
    completed: JobInsuredTimelineLabel.workCompleted,
    received: JobInsuredTimelineLabel.jobCreated,
    withdrawn: JobInsuredTimelineLabel.workCompleted,
    workDetailsReceived: JobInsuredTimelineLabel.workDetailsReceived,
};
