import { ContactCornSchema } from '@eigtech/contacts-types';
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { JobExternalIdSchema, JobIdSchema } from '../../base/index.js';
export const RecordCarrierInvoicedRequestBodySchema = z.object({
    carrierInvoice: z.object({
        externalId: JobExternalIdSchema.optional(),
        carrier: ContactCornSchema.or(z.string()),
        invoicedDate: z.string().datetime(),
        notes: z.string().optional(),
    }),
});
export const RecordCarrierInvoicedRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
});
export const RecordCarrierInvoicedRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const RecordCarrierInvoicedRequestSchema = RecordCarrierInvoicedRequestBodySchema.merge(RecordCarrierInvoicedRequestHeadersSchema).merge(RecordCarrierInvoicedRequestPathSchema);
export const RecordCarrierInvoicedResponseSchema = z.void();
export const RecordCarrierInvoicedApiSchema = {
    body: RecordCarrierInvoicedRequestBodySchema,
    headers: RecordCarrierInvoicedRequestHeadersSchema,
    params: RecordCarrierInvoicedRequestPathSchema,
};
