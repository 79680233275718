import { z } from 'zod';
import { RoleSchema } from '../../logStreamEvents/base/base.js';
export const GetRolesForUserRequestPathSchema = z.object({
    userId: z.string(),
});
export const GetRolesForUserRequestSchema = GetRolesForUserRequestPathSchema;
export const GetRolesForUserResponseSchema = z.object({
    roles: RoleSchema.array(),
});
export const GetRolesForUserApiSchema = {
    params: GetRolesForUserRequestPathSchema,
    response: GetRolesForUserResponseSchema,
};
