import { z } from 'zod';
export const MediaPlacementSchema = z.object({
    AudioHostUrl: z.string().optional(),
    /**
     * <p>The audio fallback URL.</p>
     */
    AudioFallbackUrl: z.string().optional(),
    /**
     * <p>The signaling URL.</p>
     */
    SignalingUrl: z.string().optional(),
    /**
     * <p>The turn control URL.</p>
     */
    TurnControlUrl: z.string().optional(),
    /**
     * <p>The screen data URL.</p>
     */
    ScreenDataUrl: z.string().optional(),
    /**
     * <p>The screen viewing URL.</p>
     */
    ScreenViewingUrl: z.string().optional(),
    /**
     * <p>The screen sharing URL.</p>
     */
    ScreenSharingUrl: z.string().optional(),
    /**
     * <p>The event ingestion URL.</p>
     */
    EventIngestionUrl: z.string().optional(),
});
export const AudioFeaturesSchema = z.object({
    /**
     * <p>Makes echo reduction available to clients who connect to the meeting.</p>
     */
    EchoReduction: z.enum(['AVAILABLE', 'UNAVAILABLE']).optional(),
});
export const MeetingFeaturesConfigurationSchema = z.object({
    /**
     * <p>The configuration settings for the audio features available to a meeting. </p>
     */
    Audio: AudioFeaturesSchema.optional(),
});
export const MeetingSchema = z.object({
    MeetingId: z.string().optional(),
    MeetingHostId: z.string().optional(),
    ExternalMeetingId: z.string().optional(),
    MediaRegion: z.string().optional(),
    MediaPlacement: MediaPlacementSchema.optional(),
    MeetingFeatures: MeetingFeaturesConfigurationSchema.optional(),
    PrimaryMeetingId: z.string().optional(),
    TenantIds: z.string().array().optional(),
    MeetingArn: z.string().optional(),
});
export const MediaCapabilitiesSchema = z.enum(['None', 'Receive', 'Send', 'SendReceive']);
export const AttendeeCapabilitiesSchema = z.object({
    /**
     * <p>The audio capability assigned to an attendee.</p>
     */
    Audio: MediaCapabilitiesSchema.or(z.string()).optional(),
    /**
     * <p>The video capability assigned to an attendee.</p>
     */
    Video: MediaCapabilitiesSchema.or(z.string()).optional(),
    /**
     * <p>The content capability assigned to an attendee.</p>
     */
    Content: MediaCapabilitiesSchema.or(z.string()).optional(),
});
export const AttendeeSchema = z.object({
    /**
     * <p>The Amazon Chime SDK external user ID. An idempotency token. Links the attendee to an identity managed by a builder application.</p>
     */
    ExternalUserId: z.string().optional(),
    /**
     * <p>The Amazon Chime SDK attendee ID.</p>
     */
    AttendeeId: z.string().optional(),
    /**
     * <p>The join token used by the Amazon Chime SDK attendee.</p>
     */
    JoinToken: z.string().optional(),
    /**
     * <p>The capabilities assigned to an attendee: audio, video, or content.</p>
     *         <note>
     *             <p>You use the capabilities with a set of values that control what the capabilities can do, such as <code>SendReceive</code> data. For more information about those values, see
     *             .</p>
     *          </note>
     *
     *         <p>When using capabilities, be aware of these corner cases:</p>
     *         <ul>
     *             <li>
     *                 <p>You can't set <code>content</code> capabilities to <code>SendReceive</code> or <code>Receive</code> unless you also set <code>video</code> capabilities to <code>SendReceive</code>
     *                     or <code>Receive</code>. If you don't set the <code>video</code> capability to receive, the response will contain an HTTP 400 Bad Request status code. However, you can set your <code>video</code> capability
     *                     to receive and you set your <code>content</code> capability to not receive.</p>
     *             </li>
     *             <li>
     *                 <p>When you change an <code>audio</code> capability from <code>None</code> or <code>Receive</code> to <code>Send</code> or <code>SendReceive</code> ,
     *                     and if the attendee left their microphone unmuted, audio will flow from the attendee to the other meeting participants.</p>
     *             </li>
     *             <li>
     *                 <p>When you change a <code>video</code> or <code>content</code> capability from <code>None</code> or <code>Receive</code> to <code>Send</code> or <code>SendReceive</code> ,
     *                     and if the attendee turned on their video or content streams, remote attendess can receive those streams, but only after media renegotiation between the client and the Amazon Chime back-end server.</p>
     *             </li>
     *          </ul>
     */
    Capabilities: AttendeeCapabilitiesSchema.optional(),
});
// see https://docs.aws.amazon.com/chime-sdk/latest/dg/meeting-events.html
export const ChimeLifeCycleEventSchema = z.object({
    Timestamp: z.string(),
    EventType: z.string(),
    EventParameters: z.record(z.string(), z.unknown()).optional(),
});
const ChimeChannelMessageTypeSchema = z.enum(['CONTROL', 'STANDARD']);
const ChimeChannelMessageStatusSchema = z.enum(['DENIED', 'FAILED', 'PENDING', 'SENT']);
export const ChimeChannelMessageSchema = z.object({
    MessageId: z.string(),
    Content: z.string(),
    Type: ChimeChannelMessageTypeSchema,
    CreatedTimestamp: z.date(),
    LastUpdatedTimestamp: z.date(),
    Sender: z.object({
        Arn: z.string(),
        Name: z.string(),
    }),
    Redacted: z.boolean(),
    Status: z.object({
        Value: ChimeChannelMessageStatusSchema,
    }),
});
export const isChimeChannelMessage = (m) => ChimeChannelMessageSchema.safeParse(m).success;
export const ChimeChannelMessageTypes = {
    markScreenshot: '/mark_screenshot',
    startMeeting: '/start_meeting',
    startTranscription: '/start_transcription',
    startVideo: '/start_video',
};
export const ChimeAppInstanceItemSchema = z.object({
    appInstanceArn: z.string(),
});
export const ChimeAdminUserItemSchema = z.object({
    adminUserArn: z.string(),
});
