import { z } from 'zod';
import { ClaimEventSchema, ClaimEventType, claimEventType } from './claim.js';
import { BaseClaimSchema } from '../model/base.js';
import { InitialClaimActionsSchema } from '../model/initialActions.js';
export const InitialClaimActionsAddedEventSchema = ClaimEventSchema.merge(z.object({
    type: claimEventType(ClaimEventType.initialClaimActionsAdded),
    claim: BaseClaimSchema.merge(z.object({
        initialClaimActions: InitialClaimActionsSchema.array(),
    })),
}));
export const InitialClaimActionsRemovedEventSchema = ClaimEventSchema.merge(z.object({
    type: claimEventType(ClaimEventType.initialClaimActionsRemoved),
    claim: BaseClaimSchema.merge(z.object({
        initialClaimActions: InitialClaimActionsSchema.array(),
    })),
}));
export const InitialClaimActionsUpdatedEventSchema = ClaimEventSchema.merge(z.object({
    type: claimEventType(ClaimEventType.initialClaimActionsUpdated),
    claim: BaseClaimSchema.merge(z.object({
        initialClaimActions: InitialClaimActionsSchema.array(),
    })),
}));
