import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { CornSchema } from '@eigtech/shared-corn';
import z from 'zod';
export const UpdateMeetingRequestBodySchema = z.object({
    description: z.string(),
});
export const UpdateMeetingRequestPathSchema = z.object({
    entityId: CornSchema,
    meetingId: z.string(),
    startTime: z.string().datetime(),
});
export const UpdateMeetingRequestHeaderSchema = ApiLambdalerAuthHeaderSchema;
export const UpdateMeetingRequestSchema = UpdateMeetingRequestPathSchema.merge(UpdateMeetingRequestHeaderSchema).merge(UpdateMeetingRequestBodySchema);
