import z from 'zod';
import { ImportStatusesSchema } from './importStatus.js';
export const NoteIdSchema = z.string();
export const GetNoteImportStatusRequestQueryStringSchema = z.object({
    claimNumber: z.string().optional(),
    transactionId: z.string().optional(),
});
export const GetNoteImportStatusRequestSchema = GetNoteImportStatusRequestQueryStringSchema;
export const GetNoteImportStatusResponseSchema = z.record(NoteIdSchema, ImportStatusesSchema);
export const GetNoteImportStatusApiSchema = {
    query: GetNoteImportStatusRequestQueryStringSchema,
    response: GetNoteImportStatusResponseSchema,
};
