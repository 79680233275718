import { Schema, defineTable } from 'squid';
const lossDetailsTable = defineTable('loss_details', {
    cat_code: Schema.nullable(Schema.String),
    claim_number: Schema.String,
    data_source: Schema.nullable(Schema.String),
    date_of_loss: Schema.nullable(Schema.String),
    loss_description: Schema.nullable(Schema.String),
    loss_type: Schema.nullable(Schema.String),
    received_by: Schema.nullable(Schema.String),
    severity: Schema.nullable(Schema.String),
    source_id: Schema.nullable(Schema.String),
});
