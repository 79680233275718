import z from 'zod';
import { ClaimNumberSchema, ClaimFactorsSchema } from '../model/base.js';
import { BaseHeaderSchema } from './base.js';
const AddClaimFactorsSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: z.object({
        claimFactors: ClaimFactorsSchema.array(),
    }),
    headers: BaseHeaderSchema,
    response: z.object({
        claimNumber: ClaimNumberSchema,
        claimFactors: ClaimFactorsSchema.array(),
    }),
};
export const AddClaimFactorsRequestSchema = AddClaimFactorsSchemas.params
    .merge(AddClaimFactorsSchemas.body)
    .merge(AddClaimFactorsSchemas.headers);
export const AddClaimFactorsApiSchema = AddClaimFactorsSchemas;
