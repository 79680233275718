import z from 'zod';
import { DailyServiceSchema } from './dailyJob.js';
import { EmergencyServicesServiceSchema, } from './emergencyServicesJob.js';
import { OtrServiceSchema } from './otrJob.js';
export const JobServicesSchema = z.union([
    DailyServiceSchema.array(),
    EmergencyServicesServiceSchema.array(),
    OtrServiceSchema.array(),
]);
export const jobServiceSchemaMap = {
    daily: DailyServiceSchema,
    emergencyServices: EmergencyServicesServiceSchema,
    otr: OtrServiceSchema,
};
export const jobServiceArraySchemaMap = {
    daily: jobServiceSchemaMap.daily.array(),
    emergencyServices: jobServiceSchemaMap.emergencyServices.array(),
    otr: jobServiceSchemaMap.otr.array(),
};
