import { Schema, defineTable } from 'squid';
const jobLocationTable = defineTable('job_location', {
    job_id: Schema.String,
    address_line_1: Schema.String,
    address_line_2: Schema.nullable(Schema.String),
    address_line_3: Schema.nullable(Schema.String),
    city: Schema.nullable(Schema.String),
    state_or_province: Schema.nullable(Schema.String),
    zip_or_postal_code: Schema.nullable(Schema.String),
    country: Schema.nullable(Schema.String),
    address_type: Schema.nullable(Schema.String),
    county_or_parish_code: Schema.nullable(Schema.String),
    geo_accuracy: Schema.nullable(Schema.String),
    latitude: Schema.nullable(Schema.String),
    longitude: Schema.nullable(Schema.String),
});
