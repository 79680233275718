import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { JobIdSchema } from '../../base/ids.js';
export const ReopenJobRequestBodySchema = z.object({
    notes: z.string().optional(),
    reopenedDate: z.string().datetime(),
});
export const ReopenJobRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const ReopenJobRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
});
export const ReopenJobRequestSchema = ReopenJobRequestBodySchema.merge(ReopenJobRequestHeadersSchema).merge(ReopenJobRequestPathSchema);
export const ReopenJobResponseSchema = z.void();
export const ReopenJobApiSchema = {
    body: ReopenJobRequestBodySchema,
    headers: ReopenJobRequestHeadersSchema,
    params: ReopenJobRequestPathSchema,
};
