import { Schema, defineTable } from 'squid';
const jobWorkAuthTable = defineTable('job_work_auth', {
    work_auth_id: Schema.String,
    job_id: Schema.String,
    approved_by: Schema.nullable(Schema.String),
    date_closed: Schema.nullable(Schema.String),
    effective_date: Schema.nullable(Schema.String),
    recipient: Schema.String,
    sent_date: Schema.String,
    sent_method: Schema.String,
    signer: Schema.nullable(Schema.String),
    signed_on: Schema.nullable(Schema.String),
    status: Schema.String,
});
