import z from 'zod';
import { ControlPointType } from '../shared/controlPointType.js';
export const AssignmentCreatedStatusEnum = z.enum([
    'Note',
    'Carrier Desk Adjuster Assigned',
    'Carrier Desk Adjuster Removed',
    'Sender Desk Adjuster Assigned',
    'Sender Desk Adjuster Removed',
    'Attention Needed',
]);
export const AssignmentUpdatedStatusEnum = z.enum([
    'Reviewed',
    'Contacted',
    'Inspected',
    'Not Sold',
    'QA Rejected',
    'Rejected', // documentation for this status is unclear, careful if using it
    'QA Approved',
    'Client Approved',
    'Client Rejected',
    'Job Started',
    'Job Completed',
    'File Closed',
    'File Reopened',
    'Appointment Scheduled',
    'Appointment Cancelled',
    'Planned Inspection',
    'Site Inspected',
    // ControlPointType.canceled, // this has to be sent as an assignment
    ControlPointType.jobCompleted,
    ControlPointType.jobNotSold,
    ControlPointType.jobStarted,
]);
export const XAStatusEnum = z.enum([
    ...AssignmentCreatedStatusEnum.options,
    ...AssignmentUpdatedStatusEnum.options,
]);
