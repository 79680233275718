import z from 'zod';
import { JobRestorationChronicleType } from './jobChronicles.js';
export const JobInsuredChronicleTypeSchema = z.enum([
    JobRestorationChronicleType.appointmentCompleted,
    JobRestorationChronicleType.appointmentScheduled,
    JobRestorationChronicleType.cancelled,
    JobRestorationChronicleType.completed,
    JobRestorationChronicleType.received,
    JobRestorationChronicleType.withdrawn,
    JobRestorationChronicleType.workDetailsReceived,
]);
export const JobInsuredChronicleType = JobInsuredChronicleTypeSchema.Enum;
export const isJobInsuredChronicle = (chronicle) => JobInsuredChronicleTypeSchema.safeParse(chronicle.type).success;
/**
 * The JobInsuredTimeline chronicle types before compilation
 */
export const JobInsuredSourceTypeSchema = z.enum([
    ...JobInsuredChronicleTypeSchema.options,
    JobRestorationChronicleType.reopened,
]);
export const JobInsuredSourceType = JobInsuredSourceTypeSchema.Enum;
export const isJobInsuredSourceChronicle = (chronicle) => JobInsuredSourceTypeSchema.safeParse(chronicle.type).success;
