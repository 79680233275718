import { ContactCornSchema } from '@eigtech/contacts-types';
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { JobIdSchema, WorkAuthorizationIdSchema } from '../../base/index.js';
export const RecordWorkAuthorizationCompletedRequestBodySchema = z.object({
    workAuthorization: z.object({
        approvedBy: z.string(),
        effectiveDate: z.string().datetime(),
        signer: ContactCornSchema.or(z.string()),
        workAuthorizationId: WorkAuthorizationIdSchema,
    }),
});
export const RecordWorkAuthorizationCompletedRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({ requestActor: true });
export const RecordWorkAuthorizationCompletedRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const RecordWorkAuthorizationCompletedRequestSchema = RecordWorkAuthorizationCompletedRequestBodySchema.merge(RecordWorkAuthorizationCompletedRequestHeadersSchema).merge(RecordWorkAuthorizationCompletedRequestPathSchema);
export const RecordWorkAuthorizationCompletedResponseSchema = z.void();
export const RecordWorkAuthorizationCompletedApiSchema = {
    body: RecordWorkAuthorizationCompletedRequestBodySchema,
    headers: RecordWorkAuthorizationCompletedRequestHeadersSchema,
    params: RecordWorkAuthorizationCompletedRequestPathSchema,
};
