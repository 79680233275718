export var RestorationCommandNamesEnum;
(function (RestorationCommandNamesEnum) {
    RestorationCommandNamesEnum["addJobServices"] = "addJobServices";
    RestorationCommandNamesEnum["closeJob"] = "closeJob";
    RestorationCommandNamesEnum["receiveJob"] = "receiveJob";
    RestorationCommandNamesEnum["receiveJobInternal"] = "receiveJobInternal";
    RestorationCommandNamesEnum["receiveWorkDetails"] = "receiveWorkDetails";
    RestorationCommandNamesEnum["recordAppointmentCompleted"] = "recordAppointmentCompleted";
    RestorationCommandNamesEnum["recordAppointmentScheduled"] = "recordAppointmentScheduled";
    RestorationCommandNamesEnum["recordBillSentToCollections"] = "recordBillSentToCollections";
    RestorationCommandNamesEnum["recordCarrierInvoiced"] = "recordCarrierInvoiced";
    RestorationCommandNamesEnum["recordCarrierMadePayment"] = "recordCarrierMadePayment";
    RestorationCommandNamesEnum["recordCustomerContactAttempt"] = "recordCustomerContactAttempt";
    RestorationCommandNamesEnum["recordDepositOverrideApproved"] = "recordDepositOverrideApproved";
    RestorationCommandNamesEnum["recordDepositPaid"] = "recordDepositPaid";
    RestorationCommandNamesEnum["recordJobStarted"] = "recordJobStarted";
    RestorationCommandNamesEnum["recordSubcontractorDocumentIssued"] = "recordSubDocIssued";
    RestorationCommandNamesEnum["recordSubcontractorDocumentReceived"] = "recordSubDocReceived";
    RestorationCommandNamesEnum["recordSubcontractorPaid"] = "recordSubcontractorPaid";
    RestorationCommandNamesEnum["recordWorkAuthCompleted"] = "recordWorkAuthCompleted";
    RestorationCommandNamesEnum["recordWorkAuthOverrideApproved"] = "recordWauthOverrideApproved";
    RestorationCommandNamesEnum["recordWorkAuthSent"] = "recordWorkAuthSent";
    RestorationCommandNamesEnum["removeJobServices"] = "removeJobServices";
    RestorationCommandNamesEnum["reopenJob"] = "reopenJob";
    RestorationCommandNamesEnum["updateJobDescription"] = "updateJobDescription";
    RestorationCommandNamesEnum["updateJobLocation"] = "updateJobLocation";
})(RestorationCommandNamesEnum || (RestorationCommandNamesEnum = {}));
export var RestorationQueryNamesEnum;
(function (RestorationQueryNamesEnum) {
    RestorationQueryNamesEnum["getJobDetails"] = "getJobDetails";
    RestorationQueryNamesEnum["getJobDetailsInternal"] = "getJobDetailsInternal";
})(RestorationQueryNamesEnum || (RestorationQueryNamesEnum = {}));
export const RestorationFunctionNamesEnum = {
    ...RestorationCommandNamesEnum,
    ...RestorationQueryNamesEnum,
};
