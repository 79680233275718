import { ClaimEventType, ClaimEventTypeSchema as ClaimV2EventTypeSchema, DateOfLossSchema, DateReceivedSchema, } from '@eigtech/claims-v2-types';
import { VeriskStatusPublishConfirmedEventSchema } from '@eigtech/verisk-edi-interface-types';
import z from 'zod';
import { ClaimLossOfUseDetailsSchema, ClaimLossOfUseReceiptAddedDetailsSchema, ClaimLossOfUseReceiptRemovedDetailsSchema, ClaimReviewCompletedDetailsSchema, ClaimReviewRequestedDetailsSchema, } from './claimChronicleDetails.js';
import { ClaimV1EventTypeSchema } from './claimV1Events.js';
import { ChronicleLabelSchema, ChronicleSchema } from './timeline.js';
export const ClaimV1ChronicleTypeSchema = z.enum([
    ...ClaimV1EventTypeSchema.exclude([
        'fieldAdjusterInspectionCompleted',
        'fieldAdjusterInspectionScheduled',
    ]).options,
    'inspectionCompleted',
    'inspectionScheduled',
]);
export const ClaimV1ChronicleType = ClaimV1ChronicleTypeSchema.Enum;
export const ClaimV2ChronicleTypeSchema = ClaimV2EventTypeSchema;
export const ClaimV2ChronicleType = ClaimV2ChronicleTypeSchema.Enum;
export const EstimateChronicleTypeSchema = z.enum([
    'estimateReceived',
    'carrierRejected',
    'carrierReviewed',
    'carrierReviewedWithExceptions',
    'clientApproved',
    'clientRejected',
    'qaApproved',
    'qaRejected',
]);
export const EstimateChronicleType = EstimateChronicleTypeSchema.Enum;
export const isEstimateChronicleType = (type) => EstimateChronicleTypeSchema.safeParse(type).success;
export const ClaimAssignmentChronicleTypeSchema = z.enum([
    'carrierAssignedToClaim',
    'carrierUnassignedFromClaim',
    'coordinatorAssignedToClaim',
    'coordinatorUnassignedFromClaim',
    'fieldAdjusterAssignedToClaim',
    'fieldAdjusterUnassignedFromClaim',
    'jobAssignedToClaim',
    'jobUnassignedFromClaim',
    'legalRepAssignedToClaim',
    'legalRepUnassignedFromClaim',
    'policyHolderAssignedToClaim',
    'policyHolderUnassignedFromClaim',
    'primaryContactAssignedToClaim',
    'primaryContactUnassignedFromClaim',
    'projectManagerAssignedToClaim',
    'projectManagerUnassignedFromClaim',
    'reviewerAssignedToClaim',
    'reviewerUnassignedFromClaim',
    'inspectionTechnicianAssignedToClaim',
    'inspectionTechnicianUnassignedFromClaim',
]);
export const ClaimAssignmentChronicleType = ClaimAssignmentChronicleTypeSchema.Enum;
export const ClaimMeetingChronicleTypeSchema = z.enum(['claimMeetingEnded']);
export const ClaimMeetingChronicleType = ClaimMeetingChronicleTypeSchema.Enum;
export const ClaimCreatedChronicleSchema = ChronicleSchema.merge(z.object({
    details: z.object({
        lossDate: DateOfLossSchema,
    }),
    label: ChronicleLabelSchema.optional(),
    type: z.literal(ClaimEventType.claimCreated),
}));
export const isClaimCreatedChronicle = (chronicle) => ClaimCreatedChronicleSchema.safeParse(chronicle).success;
export const DateOfLossUpdatedChronicleSchema = ChronicleSchema.merge(z.object({
    details: z.object({
        lossDate: DateOfLossSchema,
    }),
    label: ChronicleLabelSchema.optional(),
    type: z.literal(ClaimEventType.dateOfLossUpdated),
}));
export const isDateOfLossUpdatedChronicle = (chronicle) => DateOfLossUpdatedChronicleSchema.safeParse(chronicle).success;
export const DateReceivedUpdatedChronicleSchema = ChronicleSchema.merge(z.object({
    details: z.object({
        dateReceived: DateReceivedSchema,
    }),
    label: ChronicleLabelSchema.optional(),
    type: z.literal(ClaimEventType.dateReceivedUpdated),
}));
export const isDateReceivedUpdatedChronicle = (chronicle) => DateReceivedUpdatedChronicleSchema.safeParse(chronicle).success;
export const VeriskStatusPublishedChronicleSchema = ChronicleSchema.merge(z.object({
    details: VeriskStatusPublishConfirmedEventSchema.shape.publication.pick({ vediImport: true }),
    label: ChronicleLabelSchema.optional(),
    type: z.literal('statusPublishConfirmed'),
}));
export const isVeriskStatusPublishedChronicle = (chronicle) => VeriskStatusPublishedChronicleSchema.safeParse(chronicle).success;
export const InspectionChronicleSchema = ChronicleSchema.merge(z.object({
    details: z.object({
        inspection: z
            .object({
            dateCompleted: z.string().datetime().optional(),
            scheduledDate: z.string().datetime().optional(),
        })
            .passthrough(),
    }),
    label: ChronicleLabelSchema.optional(),
    type: z.enum([
        ClaimEventType.inspectionCanceled,
        ClaimEventType.inspectionCompleted,
        ClaimEventType.inspectionScheduled,
    ]),
}));
export const isInspectionChronicle = (chronicle) => InspectionChronicleSchema.safeParse(chronicle).success;
export const LossOfUseChronicleSchema = ChronicleSchema.merge(z.object({
    details: ClaimLossOfUseDetailsSchema.passthrough(),
    label: ChronicleLabelSchema.optional(),
    type: z.enum([
        ClaimEventType.lossOfUseAdded,
        ClaimEventType.lossOfUseApproved,
        ClaimEventType.lossOfUseCanceled,
        ClaimEventType.lossOfUseDenied,
        ClaimEventType.lossOfUsePending,
    ]),
}));
export const isLossOfUseChronicle = (chronicle) => LossOfUseChronicleSchema.safeParse(chronicle).success;
export const LossOfUseReceiptAddedChronicleSchema = ChronicleSchema.merge(z.object({
    details: ClaimLossOfUseReceiptAddedDetailsSchema.passthrough(),
    label: ChronicleLabelSchema.optional(),
    type: z.enum([ClaimEventType.lossOfUseReceiptAdded]),
}));
export const isLossOfUseReceiptAddedChronicle = (chronicle) => LossOfUseReceiptAddedChronicleSchema.safeParse(chronicle).success;
export const LossOfUseReceiptRemovedChronicleSchema = ChronicleSchema.merge(z.object({
    details: ClaimLossOfUseReceiptRemovedDetailsSchema.passthrough(),
    label: ChronicleLabelSchema.optional(),
    type: z.enum([ClaimEventType.lossOfUseReceiptRemoved]),
}));
export const isLossOfUseReceiptRemovedChronicle = (chronicle) => LossOfUseReceiptRemovedChronicleSchema.safeParse(chronicle).success;
export const ClaimReviewRequestedChronicleSchema = ChronicleSchema.merge(z.object({
    details: ClaimReviewRequestedDetailsSchema.passthrough(),
    label: ChronicleLabelSchema.optional(),
    type: z.enum([ClaimEventType.reviewRequested]),
}));
export const isClaimReviewRequestedChronicle = (chronicle) => ClaimReviewRequestedChronicleSchema.safeParse(chronicle).success;
export const ClaimReviewCompletedChronicleSchema = ChronicleSchema.merge(z.object({
    details: ClaimReviewCompletedDetailsSchema.passthrough(),
    label: ChronicleLabelSchema.optional(),
    type: z.enum([ClaimEventType.reviewCompleted]),
}));
export const isClaimReviewCompletedChronicle = (chronicle) => ClaimReviewCompletedChronicleSchema.safeParse(chronicle).success;
