export var ClaimEstimatorTimelineLabel;
(function (ClaimEstimatorTimelineLabel) {
    ClaimEstimatorTimelineLabel["customerContactedEmail"] = "Customer Contacted Email";
    ClaimEstimatorTimelineLabel["customerContactedLvm"] = "Customer Contacted Lvm";
    ClaimEstimatorTimelineLabel["customerNoContact"] = "Customer No Contact";
    ClaimEstimatorTimelineLabel["fieldAdjusterContactedCustomer"] = "Estimator Contacted Customer";
    ClaimEstimatorTimelineLabel["fieldAdjusterCorrectionUploaded"] = "Estimator Correction Uploaded";
    ClaimEstimatorTimelineLabel["fieldAdjusterEstimateUploaded"] = "Estimator Estimate Uploaded";
    ClaimEstimatorTimelineLabel["inspectionCompleted"] = "Inspection Completed";
    ClaimEstimatorTimelineLabel["inspectionScheduled"] = "Inspection Scheduled";
    ClaimEstimatorTimelineLabel["invalidContactInformation"] = "Invalid Contact Information";
    ClaimEstimatorTimelineLabel["legalRepAssignedToClaim"] = "Legal Representative Assigned";
    ClaimEstimatorTimelineLabel["legalRepUnassignedFromClaim"] = "Legal Representative Unassigned";
    ClaimEstimatorTimelineLabel["policyHolderAssignedToClaim"] = "Policyholder Assigned";
    ClaimEstimatorTimelineLabel["policyHolderUnassignedFromClaim"] = "Policyholder Unassigned";
    ClaimEstimatorTimelineLabel["primaryContactAssignedToClaim"] = "Primary Contact Assigned";
    ClaimEstimatorTimelineLabel["primaryContactUnassignedFromClaim"] = "Primary Contact Unassigned";
    ClaimEstimatorTimelineLabel["surveySentToCustomer"] = "Survey Sent to Customer";
})(ClaimEstimatorTimelineLabel || (ClaimEstimatorTimelineLabel = {}));
export const claimEstimatorChronicleLabelMap = {
    customerContactedEmail: ClaimEstimatorTimelineLabel.customerContactedEmail,
    customerContactedLvm: ClaimEstimatorTimelineLabel.customerContactedEmail,
    customerNoContact: ClaimEstimatorTimelineLabel.customerContactedEmail,
    fieldAdjusterContactedCustomer: ClaimEstimatorTimelineLabel.customerContactedEmail,
    fieldAdjusterCorrectionUploaded: ClaimEstimatorTimelineLabel.customerContactedEmail,
    fieldAdjusterEstimateUploaded: ClaimEstimatorTimelineLabel.customerContactedEmail,
    inspectionCompleted: ClaimEstimatorTimelineLabel.customerContactedEmail,
    inspectionScheduled: ClaimEstimatorTimelineLabel.customerContactedEmail,
    invalidContactInformation: ClaimEstimatorTimelineLabel.customerContactedEmail,
    legalRepAssignedToClaim: ClaimEstimatorTimelineLabel.customerContactedEmail,
    legalRepUnassignedFromClaim: ClaimEstimatorTimelineLabel.customerContactedEmail,
    policyHolderAssignedToClaim: ClaimEstimatorTimelineLabel.customerContactedEmail,
    policyHolderUnassignedFromClaim: ClaimEstimatorTimelineLabel.customerContactedEmail,
    primaryContactAssignedToClaim: ClaimEstimatorTimelineLabel.customerContactedEmail,
    primaryContactUnassignedFromClaim: ClaimEstimatorTimelineLabel.customerContactedEmail,
    surveySentToCustomer: ClaimEstimatorTimelineLabel.customerContactedEmail,
};
