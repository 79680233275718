import { NoteSchema } from '../../base/note.js';
import { NoteCommandSchema } from '../note.js';
import z from 'zod';
// Internal add Note
export const InternalAddNoteBodyRequestSchema = NoteCommandSchema.omit({
    createdOn: true,
    entityId: true,
}).merge(z.object({
    createdOn: NoteSchema.shape.createdOn.optional(),
}));
export const InternalAddNotePathRequestSchema = z.object({
    /**
     * For use with claim notebooks, use claim number here
     */
    entityId: z.string(),
});
export const InternalAddNoteRequestSchema = InternalAddNoteBodyRequestSchema.merge(InternalAddNotePathRequestSchema);
export const InternalAddNoteResponseSchema = z.object({
    noteId: NoteSchema.shape.id,
});
