import { CohortEnumSchema } from '@eigtech/auth0-types';
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { CornSchema } from '@eigtech/shared-corn';
import z from 'zod';
import { AttendeeSchema, MeetingSchema } from '../base/chime.js';
import { MeetingDetailsSchema } from '../base/meetingDetails.js';
import { ParticipantSchema } from '../base/participant.js';
export const StartMeetingRequestBodySchema = z.object({
    participant: ParticipantSchema.omit({ attendeeId: true }).partial({ auth0Id: true }),
    description: z.string().optional(),
});
export const StartMeetingRequestPathSchema = z.object({
    cohort: CohortEnumSchema,
    entityId: CornSchema,
});
export const StartMeetingRequestHeaderSchema = ApiLambdalerAuthHeaderSchema;
export const StartMeetingRequestSchema = StartMeetingRequestPathSchema.merge(StartMeetingRequestHeaderSchema.merge(StartMeetingRequestBodySchema));
export const StartMeetingResponseSchema = z.object({
    meetingDetails: MeetingDetailsSchema,
    chimeMeeting: MeetingSchema,
    chimeAttendee: AttendeeSchema,
});
