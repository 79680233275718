import { NoteSchema } from '@eigtech/notes-types';
import z from 'zod';
export const XAPublicationTarget = 'XactAnalysis';
export const XM8PublicationTarget = 'Xactimate';
export const ImportNoteRequestBodySchema = z.object({
    claimNumber: z.string().optional(),
    transactionId: z.string().optional(),
    note: NoteSchema,
});
export const ImportNoteRequestSchema = ImportNoteRequestBodySchema;
export const ImportNoteApiSchema = {
    body: ImportNoteRequestBodySchema,
};
