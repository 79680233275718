export enum RestorationCommandNamesEnum {
  addJobServices = 'addJobServices',
  closeJob = 'closeJob',
  receiveJob = 'receiveJob',
  receiveJobInternal = 'receiveJobInternal',
  receiveWorkDetails = 'receiveWorkDetails',
  recordAppointmentCompleted = 'recordAppointmentCompleted',
  recordAppointmentScheduled = 'recordAppointmentScheduled',
  recordBillSentToCollections = 'recordBillSentToCollections',
  recordCarrierInvoiced = 'recordCarrierInvoiced',
  recordCarrierMadePayment = 'recordCarrierMadePayment',
  recordCustomerContactAttempt = 'recordCustomerContactAttempt',
  recordDepositOverrideApproved = 'recordDepositOverrideApproved',
  recordDepositPaid = 'recordDepositPaid',
  recordJobStarted = 'recordJobStarted',
  recordSubcontractorDocumentIssued = 'recordSubDocIssued',
  recordSubcontractorDocumentReceived = 'recordSubDocReceived',
  recordSubcontractorPaid = 'recordSubcontractorPaid',
  recordWorkAuthCompleted = 'recordWorkAuthCompleted',
  recordWorkAuthOverrideApproved = 'recordWauthOverrideApproved',
  recordWorkAuthSent = 'recordWorkAuthSent',
  removeJobServices = 'removeJobServices',
  reopenJob = 'reopenJob',
  updateJobDescription = 'updateJobDescription',
  updateJobLocation = 'updateJobLocation',
}

export enum RestorationQueryNamesEnum {
  getJobDetails = 'getJobDetails',
  getJobDetailsInternal = 'getJobDetailsInternal',
}

export const RestorationFunctionNamesEnum = {
  ...RestorationCommandNamesEnum,
  ...RestorationQueryNamesEnum,
}

export type RestorationFunctionNamesEnumKeys =
  | keyof typeof RestorationCommandNamesEnum
  | keyof typeof RestorationQueryNamesEnum

export type RestorationFunctionNamesEnumValues =
  (typeof RestorationFunctionNamesEnum)[RestorationFunctionNamesEnumKeys]
