import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { BaseLossDetailsSchema, ClaimNumberSchema, LocationSchema } from '../model/index.js';
export const UpdateLossDetailsSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: z.object({
        lossLocation: LocationSchema.optional(),
        lossDetails: BaseLossDetailsSchema,
    }),
    headers: ApiLambdalerAuthHeaderSchema.pick({ requestActor: true }).required({
        requestActor: true,
    }),
};
export const UpdateLossDetailsRequestSchema = UpdateLossDetailsSchemas.params
    .merge(UpdateLossDetailsSchemas.body)
    .merge(UpdateLossDetailsSchemas.headers);
export const UpdateLossDetailsResponseSchema = z.void();
export const UpdateLossDetailsApiSchema = UpdateLossDetailsSchemas;
