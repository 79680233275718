import { BookCornSchema, ContactDataSourceSchema, ContactSchema } from '@eigtech/contacts-types';
import { EventBaseSchema, eventType } from '@eigtech/event-stream-types';
import { z } from 'zod';
const serviceName = 'contacts';
const serviceEventType = (entity, eventName) => eventType(serviceName, entity, eventName);
const bookEventType = (eventName) => serviceEventType('book', eventName);
const claimBookEventType = (eventName) => serviceEventType('claimBook', eventName);
const claimContactEventType = (eventName) => serviceEventType('claimContact', eventName);
const ClaimContactSchema = z.object({
    contact: ContactSchema,
    claimNumber: z.string(),
});
const ServiceEventBaseSchema = EventBaseSchema.merge(z.object({
    type: serviceEventType(),
}));
const BookEventSchema = ServiceEventBaseSchema.merge(z.object({
    type: bookEventType(),
    book: z.unknown(),
    schemaVersion: z.literal(1),
}));
const ClaimContactEventSchema = ServiceEventBaseSchema.merge(z.object({
    type: claimContactEventType(),
    claimContact: ClaimContactSchema,
    metadata: ServiceEventBaseSchema.shape.metadata.and(z.object({
        requestedBy: z.string().optional(),
        dataSource: ContactDataSourceSchema.or(z.string()),
    })),
}));
export const DeprecatedClaimBookMetadataUpdatedEventSchema = BookEventSchema.merge(z.object({
    type: claimBookEventType('metadataUpdated'),
    book: z.object({
        bookId: BookCornSchema,
        primaryContact: ContactSchema.or(z.undefined()),
        carrier: ContactSchema.or(z.undefined()),
        fieldAdjuster: ContactSchema.or(z.undefined()),
        coordinator: ContactSchema.or(z.undefined()),
        policyHolder: ContactSchema.or(z.undefined()),
        hasLegalRep: z.boolean(),
    }),
}));
export const DeprecatedClaimBookLegalRepEventSchema = BookEventSchema.merge(z.object({
    type: claimBookEventType('legalRepSet'),
    book: z.object({
        bookId: BookCornSchema,
        hasLegalRep: z.boolean(),
    }),
}));
export const DeprecatedCarrierSetEventSchema = ClaimContactEventSchema.merge(z.object({
    type: claimContactEventType('carrierSet'),
    claimContact: ClaimContactSchema,
}));
export const DeprecatedPolicyHolderSetEventSchema = ClaimContactEventSchema.merge(z.object({
    type: claimContactEventType('policyHolderSet'),
    claimContact: ClaimContactSchema,
}));
export const DeprecatedPrimaryContactSetEventSchema = ClaimContactEventSchema.merge(z.object({
    type: claimContactEventType('primaryContactSet'),
    claimContact: ClaimContactSchema,
}));
export const DeprecatedPrimaryFieldAdjusterSetEventSchema = ClaimContactEventSchema.merge(z.object({
    type: claimContactEventType('primaryFieldAdjusterSet'),
    claimContact: ClaimContactSchema,
}));
export const DeprecatedPrimaryFieldAdjusterRemovedEventSchema = ClaimContactEventSchema.merge(z.object({
    type: claimContactEventType('primaryFieldAdjusterRemoved'),
    claimContact: ClaimContactSchema.omit({ contact: true }),
}));
export const DeprecatedPrimaryCoordinatorSetEventSchema = ClaimContactEventSchema.merge(z.object({
    type: claimContactEventType('primaryCoordinatorSet'),
    claimContact: ClaimContactSchema,
}));
export const DeprecatedPrimaryCoordinatorRemovedEventSchema = ClaimContactEventSchema.merge(z.object({
    type: claimContactEventType('primaryCoordinatorRemoved'),
    claimContact: ClaimContactSchema.omit({ contact: true }),
}));
export const DeprecatedContactEventSchema = DeprecatedClaimBookMetadataUpdatedEventSchema.or(DeprecatedCarrierSetEventSchema)
    .or(DeprecatedPolicyHolderSetEventSchema)
    .or(DeprecatedPrimaryContactSetEventSchema)
    .or(DeprecatedPrimaryFieldAdjusterSetEventSchema)
    .or(DeprecatedPrimaryFieldAdjusterRemovedEventSchema)
    .or(DeprecatedPrimaryCoordinatorSetEventSchema)
    .or(DeprecatedPrimaryCoordinatorRemovedEventSchema);
