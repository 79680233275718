import z from 'zod';
import { ClaimNumberSchema } from '../model/base.js';
import { ClaimReviewerSchema, PendingReviewSchema } from '../model/reviews.js';
import { BaseHeaderSchema } from './base.js';
const FlagClaimForReviewSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: z.object({
        notes: z.string(),
        reviewers: ClaimReviewerSchema.array(),
    }),
    headers: BaseHeaderSchema,
    response: z
        .object({
        claimNumber: ClaimNumberSchema,
    })
        .merge(PendingReviewSchema),
};
export const FlagClaimForReviewRequestSchema = FlagClaimForReviewSchemas.params
    .merge(FlagClaimForReviewSchemas.headers)
    .merge(FlagClaimForReviewSchemas.body);
export const FlagClaimForReviewApiSchema = FlagClaimForReviewSchemas;
