import z from 'zod';
import { ImportStatusesSchema } from './importStatus.js';
export const GetClaimAssignmentStatusRequestQueryStringSchema = z.object({
    claimNumber: z.string().optional(),
    transactionId: z.string().optional(),
});
export const GetClaimAssignmentStatusRequestSchema = GetClaimAssignmentStatusRequestQueryStringSchema;
export const GetClaimAssignmentStatusResponseSchema = z.record(z.string(), ImportStatusesSchema);
export const GetClaimAssignmentStatusApiSchema = {
    query: GetClaimAssignmentStatusRequestQueryStringSchema,
    response: GetClaimAssignmentStatusResponseSchema,
};
