import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { CornSchema } from '@eigtech/shared-corn';
import { z } from 'zod';
export const GetTranscriptUrlApiSchema = {
    headers: ApiLambdalerAuthHeaderSchema,
    params: z.object({
        entityId: CornSchema,
        meetingId: z.string(),
    }),
    response: z.object({
        url: z.string(),
    }),
};
export const GetTranscriptUrlRequestSchema = GetTranscriptUrlApiSchema.params.merge(GetTranscriptUrlApiSchema.headers);
