import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { BaseJobSchema } from '../../base/baseJob.js';
import { JobIdSchema } from '../../base/ids.js';
export const UpdateJobDescriptionRequestBodySchema = z.object({
    jobDescription: BaseJobSchema.shape.jobDescription,
});
export const UpdateJobDescriptionRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const UpdateJobDescriptionRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
});
export const UpdateJobDescriptionRequestSchema = UpdateJobDescriptionRequestBodySchema.merge(UpdateJobDescriptionRequestHeadersSchema).merge(UpdateJobDescriptionRequestPathSchema);
export const UpdateJobDescriptionResponseSchema = z.object({
    jobId: JobIdSchema,
    jobDescription: BaseJobSchema.shape.jobDescription,
});
export const UpdateJobDescriptionApiSchema = {
    body: UpdateJobDescriptionRequestBodySchema,
    headers: UpdateJobDescriptionRequestHeadersSchema,
    params: UpdateJobDescriptionRequestPathSchema,
    response: UpdateJobDescriptionResponseSchema,
};
