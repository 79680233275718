import { EventBase } from '@eigtech/event-stream-types'

import z from 'zod'
import {
  AppointmentChannelSchema,
  BaseJobSchema,
  CarrierInvoiceSchema,
  CarrierPaymentSchema,
  CustomerContactAttemptSchema,
  CustomerContactOutcomeSchema,
  DocumentTransitMethodSchema,
  JobAppointmentSchema,
  JobBillCollectionSchema,
  JobDepositOverrideApprovalSchema,
  JobDepositPaymentSchema,
  JobLocationSchema,
  JobSchema,
  JobServiceSchema,
  JobStatus,
  JobTypeSchema,
  SubcontractorDocumentKindSchema,
  SubcontractorIssuedDocumentSchema,
  SubcontractorPaymentSchema,
  SubcontractorReceivedDocumentSchema,
  SubcontractorSchema,
  WorkAuthorizationIdSchema,
  WorkAuthorizationRecipientSchema,
  WorkAuthorizationStatus,
} from '../base/index.js'
import { WorkDetailsSchema } from '../base/workDetails.js'
import { RestorationServiceEventBaseSchema, restorationServiceEventType } from './service.js'

export const jobEventType = <V extends string | undefined>(eventName?: V) =>
  restorationServiceEventType('job', eventName)

export const JobEventTypeSchema = z.enum([
  'appointmentCompleted',
  'appointmentScheduled',
  'billSentToCollections',
  'cancelled',
  'carrierInvoiced',
  'carrierMadePayment',
  'completed',
  'customerContactAttempted',
  'depositOverrideApproved',
  'depositPaid',
  'descriptionUpdated',
  'locationUpdated',
  'received',
  'reopened',
  'servicesAdded',
  'servicesRemoved',
  'started',
  'subcontractorPaid',
  'subdocumentIssued',
  'subdocumentReceived',
  'withdrawn',
  'workAuthorizationCompleted',
  'workAuthorizationDeclined',
  'workAuthorizationExpired',
  'workAuthorizationOverrideApproved',
  'workAuthorizationReassigned',
  'workAuthorizationRecalled',
  'workAuthorizationRecipientSigned',
  'workAuthorizationSent',
  'workDetailsReceived',
])
export const JobEventType = JobEventTypeSchema.Enum

export const JobEventSchema = RestorationServiceEventBaseSchema.merge(
  z.object({
    type: jobEventType(),
    job: JobSchema.and(z.unknown()),
    metadata: RestorationServiceEventBaseSchema.shape.metadata.and(
      z.object({
        jobType: JobTypeSchema,
        requestActor: z.string(),
      })
    ),
  })
)
export const isJobEvent = (event: EventBase) => JobEventSchema.safeParse(event).success
// #endregion
// #region JobReceivedEvent
export const JobReceivedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.received),
    job: JobSchema,
  })
)
export const isJobReceivedEvent = (event: EventBase) =>
  JobReceivedEventSchema.safeParse(event).success
// #endregion
// #region JobStartedEventDetails
export const JobStartedEventDetailsSchema = z.object({
  notes: z.string().optional(),
  occurredAt: z.string().datetime(),
})
export const JobStartedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.started),
    job: JobSchema.and(JobStartedEventDetailsSchema).and(
      z.object({
        status: z.literal(JobStatus.created),
      })
    ),
  })
)
export const isJobStartedEvent = (event: EventBase) =>
  JobStartedEventSchema.safeParse(event).success
// #endregion
// #region CustomerContactAttemptedEventDetails
export const CustomerContactAttemptedEventDetailsSchema = CustomerContactAttemptSchema
export const CustomerContactAttemptedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.customerContactAttempted),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        contactOutcome: CustomerContactOutcomeSchema,
      })
    ),
    job: JobSchema.and(CustomerContactAttemptedEventDetailsSchema),
  })
)
export const isCustomerContactAttemptedEvent = (event: EventBase) =>
  CustomerContactAttemptedEventSchema.safeParse(event).success
// #endregion
// #region JobAppointmentScheduledEventDetails
export const JobAppointmentScheduledEventDetailsSchema = JobAppointmentSchema.omit({
  appointmentNotes: true,
})
export const JobAppointmentScheduledEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.appointmentScheduled),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        appointmentChannel: AppointmentChannelSchema,
      })
    ),
    job: JobSchema.and(JobAppointmentScheduledEventDetailsSchema).and(
      z.object({
        status: z.literal(JobStatus.scheduled),
      })
    ),
  })
)
export const isJobAppointmentScheduledEvent = (
  event: EventBase
): event is JobAppointmentScheduledEvent =>
  JobAppointmentScheduledEventSchema.safeParse(event).success
// #endregion
// #region JobAppointmentCompletedEventDetails
export const JobAppointmentCompletedEventDetailsSchema =
  JobAppointmentScheduledEventDetailsSchema.and(
    z.object({
      appointmentId: z.string().optional(),
      appointmentNotes: z.string().optional(),
    })
  )
export const JobAppointmentCompletedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.appointmentCompleted),
    metadata: JobAppointmentScheduledEventSchema.shape.metadata,
    job: JobAppointmentScheduledEventSchema.shape.job.and(
      JobAppointmentCompletedEventDetailsSchema
    ),
  })
)
export const isJobAppointmentCompletedEvent = (event: EventBase) =>
  JobAppointmentCompletedEventSchema.safeParse(event).success
// #endregion
// #region JobSubcontractorDocumentIssuedEventDetails
export const JobSubcontractorDocumentIssuedEventDetailsSchema = z.object({
  subcontractorDocument: SubcontractorIssuedDocumentSchema,
  subcontractor: SubcontractorSchema,
})
export const JobSubcontractorDocumentIssuedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.subdocumentIssued),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        documentKind: SubcontractorDocumentKindSchema.or(z.string()),
        documentIssuedMethod: DocumentTransitMethodSchema.or(z.string()),
      })
    ),
    job: JobSchema.and(JobSubcontractorDocumentIssuedEventDetailsSchema),
  })
)
export const isJobSubcontractorDocumentIssuedEvent = (
  event: EventBase
): event is JobSubcontractorDocumentIssuedEvent =>
  JobSubcontractorDocumentIssuedEventSchema.safeParse(event).success
// #endregion
// #region JobSubcontractorDocumentReceivedEventDetails
export const JobSubcontractorDocumentReceivedEventDetailsSchema = z.object({
  subcontractorDocument: SubcontractorReceivedDocumentSchema,
  subcontractor: SubcontractorSchema,
})
export const JobSubcontractorDocumentReceivedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.subdocumentReceived),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        documentKind: SubcontractorDocumentKindSchema.or(z.string()),
        documentReceivedMethod: DocumentTransitMethodSchema.or(z.string()),
      })
    ),
    job: JobSchema.and(JobSubcontractorDocumentReceivedEventDetailsSchema),
  })
)
export const isJobSubcontractorDocumentReceivedEvent = (
  event: EventBase
): event is JobSubcontractorDocumentReceivedEvent =>
  JobSubcontractorDocumentReceivedEventSchema.safeParse(event).success
// #endregion
// #region JobWorkAuthorizationSentEventDetails
export const JobWorkAuthorizationSentEventDetailsSchema = z.object({
  workAuthorization: z.object({
    sentDate: z.string().datetime(),
    recipient: WorkAuthorizationRecipientSchema,
    sentMethod: DocumentTransitMethodSchema,
    status: z.literal(WorkAuthorizationStatus.sent),
    workAuthorizationId: WorkAuthorizationIdSchema,
  }),
})
export const JobWorkAuthorizationSentEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.workAuthorizationSent),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        documentTransitMethod: DocumentTransitMethodSchema.or(z.string()),
      })
    ),
    job: JobSchema.and(JobWorkAuthorizationSentEventDetailsSchema),
  })
)
export const isJobWorkAuthorizationSentEvent = (
  event: EventBase
): event is JobWorkAuthorizationSentEvent =>
  JobWorkAuthorizationSentEventSchema.safeParse(event).success
// #endregion
// #region JobWorkAuthorizationCompletedEventDetails
export const JobWorkAuthorizationCompletedEventDetailsSchema = z.object({
  workAuthorization: z.object({
    approvedBy: WorkAuthorizationRecipientSchema,
    dateClosed: z.string().datetime(),
    effectiveDate: z.string().datetime(),
    signer: WorkAuthorizationRecipientSchema,
    status: z.literal(WorkAuthorizationStatus.completed),
    workAuthorizationId: WorkAuthorizationIdSchema,
  }),
})
export const JobWorkAuthorizationCompletedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.workAuthorizationCompleted),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        documentTransitMethod: DocumentTransitMethodSchema.or(z.string()),
      })
    ),
    job: JobSchema.and(JobWorkAuthorizationCompletedEventDetailsSchema),
  })
)
export const isJobWorkAuthorizationCompletedEvent = (
  event: EventBase
): event is JobWorkAuthorizationCompletedEvent =>
  JobWorkAuthorizationCompletedEventSchema.safeParse(event).success
// #endregion
//#region RecipientSigned
export const JobWorkAuthorizationRecipientSignedEventDetailsSchema = z.object({
  workAuthorization: z.object({
    signedOn: z.string().datetime(),
    workAuthorizationId: WorkAuthorizationIdSchema,
    status: z.literal(WorkAuthorizationStatus.signed),
    signer: WorkAuthorizationRecipientSchema,
  }),
})
export const JobWorkAuthorizationRecipientSignedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.workAuthorizationRecipientSigned),
    job: JobSchema.and(JobWorkAuthorizationRecipientSignedEventDetailsSchema),
  })
)
export const isJobWorkAuthorizationRecipientSignedEvent = (
  event: EventBase
): event is JobWorkAuthorizationRecipientSignedEvent =>
  JobWorkAuthorizationRecipientSignedEventSchema.safeParse(event).success
// #endregion
// #region JobWorkAuthorizationRecalledEventDetails
export const JobWorkAuthorizationRecalledEventDetailsSchema = z.object({
  workAuthorization: z.object({
    dateClosed: z.string().datetime(),
    status: z.literal(WorkAuthorizationStatus.recalled),
    workAuthorizationId: WorkAuthorizationIdSchema,
  }),
})
export const JobWorkAuthorizationRecalledEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.workAuthorizationRecalled),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        documentTransitMethod: DocumentTransitMethodSchema.or(z.string()),
      })
    ),
    job: JobSchema.and(JobWorkAuthorizationRecalledEventDetailsSchema),
  })
)
export const isJobWorkAuthorizationRecalledEvent = (
  event: EventBase
): event is JobWorkAuthorizationRecalledEvent =>
  JobWorkAuthorizationRecalledEventSchema.safeParse(event).success
// #endregion
// #region JobWorkAuthorizationExpiredEventDetails
export const JobWorkAuthorizationExpiredEventDetailsSchema = z.object({
  workAuthorization: z.object({
    dateClosed: z.string().datetime(),
    status: z.literal(WorkAuthorizationStatus.expired),
    workAuthorizationId: WorkAuthorizationIdSchema,
  }),
})
export const JobWorkAuthorizationExpiredEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.workAuthorizationExpired),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        documentTransitMethod: DocumentTransitMethodSchema.or(z.string()),
      })
    ),
    job: JobSchema.and(JobWorkAuthorizationExpiredEventDetailsSchema),
  })
)
export const isJobWorkAuthorizationExpiredEvent = (
  event: EventBase
): event is JobWorkAuthorizationExpiredEvent =>
  JobWorkAuthorizationExpiredEventSchema.safeParse(event).success
// #endregion
// #region JobWorkAuthorizationDeclinedEventDetails
export const JobWorkAuthorizationDeclinedEventDetailsSchema = z.object({
  workAuthorization: z.object({
    dateClosed: z.string().datetime(),
    status: z.literal(WorkAuthorizationStatus.declined),
    workAuthorizationId: WorkAuthorizationIdSchema,
  }),
})
export const JobWorkAuthorizationDeclinedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.workAuthorizationDeclined),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        documentTransitMethod: DocumentTransitMethodSchema.or(z.string()),
      })
    ),
    job: JobSchema.and(JobWorkAuthorizationDeclinedEventDetailsSchema),
  })
)
export const isJobWorkAuthorizationDeclinedEvent = (
  event: EventBase
): event is JobWorkAuthorizationDeclinedEvent =>
  JobWorkAuthorizationDeclinedEventSchema.safeParse(event).success
// #endregion
// #region JobWorkAuthorizationReassignedEventDetails
export const JobWorkAuthorizationReassignedEventDetailsSchema = z.object({
  workAuthorization: z.object({
    dateReassigned: z.string().datetime(),
    recipient: WorkAuthorizationRecipientSchema,
    workAuthorizationId: WorkAuthorizationIdSchema,
  }),
})
export const JobWorkAuthorizationReassignedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.workAuthorizationReassigned),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        documentTransitMethod: DocumentTransitMethodSchema.or(z.string()),
      })
    ),
    job: JobSchema.and(JobWorkAuthorizationReassignedEventDetailsSchema),
  })
)
export const isJobWorkAuthorizationReassignedEvent = (
  event: EventBase
): event is JobWorkAuthorizationReassignedEvent =>
  JobWorkAuthorizationReassignedEventSchema.safeParse(event).success
// #endregion
// #region JobWorkAuthorizationOverrideApprovedEventDetails
export const JobWorkAuthorizationOverrideApprovedEventDetailsSchema = z.object({
  workAuthorization: z.object({
    effectiveDate: z.string().datetime(),
    approvedBy: WorkAuthorizationRecipientSchema,
    status: z.literal(WorkAuthorizationStatus.override_approved),
    workAuthorizationId: WorkAuthorizationIdSchema,
  }),
})
export const JobWorkAuthorizationOverrideApprovedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.workAuthorizationOverrideApproved),
    job: JobSchema.and(JobWorkAuthorizationOverrideApprovedEventDetailsSchema),
  })
)
export const isJobWorkAuthorizationOverrideApprovedEvent = (
  event: EventBase
): event is JobWorkAuthorizationOverrideApprovedEvent =>
  JobWorkAuthorizationOverrideApprovedEventSchema.safeParse(event).success
// #endregion
// #region JobCarrierInvoicedEventDetails
export const JobCarrierInvoicedEventDetailsSchema = z.object({
  carrierInvoice: CarrierInvoiceSchema,
})
export const JobCarrierInvoicedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.carrierInvoiced),
    job: JobSchema.and(JobCarrierInvoicedEventDetailsSchema).and(
      z.object({
        status: z.literal(JobStatus.paymentPending),
      })
    ),
  })
)
export const isJobCarrierInvoicedEvent = (event: EventBase): event is JobCarrierInvoicedEvent =>
  JobCarrierInvoicedEventSchema.safeParse(event).success
// #endregion
// #region JobCarrierMadePaymentEventDetails
export const JobCarrierMadePaymentEventDetailsSchema = z.object({
  carrierPayment: CarrierPaymentSchema,
})
export const JobCarrierMadePaymentEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.carrierMadePayment),
    job: JobSchema.and(JobCarrierMadePaymentEventDetailsSchema).and(
      z.object({
        status: z.literal(JobStatus.paymentReceived),
      })
    ),
  })
)
export const isJobCarrierMadePaymentEvent = (
  event: EventBase
): event is JobCarrierMadePaymentEvent => JobCarrierMadePaymentEventSchema.safeParse(event).success
// #endregion
// #region JobBillSentToCollectionsEventDetails
export const JobBillSentToCollectionsEventDetailsSchema = z.object({
  billCollection: JobBillCollectionSchema,
})
export const JobBillSentToCollectionsEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.billSentToCollections),
    job: JobSchema.and(JobBillSentToCollectionsEventDetailsSchema),
  })
)
// #endregion
// #region JobSubcontractorPaidEventDetails
export const JobSubcontractorPaidEventDetailsSchema = z.object({
  subcontractorPayment: SubcontractorPaymentSchema,
  subcontractor: SubcontractorSchema,
})
export const JobSubcontractorPaidEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.subcontractorPaid),
    job: JobSchema.and(JobSubcontractorPaidEventDetailsSchema),
  })
)
export const isJobSubcontractorPaidEvent = (event: EventBase): event is JobSubcontractorPaidEvent =>
  JobSubcontractorPaidEventSchema.safeParse(event).success
// #endregion
// #region JobLocationUpdatedEventDetails
export const JobLocationUpdatedEventDetailsSchema = z.object({
  previousLocation: JobLocationSchema,
})
export const JobLocationUpdatedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.locationUpdated),
    job: JobSchema.and(JobLocationUpdatedEventDetailsSchema),
  })
)
// #endregion
// #region JobDescriptionUpdatedEventDetails
export const JobDescriptionUpdatedEventDetailsSchema = z.object({
  previousDescription: BaseJobSchema.shape.jobDescription,
})
export const JobDescriptionUpdatedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.descriptionUpdated),
    job: JobSchema.and(JobDescriptionUpdatedEventDetailsSchema),
  })
)
// #endregion
// #region JobCompletedEventDetails
export const JobCompletedEventDetailsSchema = z.object({
  notes: z.string().optional(),
  occurredAt: z.string().datetime(),
})
export const JobCompletedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.completed),
    job: JobSchema.and(JobCompletedEventDetailsSchema).and(
      z.object({
        status: z.literal(JobStatus.closed),
      })
    ),
  })
)
// #endregion
// #region JobWithdrawnEventDetails
export const JobWithdrawnEventDetailsSchema = z.object({
  notes: z.string().optional(),
  occurredAt: z.string().datetime(),
})
export const JobWithdrawnEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.withdrawn),
    job: JobSchema.and(JobWithdrawnEventDetailsSchema).and(
      z.object({
        status: z.literal(JobStatus.closed),
      })
    ),
  })
)
// #endregion
// #region JobReopenedEventDetails
export const JobReopenedEventDetailsSchema = z.object({
  notes: z.string().optional(),
  occurredAt: z.string().datetime(),
})
export const JobReopenedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.reopened),
    job: JobSchema.and(JobReopenedEventDetailsSchema).and(
      z.object({
        status: z.literal(JobStatus.reopened),
      })
    ),
  })
)
// #endregion
// #region JobCancelledEventDetails
export const JobCancelledEventDetailsSchema = z.object({
  notes: z.string().optional(),
  occurredAt: z.string().datetime(),
})
export const JobCancelledEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.cancelled),
    job: JobSchema.and(JobCancelledEventDetailsSchema).and(
      z.object({
        status: z.literal(JobStatus.closed),
      })
    ),
  })
)
export const isJobCancelledEvent = (event: EventBase): event is JobCancelledEvent =>
  JobCancelledEventSchema.safeParse(event).success
// #endregion
// #region JobDepositPaidEventDetails
export const JobDepositPaidEventDetailsSchema = JobDepositPaymentSchema
export const JobDepositPaidEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.depositPaid),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        paidInFull: z.boolean(),
      })
    ),
    job: JobSchema.and(JobDepositPaidEventDetailsSchema),
  })
)
export const isJobDepositPaidEvent = (event: EventBase): event is JobDepositPaidEvent =>
  JobDepositPaidEventSchema.safeParse(event).success
// #endregion
// #region JobDepositOverrideApprovedEventDetails
export const JobDepositOverrideApprovedEventDetailsSchema = JobDepositOverrideApprovalSchema
export const JobDepositOverrideApprovedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.depositOverrideApproved),
    job: JobSchema.and(JobDepositOverrideApprovedEventDetailsSchema),
  })
)
// #endregion
// #region JobServicesAddedEventDetails
export const JobServicesAddedEventDetailsSchema = z.object({
  servicesAdded: JobServiceSchema.array(),
})
export const JobServicesAddedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.servicesAdded),
    job: JobSchema.and(JobServicesAddedEventDetailsSchema),
  })
)
export const isJobServicesAddedEvent = (event: EventBase) =>
  JobServicesAddedEventSchema.safeParse(event).success
// #endregion
// #region JobServicesRemovedEventDetails
export const JobServicesRemovedEventDetailsSchema = z.object({
  servicesRemoved: JobServiceSchema.array(),
})
export const JobServicesRemovedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.servicesRemoved),
    job: JobSchema.and(JobServicesRemovedEventDetailsSchema),
  })
)
export const isJobServicesRemovedEvent = (event: EventBase) =>
  JobServicesRemovedEventSchema.safeParse(event).success
// #endregion
// #region JobWorkDetailsReceived
export const JobWorkDetailsReceivedEventDetailsSchema = z.object({
  workDetails: WorkDetailsSchema,
  status: z.literal(JobStatus.readyForPayment),
})
export const JobWorkDetailsReceivedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.workDetailsReceived),
    job: JobSchema.and(JobWorkDetailsReceivedEventDetailsSchema),
  })
)
export const isJobWorkDetailsReceivedEvent = (event: EventBase) =>
  JobWorkDetailsReceivedEventSchema.safeParse(event).success
export type JobWorkDetailsReceivedEventDetails = z.infer<
  typeof JobWorkDetailsReceivedEventDetailsSchema
>
export type JobWorkDetailsReceivedEvent = z.infer<typeof JobWorkDetailsReceivedEventSchema>
// #endregion

export type JobEvent = z.infer<typeof JobEventSchema>
export type JobEventTypeEnum = z.infer<typeof JobEventTypeSchema>

export type JobStartedEventDetails = z.infer<typeof JobStartedEventDetailsSchema>
export type JobStartedEvent = z.infer<typeof JobStartedEventSchema>
export type CustomerContactAttemptedEventDetails = z.infer<
  typeof CustomerContactAttemptedEventDetailsSchema
>
export type CustomerContactAttemptedEvent = z.infer<typeof CustomerContactAttemptedEventSchema>
export type JobAppointmentCompletedEventDetails = z.infer<
  typeof JobAppointmentCompletedEventDetailsSchema
>
export type JobAppointmentCompletedEvent = z.infer<typeof JobAppointmentCompletedEventSchema>
export type JobAppointmentScheduledEventDetails = z.infer<
  typeof JobAppointmentScheduledEventDetailsSchema
>
export type JobAppointmentScheduledEvent = z.infer<typeof JobAppointmentScheduledEventSchema>
export type JobSubcontractorDocumentIssuedEventDetails = z.infer<
  typeof JobSubcontractorDocumentIssuedEventDetailsSchema
>
export type JobSubcontractorDocumentIssuedEvent = z.infer<
  typeof JobSubcontractorDocumentIssuedEventSchema
>
export type JobSubcontractorDocumentReceivedEventDetails = z.infer<
  typeof JobSubcontractorDocumentReceivedEventDetailsSchema
>
export type JobSubcontractorDocumentReceivedEvent = z.infer<
  typeof JobSubcontractorDocumentReceivedEventSchema
>
export type JobSubcontractorPaidEventDetails = z.infer<
  typeof JobSubcontractorPaidEventDetailsSchema
>
export type JobSubcontractorPaidEvent = z.infer<typeof JobSubcontractorPaidEventSchema>
export type JobWorkAuthorizationEvent =
  | JobWorkAuthorizationCompletedEvent
  | JobWorkAuthorizationDeclinedEvent
  | JobWorkAuthorizationExpiredEvent
  | JobWorkAuthorizationOverrideApprovedEvent
  | JobWorkAuthorizationReassignedEvent
  | JobWorkAuthorizationRecalledEvent
  | JobWorkAuthorizationSentEvent
export type JobWorkAuthorizationSentEventDetails = z.infer<
  typeof JobWorkAuthorizationSentEventDetailsSchema
>
export type JobWorkAuthorizationSentEvent = z.infer<typeof JobWorkAuthorizationSentEventSchema>
export type JobWorkAuthorizationCompletedEventDetails = z.infer<
  typeof JobWorkAuthorizationCompletedEventDetailsSchema
>
export type JobWorkAuthorizationCompletedEvent = z.infer<
  typeof JobWorkAuthorizationCompletedEventSchema
>
export type JobWorkAuthorizationRecipientSignedEventDetails = z.infer<
  typeof JobWorkAuthorizationRecipientSignedEventDetailsSchema
>
export type JobWorkAuthorizationRecipientSignedEvent = z.infer<
  typeof JobWorkAuthorizationRecipientSignedEventSchema
>
export type JobWorkAuthorizationDeclinedEventDetails = z.infer<
  typeof JobWorkAuthorizationDeclinedEventDetailsSchema
>
export type JobWorkAuthorizationDeclinedEvent = z.infer<
  typeof JobWorkAuthorizationDeclinedEventSchema
>
export type JobWorkAuthorizationExpiredEventDetails = z.infer<
  typeof JobWorkAuthorizationExpiredEventDetailsSchema
>
export type JobWorkAuthorizationExpiredEvent = z.infer<
  typeof JobWorkAuthorizationExpiredEventSchema
>
export type JobWorkAuthorizationReassignedEventDetails = z.infer<
  typeof JobWorkAuthorizationReassignedEventDetailsSchema
>
export type JobWorkAuthorizationReassignedEvent = z.infer<
  typeof JobWorkAuthorizationReassignedEventSchema
>
export type JobWorkAuthorizationRecalledEventDetails = z.infer<
  typeof JobWorkAuthorizationRecalledEventDetailsSchema
>
export type JobWorkAuthorizationRecalledEvent = z.infer<
  typeof JobWorkAuthorizationRecalledEventSchema
>
export type JobWorkAuthorizationOverrideApprovedEventDetails = z.infer<
  typeof JobWorkAuthorizationOverrideApprovedEventDetailsSchema
>
export type JobWorkAuthorizationOverrideApprovedEvent = z.infer<
  typeof JobWorkAuthorizationOverrideApprovedEventSchema
>
export type JobCarrierMadePaymentEventDetails = z.infer<
  typeof JobCarrierMadePaymentEventDetailsSchema
>
export type JobCarrierMadePaymentEvent = z.infer<typeof JobCarrierMadePaymentEventSchema>
export type JobCarrierInvoicedEventDetails = z.infer<typeof JobCarrierInvoicedEventDetailsSchema>
export type JobCarrierInvoicedEvent = z.infer<typeof JobCarrierInvoicedEventSchema>
export type JobBillSentToCollectionsEventDetails = z.infer<
  typeof JobBillSentToCollectionsEventDetailsSchema
>
export type JobBillSentToCollectionsEvent = z.infer<typeof JobBillSentToCollectionsEventSchema>
export type JobReceivedEvent = z.infer<typeof JobReceivedEventSchema>
export type JobLocationUpdatedEventDetails = z.infer<typeof JobLocationUpdatedEventDetailsSchema>
export type JobLocationUpdatedEvent = z.infer<typeof JobLocationUpdatedEventSchema>
export type JobDescriptionUpdatedEventDetails = z.infer<
  typeof JobDescriptionUpdatedEventDetailsSchema
>
export type JobDescriptionUpdatedEvent = z.infer<typeof JobDescriptionUpdatedEventSchema>
export type JobCompletedEventDetails = z.infer<typeof JobCompletedEventDetailsSchema>
export type JobCompletedEvent = z.infer<typeof JobCompletedEventSchema>
export type JobWithdrawnEventDetails = z.infer<typeof JobWithdrawnEventDetailsSchema>
export type JobWithdrawnEvent = z.infer<typeof JobWithdrawnEventSchema>
export type JobCancelledEventDetails = z.infer<typeof JobCancelledEventDetailsSchema>
export type JobCancelledEvent = z.infer<typeof JobCancelledEventSchema>
export type JobReopenedEventDetails = z.infer<typeof JobReopenedEventDetailsSchema>
export type JobReopenedEvent = z.infer<typeof JobReopenedEventSchema>
export type JobDepositPaidEventDetails = z.infer<typeof JobDepositPaidEventDetailsSchema>
export type JobDepositPaidEvent = z.infer<typeof JobDepositPaidEventSchema>
export type JobDepositOverrideApprovedEventDetails = z.infer<
  typeof JobDepositOverrideApprovedEventDetailsSchema
>
export type JobDepositOverrideApprovedEvent = z.infer<typeof JobDepositOverrideApprovedEventSchema>

export type JobServicesAddedEventDetails = z.infer<typeof JobServicesAddedEventDetailsSchema>
export type JobServicesAddedEvent = z.infer<typeof JobServicesAddedEventSchema>
export type JobServicesRemovedEventDetails = z.infer<typeof JobServicesRemovedEventDetailsSchema>
export type JobServicesRemovedEvent = z.infer<typeof JobServicesRemovedEventSchema>

export type JobEventDiscriminator =
  | CustomerContactAttemptedEvent
  | JobAppointmentCompletedEvent
  | JobAppointmentScheduledEvent
  | JobCancelledEvent
  | JobCarrierInvoicedEvent
  | JobCarrierMadePaymentEvent
  | JobCompletedEvent
  | JobDepositOverrideApprovedEvent
  | JobDepositPaidEvent
  | JobDescriptionUpdatedEvent
  | JobLocationUpdatedEvent
  | JobReceivedEvent
  | JobReopenedEvent
  | JobServicesAddedEvent
  | JobServicesRemovedEvent
  | JobStartedEvent
  | JobSubcontractorDocumentIssuedEvent
  | JobSubcontractorDocumentReceivedEvent
  | JobWithdrawnEvent
  | JobWorkAuthorizationCompletedEvent
  | JobWorkAuthorizationDeclinedEvent
  | JobWorkAuthorizationExpiredEvent
  | JobWorkAuthorizationOverrideApprovedEvent
  | JobWorkAuthorizationReassignedEvent
  | JobWorkAuthorizationRecalledEvent
  | JobWorkAuthorizationRecipientSignedEvent
  | JobWorkAuthorizationSentEvent
  | JobWorkDetailsReceivedEvent
