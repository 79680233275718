import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { RegionRatesSchema, RegionSchema } from '../../base/index.js';
export const UpdateRegionApiSchema = {
    params: z.object({
        regionId: RegionSchema.shape.id,
    }),
    headers: ApiLambdalerAuthHeaderWithRequestActorSchema,
    body: z.object({
        name: z.string().min(1),
        regionRates: RegionRatesSchema,
    }),
    response: z.object({
        region: RegionSchema,
    }),
};
