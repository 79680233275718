import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { BookCornSchema } from '../../../base/books.js';
import { ContactCornSchema } from '../../../base/contacts.js';
export const RemoveContactFromBookRequestPathParametersSchema = z.object({
    bookId: BookCornSchema,
});
export const RemoveContactFromBookRequestBodySchema = z.object({
    contactId: ContactCornSchema,
});
export const RemoveContactFromBookRequestHeadersSchema = ApiLambdalerAuthHeaderWithRequestActorSchema.pick({ requestActor: true });
export const RemoveContactFromBookRequestSchema = RemoveContactFromBookRequestPathParametersSchema.merge(RemoveContactFromBookRequestBodySchema).merge(RemoveContactFromBookRequestHeadersSchema);
export const RemoveContactFromBookApiSchema = {
    body: RemoveContactFromBookRequestBodySchema,
    headers: RemoveContactFromBookRequestHeadersSchema,
    params: RemoveContactFromBookRequestPathParametersSchema,
};
