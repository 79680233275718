import z from 'zod';
import { ClaimNumberSchema, InitialClaimActionsSchema } from '../model/index.js';
import { BaseHeaderSchema } from './base.js';
const AddInitialClaimActionsSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: z.object({
        initialClaimActions: InitialClaimActionsSchema.array(),
    }),
    headers: BaseHeaderSchema,
    response: z.object({
        claimNumber: ClaimNumberSchema,
        initialClaimActions: InitialClaimActionsSchema.array(),
    }),
};
export const AddInitialClaimActionsRequestSchema = AddInitialClaimActionsSchemas.params
    .merge(AddInitialClaimActionsSchemas.body)
    .merge(AddInitialClaimActionsSchemas.headers);
export const AddInitialClaimActionsApiSchema = AddInitialClaimActionsSchemas;
