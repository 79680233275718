import z from 'zod';
import { ClaimNumberSchema } from '../model/base.js';
import { EstimateReviewSchema } from '../model/estimate.js';
import { BaseHeaderSchema } from './base.js';
const ReviewEstimateSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: EstimateReviewSchema.omit({ claimNumber: true }).partial({
        estimateId: true,
    }),
    headers: BaseHeaderSchema,
    response: EstimateReviewSchema,
};
export const ReviewEstimateRequestSchema = ReviewEstimateSchemas.params
    .merge(ReviewEstimateSchemas.body)
    .merge(ReviewEstimateSchemas.headers);
export const ReviewEstimateApiSchema = ReviewEstimateSchemas;
