import z from 'zod';
import { ClaimNumberSchema } from '../model/base.js';
const IsClaimPublishableSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    response: z.object({ publishable: z.boolean(), message: z.string().optional() }),
};
export const IsClaimPublishableRequestSchema = IsClaimPublishableSchemas.params;
export const IsClaimPublishableApiSchema = IsClaimPublishableSchemas;
