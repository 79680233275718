import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { ContactCornSchema } from '../../../base/contacts.js';
export const DeactivateContactRequestPathSchema = z.object({ contactId: ContactCornSchema });
export const DeactivateContactRequestHeadersSchema = ApiLambdalerAuthHeaderWithRequestActorSchema.pick({ requestActor: true });
export const DeactivateContactRequestSchema = DeactivateContactRequestPathSchema.merge(DeactivateContactRequestHeadersSchema);
export const DeactivateContactApiSchema = {
    params: DeactivateContactRequestPathSchema,
    headers: DeactivateContactRequestHeadersSchema,
};
