import { z } from 'zod';
import { BookCornSchema } from '../base/books.js';
import { ContactCornSchema, ContactSchema, ExternalSystemId } from '../base/contacts.js';
import { ServiceEventBaseSchema, serviceEventType } from './service.js';
//Schemas
export const contactEventType = (eventName) => serviceEventType('contact', eventName);
export const claimContactEventType = (eventName) => serviceEventType('claimContact', eventName);
export const ContactEventSchema = ServiceEventBaseSchema.merge(z.object({
    type: contactEventType(),
    contact: z.unknown(),
    metadata: ServiceEventBaseSchema.shape.metadata.and(z.object({
        requestActor: z.string().optional(),
    })),
}));
export const ContactCreatedEventSchema = ContactEventSchema.merge(z.object({
    type: contactEventType('created'),
    contact: ContactSchema,
}));
export const ContactDeletedEventSchema = ContactEventSchema.merge(z.object({
    type: contactEventType('deleted'),
    contact: ContactSchema,
}));
export const ContactAddedToBookEventSchema = ContactEventSchema.merge(z.object({
    type: contactEventType('addedToBook'),
    contact: z.object({
        contactId: ContactCornSchema,
        bookId: BookCornSchema,
    }),
}));
export const ContactRemovedFromBookEventSchema = ContactEventSchema.merge(z.object({
    type: contactEventType('removedFromBook'),
    contact: z.object({
        contactId: ContactCornSchema,
        bookId: BookCornSchema,
    }),
}));
export const ContactUpdatedEventSchema = ContactEventSchema.merge(z.object({
    type: contactEventType('updated'),
    contact: ContactSchema,
}));
export const ContactActivatedEventSchema = ContactEventSchema.merge(z.object({
    type: contactEventType('activated'),
    contact: ContactSchema,
}));
export const ContactDeactivatedEventSchema = ContactEventSchema.merge(z.object({
    type: contactEventType('deactivated'),
    contact: ContactSchema,
}));
export const ContactExternalSystemIdAddedEventSchema = ContactEventSchema.merge(z.object({
    type: contactEventType('externalSystemIdAdded'),
    contact: z.object({
        info: ContactSchema,
        externalSystem: z.object({
            id: z.string(),
            service: ExternalSystemId,
        }),
    }),
}));
export const ContactExternalSystemIdRemovedEventSchema = ContactEventSchema.merge(z.object({
    type: contactEventType('externalSystemIdRemoved'),
    contact: z.object({
        info: ContactSchema,
        externalSystem: z.object({
            id: z.string(),
            service: ExternalSystemId,
        }),
    }),
}));
export const ContactMergedEventSchema = ContactEventSchema.merge(z.object({
    type: contactEventType('merged'),
    contact: z.object({
        baseId: ContactSchema.shape.contactId,
        otherId: ContactSchema.shape.contactId,
        merged: ContactSchema,
    }),
}));
export const ContactRestoredEventSchema = ContactEventSchema.merge(z.object({
    type: contactEventType('restored'),
    contact: ContactSchema,
}));
export const ContactDescriptionUpdatedEventSchema = ContactEventSchema.merge(z.object({
    type: contactEventType('descriptionUpdated'),
    contact: z.object({
        contactId: ContactCornSchema,
        description: z.string(),
    }),
}));
export const ContactDescriptionDeletedEventSchema = ContactEventSchema.merge(z.object({
    type: contactEventType('descriptionDeleted'),
    contact: z.object({
        contactId: ContactCornSchema,
    }),
}));
//typeguards
export const isContactCreatedEvent = (event) => ContactCreatedEventSchema.safeParse(event).success;
export const isContactAddedToBookEvent = (event) => ContactAddedToBookEventSchema.safeParse(event).success;
export const isContactRemovedFromBookEvent = (event) => ContactRemovedFromBookEventSchema.safeParse(event).success;
export const isContactUpdatedEvent = (event) => ContactUpdatedEventSchema.safeParse(event).success;
