import z from 'zod';
import { AssigneeRelationshipCanonSchema } from '../entities/assignment.js';
export const UnassignApiSchema = {
    params: z.object({
        assignableId: z.string(),
        assigneeId: z.string(),
        assigneeRelationship: AssigneeRelationshipCanonSchema,
    }),
    headers: z.object({
        requestActor: z.string().optional(),
    }),
};
export const UnassignPathRequestSchema = UnassignApiSchema.params;
export const UnassignHeadersRequestSchema = UnassignApiSchema.headers;
export const UnassignRequestSchema = UnassignPathRequestSchema.merge(UnassignHeadersRequestSchema);
