import z from 'zod';
import { DailyJobSchema, JOB_TYPE_DAILY, JOB_TYPE_DAILY_ABBREVIATION } from './dailyJob.js';
import { EmergencyServicesJobSchema, JOB_TYPE_EMERGENCY_SERVICES, JOB_TYPE_EMERGENCY_SERVICES_ABBREVIATION, } from './emergencyServicesJob.js';
import { JOB_TYPE_OTR, JOB_TYPE_OTR_ABBREVIATION, OtrJobSchema } from './otrJob.js';
export const JobTypeSchema = z.enum([JOB_TYPE_DAILY, JOB_TYPE_EMERGENCY_SERVICES, JOB_TYPE_OTR]);
export const JobTypeAbbreviationSchema = z.enum([
    JOB_TYPE_DAILY_ABBREVIATION,
    JOB_TYPE_EMERGENCY_SERVICES_ABBREVIATION,
    JOB_TYPE_OTR_ABBREVIATION,
]);
export const JobSchema = z.discriminatedUnion('jobType', [
    DailyJobSchema,
    EmergencyServicesJobSchema,
    OtrJobSchema,
]);
