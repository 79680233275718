import { z } from 'zod';
import { Auth0UserSchema, RoleSchema } from '../../logStreamEvents/base/base.js';
export const AddUserRequestPathSchema = z.object({
    userId: z.string(),
});
export const AddUserRequestSchema = AddUserRequestPathSchema;
export const AddUserResponseSchema = z.object({
    user: Auth0UserSchema,
    roles: RoleSchema.array(),
});
export const AddUserApiSchema = {
    params: AddUserRequestPathSchema,
    response: AddUserResponseSchema,
};
