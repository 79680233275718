import { CohortEnumSchema } from '@eigtech/auth0-types';
import { CornSchema } from '@eigtech/shared-corn';
import { z } from 'zod';
export const ExportImagesApiSchema = {
    params: z.object({
        entityId: CornSchema,
        cohort: CohortEnumSchema,
    }),
    response: z.string(),
};
export const ExportImagesRequestSchema = ExportImagesApiSchema.params;
