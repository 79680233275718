import { z } from 'zod';
import { eventType, EventBaseSchema } from '@eigtech/event-stream-types';
/** @deprecated */
export const serviceName = 'claims';
/** @deprecated */
export const claimsServiceEventType = (entity, eventName) => eventType(serviceName, entity, eventName);
/** @deprecated */
export const ClaimsServiceEventBaseSchema = EventBaseSchema.merge(z.object({
    type: claimsServiceEventType(),
}));
