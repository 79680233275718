import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { ContactCornSchema, ContactSchema } from '../../../base/contacts.js';
export const UpdateContactRequestPathSchema = z.object({ contactId: ContactCornSchema });
export const UpdateContactRequestHeadersSchema = ApiLambdalerAuthHeaderWithRequestActorSchema.pick({
    requestActor: true,
});
export const UpdateContactRequestBodySchema = ContactSchema.omit({ contactId: true });
export const UpdateContactRequestSchema = UpdateContactRequestBodySchema.merge(UpdateContactRequestPathSchema).merge(UpdateContactRequestHeadersSchema);
export const UpdateContactApiSchema = {
    body: UpdateContactRequestBodySchema,
    headers: UpdateContactRequestHeadersSchema,
    params: UpdateContactRequestPathSchema,
};
