import { z } from 'zod';
export const GetUserNameFromIdRequestPathSchema = z.object({
    userId: z.string(),
});
export const GetUserNameFromIdRequestSchema = GetUserNameFromIdRequestPathSchema;
export const GetUserNameFromIdResponseSchema = z.object({
    name: z.string(),
});
export const GetUserNameFromIdApiSchema = {
    params: GetUserNameFromIdRequestPathSchema,
    response: GetUserNameFromIdResponseSchema,
};
