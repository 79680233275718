import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { TimelineEntityTypeSchema, TimelineSchema, TimelineTypeSchema, } from '../../timeline/index.js';
export const GetTimelineApiSchema = {
    params: z.object({
        entityId: z.string(),
        entityType: TimelineEntityTypeSchema,
        timelineType: TimelineTypeSchema,
    }),
    headers: ApiLambdalerAuthHeaderSchema.pick({ requestToken: true }).required({
        requestToken: true,
    }),
    response: z.object({
        timeline: TimelineSchema,
    }),
    query: z.object({ filter: z.string().optional() }),
};
export const GetTimelineInternalApiSchema = {
    params: GetTimelineApiSchema.params,
    response: GetTimelineApiSchema.response,
    query: GetTimelineApiSchema.query,
};
export const GetTimelineRequestSchema = GetTimelineApiSchema.params
    .merge(GetTimelineApiSchema.headers)
    .merge(GetTimelineApiSchema.query);
export const GetTimelineInternalRequestSchema = GetTimelineApiSchema.params;
export const GetTimelineResponseSchema = GetTimelineApiSchema.response;
