import { z } from 'zod';
import { BookSchema, GlobalBookCornLaxSchema } from '../../../base/books.js';
import { ContactSchema } from '../../../base/contacts.js';
export const GetGlobalBookRequestPathParametersSchema = z.object({
    bookId: GlobalBookCornLaxSchema,
});
export const GetGlobalBookRequestSchema = GetGlobalBookRequestPathParametersSchema;
export const GetGlobalBookResponseSchema = BookSchema.extend({
    contacts: ContactSchema.array(),
});
export const GetGlobalBookApiSchema = {
    params: GetGlobalBookRequestPathParametersSchema,
    response: GetGlobalBookResponseSchema,
};
