import { cornType } from '@eigtech/shared-corn';
import { makeTypeIdSchema, mimicTypeIdSchema } from '@eigtech/typeid';
export const JobCornSchema = cornType('restoration', 'job');
export const isJobCorn = (corn) => JobCornSchema.safeParse(corn).success;
export const JobIdSchema = mimicTypeIdSchema('job');
export const isJobId = (id) => JobIdSchema.safeParse(id).success;
export const JobAppointmentIdSchema = makeTypeIdSchema('jobappointment');
export const isJobAppointmentId = (id) => JobAppointmentIdSchema.safeParse(id).success;
export const SubcontractorDocumentIdSchema = makeTypeIdSchema('subcontractordocument');
export const isSubcontractorDocumentId = (id) => SubcontractorDocumentIdSchema.safeParse(id).success;
export const workAuthorizationIdPrefix = 'workauthorization';
export const WorkAuthorizationIdSchema = mimicTypeIdSchema(workAuthorizationIdPrefix);
export const isWorkAuthorizationId = (id) => WorkAuthorizationIdSchema.safeParse(id).success;
