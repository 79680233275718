import { z } from 'zod';
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { CornSchema } from '@eigtech/shared-corn';
export const GetMeetingUploadApiSchema = {
    params: z.object({
        entityId: CornSchema,
    }),
    headers: ApiLambdalerAuthHeaderSchema,
    response: z.object({
        fields: z.record(z.string()),
        url: z.string(),
    }),
};
export const GetMeetingUploadUrlRequestSchema = GetMeetingUploadApiSchema.params.merge(GetMeetingUploadApiSchema.headers);
