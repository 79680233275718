import z from 'zod';
import { AssignableLaxSchema, AssigneeSchema } from '../entities/assignment.js';
export const AssignRequestApiSchema = {
    body: z.object({
        assignee: AssigneeSchema,
        assignable: AssignableLaxSchema,
    }),
    headers: z.object({ requestActor: z.string().optional() }),
    params: z.object({ assignableId: z.string() }),
};
export const AssignRequestBodySchema = AssignRequestApiSchema.body;
export const AssignPathRequestSchema = AssignRequestApiSchema.params;
export const AssignHeadersRequestSchema = AssignRequestApiSchema.headers;
export const AssignRequestSchema = AssignRequestBodySchema.merge(AssignPathRequestSchema).merge(AssignHeadersRequestSchema);
