import { BaseEventMetadataSchema, ParsedExportBaseSchema } from './parsedBase.js';
import { z } from 'zod';
export const CustomDocumentMetadataSchema = BaseEventMetadataSchema.merge(z.object({
    type: z.string(),
    notes: z.string(),
    filename: z.string(),
    claimNumber: z.string(),
    parentFilename: z.string().optional(),
}));
export const ParsedCustomDocumentExportSchema = ParsedExportBaseSchema.merge(CustomDocumentMetadataSchema).merge(z.object({
    s3Bucket: z.string(),
    s3Key: z.string(),
}));
