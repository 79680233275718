import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { BookCornSchema } from '../../../base/books.js';
import { ContactCornSchema } from '../../../base/contacts.js';
export const AddContactToBookRequestPathParametersSchema = z.object({
    bookId: BookCornSchema,
});
export const AddContactToBookRequestBodySchema = z.object({
    contactId: ContactCornSchema,
});
export const AddContactToBookRequestHeadersSchema = ApiLambdalerAuthHeaderWithRequestActorSchema.pick({ requestActor: true });
export const AddContactToBookRequestSchema = AddContactToBookRequestPathParametersSchema.merge(AddContactToBookRequestBodySchema).merge(AddContactToBookRequestHeadersSchema);
export const AddContactToBookApiSchema = {
    body: AddContactToBookRequestBodySchema,
    params: AddContactToBookRequestPathParametersSchema,
    headers: AddContactToBookRequestHeadersSchema,
};
