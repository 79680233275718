import { Handler } from 'aws-lambda'
import z from 'zod'
import { SuccessApiOpDetailsSchema } from './base/auth0Events/SuccessApiOperationDetails.js'

// path /api/v2/connections/con_nqxGMyPER4CXOiGC
export const ConnectionDeletedBodySchema = SuccessApiOpDetailsSchema.extend({
  request: SuccessApiOpDetailsSchema.shape.request.extend({
    method: z.literal('delete'),
    path: z.string(),
    query: z.object({}),
    body: z.object({}),
  }),
  response: SuccessApiOpDetailsSchema.shape.response.extend({
    body: z.object({}),
  }),
})
export const ConnectionDeletedPathSchema = z.object({
  connectionId: z.string(),
})
export const ConnectionDeletedRequestSchema = ConnectionDeletedBodySchema.merge(
  ConnectionDeletedPathSchema
)

export type ConnectionDeletedRequest = z.infer<typeof ConnectionDeletedRequestSchema>
export type ConnectionDeletedResponse = void

export type ConnectionDeletedHandler = Handler<ConnectionDeletedRequest, ConnectionDeletedResponse>
