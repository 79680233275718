import { CohortEnumSchema } from '@eigtech/auth0-types';
import { CornSchema } from '@eigtech/shared-corn';
import { z } from 'zod';
export const AddDocumentCohortApiSchema = {
    params: z.object({
        entityId: CornSchema,
        cohort: CohortEnumSchema,
        documentCorn: CornSchema,
    }),
};
export const AddDocumentCohortRequestSchema = AddDocumentCohortApiSchema.params;
