import z from 'zod';
import { ProducerUserTypesEnumSchema } from '../../base/index.js';
export const AddUserByUserTypeRequestBodySchema = z.object({
    userId: z.string(),
    userType: ProducerUserTypesEnumSchema,
});
export const AddUserByUserTypeRequestSchema = AddUserByUserTypeRequestBodySchema;
export const AddUserByUserTypeResponseSchema = z.void();
export const AddUserByUserTypeApiSchema = {
    body: AddUserByUserTypeRequestBodySchema,
};
