import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { CornSchema } from '@eigtech/shared-corn';
import z from 'zod';
import { ScreenshotSchema } from '../base/screenshot.js';
export const ListMeetingScreenshotsRequestPathSchema = z.object({
    entityId: CornSchema,
    startTime: z.string().datetime(),
});
export const ListMeetingScreenshotsRequestHeaderSchema = ApiLambdalerAuthHeaderSchema;
export const ListMeetingScreenshotsRequestSchema = ListMeetingScreenshotsRequestPathSchema.merge(ListMeetingScreenshotsRequestHeaderSchema);
export const ListMeetingScreenshotsResponseSchema = z.array(ScreenshotSchema);
