import z from 'zod';
import { ClaimNumberSchema } from '../model/base.js';
import { ClosedOutcomeInternalReasonSchema, ClosedOutcomeReasonSchema, ClosedOutcomeSchema, } from '../model/outcome.js';
import { PublicationTargetsSchema } from '../model/publishable.js';
import { BaseHeaderSchema } from './base.js';
const CloseClaimSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: ClosedOutcomeSchema.omit({ isClosed: true }).merge(z.object({
        publicationTargets: PublicationTargetsSchema.array().optional(),
        reason: ClosedOutcomeReasonSchema.exclude([...ClosedOutcomeInternalReasonSchema.options]).or(z.string()),
    })),
    headers: BaseHeaderSchema,
    response: z
        .object({
        claimNumber: ClaimNumberSchema,
    })
        .merge(ClosedOutcomeSchema),
};
export const CloseClaimRequestSchema = CloseClaimSchemas.params
    .merge(CloseClaimSchemas.body)
    .merge(CloseClaimSchemas.headers);
export const CloseClaimInputSchema = CloseClaimRequestSchema.merge(z.object({
    reason: ClosedOutcomeReasonSchema.or(z.string()),
}));
export const CloseClaimApiSchema = CloseClaimSchemas;
