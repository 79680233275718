import { z } from 'zod';
import { ContactRoleConsumerInSchema, ContactSchema } from '../base/contacts.js';
export const BookContactQueryModelSchema = ContactSchema.omit({ roles: true }).merge(z.object({
    roles: z.union([z.set(ContactRoleConsumerInSchema), z.array(ContactRoleConsumerInSchema)]),
}));
export const ContactQueryModelSchema = ContactSchema.omit({ roles: true })
    .merge(z.object({
    roles: z.set(ContactRoleConsumerInSchema),
}))
    .extend({
    PK: z.string(), //'CONTACT#<<contactId>>'
    SK: z.string(), //'<<contactId>>'
});
