import z from 'zod';
import { NotebookCornSchema, NoteSchema } from '../base/index.js';
import { NotesServiceEventBaseSchema, serviceEventType } from './notesService.js';
export const noteEventType = (eventName) => serviceEventType('note', eventName);
export const notebookEventType = (eventName) => serviceEventType('notebook', eventName);
export const NoteEventSchema = NotesServiceEventBaseSchema.merge(z.object({
    type: noteEventType(),
}));
export const NotebookEventSchema = NotesServiceEventBaseSchema.merge(z.object({
    type: notebookEventType(),
}));
export const NoteAddedEventSchema = NoteEventSchema.merge(z.object({
    type: noteEventType('added'),
    note: NoteSchema.merge(z.object({ notebookId: NotebookCornSchema })).partial({
        entityId: true,
    }),
}));
export const NoteRemovedEventSchema = NoteEventSchema.merge(z.object({
    type: noteEventType('removed'),
    note: NoteSchema.merge(z.object({ notebookId: NotebookCornSchema })),
}));
export const NoteMarkedForPublicationEventSchema = NoteEventSchema.merge(z.object({
    type: noteEventType('markedForPublication'),
    note: NoteSchema,
}));
export const NoteTaggedContactsEventSchema = NoteEventSchema.merge(z.object({
    type: noteEventType('taggedContacts'),
    note: NoteSchema,
}));
