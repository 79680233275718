import z from 'zod';
export const UnlinkAccountsRequestBodySchema = z.object({
    userId: z.string(),
    linkedUserIds: z.string().array(),
});
export const UnlinkAccountsRequestSchema = UnlinkAccountsRequestBodySchema;
export const UnlinkAccountsResponseSchema = z.void();
export const UnlinkAccountsApiSchema = {
    body: UnlinkAccountsRequestBodySchema,
};
