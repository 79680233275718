import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { CornSchema } from '@eigtech/shared-corn';
import z from 'zod';
import { AttendeeSchema, MeetingSchema } from '../base/chime.js';
import { MeetingDetailsSchema } from '../base/meetingDetails.js';
import { ParticipantSchema } from '../base/participant.js';
export const JoinMeetingRequestBodySchema = z.object({
    participant: ParticipantSchema.omit({ auth0Id: true, attendeeId: true }),
});
export const JoinMeetingRequestPathSchema = z.object({
    entityId: CornSchema,
    meetingId: z.string(),
});
export const JoinMeetingRequestHeaderSchema = ApiLambdalerAuthHeaderSchema;
export const JoinMeetingRequestSchema = JoinMeetingRequestBodySchema.merge(JoinMeetingRequestPathSchema).merge(JoinMeetingRequestHeaderSchema);
export const JoinMeetingResponseSchema = z.object({
    meetingDetails: MeetingDetailsSchema,
    chimeMeeting: MeetingSchema,
    chimeAttendee: AttendeeSchema,
});
