import z from 'zod';
import { CarrierNameSchema } from '../model/index.js';
export const GetCarrierConfigurationSchemas = {
    query: z.object({
        carrier: CarrierNameSchema,
    }),
    response: z.object({
        lossTypes: z.string().array(),
    }),
};
export const GetCarrierConfigurationRequestSchema = GetCarrierConfigurationSchemas.query;
export const GetCarrierConfigurationResponseSchema = GetCarrierConfigurationSchemas.response;
export const GetCarrierConfigurationApiSchema = GetCarrierConfigurationSchemas;
