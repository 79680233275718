import { z } from 'zod';
export const TranscriptItemSchema = z.object({
    type: z.string(),
    startTime: z.number(),
    endTime: z.number(),
    speakerAttendeeId: z.string(),
    speakerExternalUserId: z.string(),
    content: z.string(),
    vocabularyFilterMatch: z.boolean(),
});
export const TranscriptResultSchema = z.object({
    resultId: z.string(),
    isPartial: z.boolean(),
    startTimeMs: z.number(), // epoch time
    endTimeMs: z.number(), // epoch time
    alternatives: z
        .object({
        transcript: z.string(),
        items: TranscriptItemSchema.array(),
    })
        .array(),
});
export const TranscriptSchema = z.object({
    transcript: z.object({
        results: TranscriptResultSchema.array(),
    }),
});
export const AbsoluteTranscriptEventSchema = z.object({
    speakerName: z.string(),
    date: z.date(),
    text: z.string(),
});
export const AbsoluteTranscriptSchema = AbsoluteTranscriptEventSchema.array();
export const RelativeTranscriptEventSchema = z.object({
    speakerName: z.string(),
    seconds: z.number(),
    text: z.string(),
});
export const RelativeTranscriptSchema = RelativeTranscriptEventSchema.array();
