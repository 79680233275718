import z from 'zod';
import { SuccessApiOpDetailsSchema } from './base/auth0Events/SuccessApiOperationDetails.js';
// description "Remove one or more roles from a given user in the context of the provided organization"
// path "/api/v2/organizations/org_Miqv9tEm8irQwL17/members/auth0%7C63377b17eb80942f6f0b4b3a/roles"
export const RoleRemovedFromOrgUserBodySchema = SuccessApiOpDetailsSchema.extend({
    request: SuccessApiOpDetailsSchema.shape.request.extend({
        method: z.literal('delete'),
        query: z.object({}),
        body: z.object({}),
    }),
    response: SuccessApiOpDetailsSchema.shape.response.extend({
        body: z.object({}),
    }),
});
export const RoleRemovedFromOrgUserPathSchema = z.object({
    userId: z.string(),
    orgId: z.string(),
});
export const RoleRemovedFromOrgUserRequestSchema = RoleRemovedFromOrgUserBodySchema.merge(RoleRemovedFromOrgUserPathSchema);
