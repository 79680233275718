import z from 'zod';
import { TimelineEntityTypeSchema } from '../timeline.js';
export const StepFunctionErrorSchema = z.object({
    errorMessage: z.string(),
    errorType: z.string().optional(),
    trace: z.string().array().optional(),
});
export const isStepFunctionError = (error) => StepFunctionErrorSchema.safeParse(error).success;
export const TimelineSyncErrorSchema = z.object({
    errorMessage: z.string(),
    entityType: TimelineEntityTypeSchema,
    executionId: z.string(),
});
export const isTimelineSyncError = (error) => TimelineSyncErrorSchema.safeParse(error).success;
