import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { JobIdSchema } from '../../base/ids.js';
import { JobDetailsSchema } from '../../base/jobDetails.js';
export const GetJobDetailsInternalApiSchema = {
    params: z.object({
        jobId: JobIdSchema,
    }),
    headers: ApiLambdalerAuthHeaderSchema.pick({ requestActor: true }).required({
        requestActor: true,
    }),
    response: z.object({ job: JobDetailsSchema }),
};
export const GetJobDetailsInternalRequestSchema = GetJobDetailsInternalApiSchema.params;
