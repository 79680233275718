import { defineTable, Schema } from 'squid';
const reportingEventsTable = defineTable('reporting_events', {
    id: Schema.String,
    entity_id: Schema.String,
    entity_type: Schema.nullable(Schema.String),
    event_name: Schema.nullable(Schema.String),
    date: Schema.nullable(Schema.Date),
    data_source: Schema.nullable(Schema.String),
    additional: Schema.nullable(Schema.JSON(Schema.Any)),
});
