import z from 'zod';
import { VediImportSchema } from '../vediImport.js';
export const GetClaimImportsApiSchema = {
    params: z.object({
        claimNumber: z.string(),
    }),
    response: z.object({
        results: VediImportSchema.array(),
    }),
};
