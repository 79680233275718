import { z } from 'zod';
import { claimsServiceCorn } from '../base/service.js';
/** @deprecated */
export const NotebookIdSchema = z.string();
/** @deprecated */
export const EstimateIdSchema = z.string();
/** @deprecated */
export const EMSJobIdSchema = z.string();
/** @deprecated */
export const BuildingInformationIdSchema = z.string();
/** @deprecated */
export const ExternalSystemId = z.enum(['verisk', 'symbility', 'symbilityAssignmentId']);
/** @deprecated */
export const ExternalSystemIdsSchema = z.record(ExternalSystemId, z.string());
/** @deprecated */
export const ClaimCornSchema = claimsServiceCorn('claim');
/** @deprecated */
export const isClaimCorn = (entityId) => ClaimCornSchema.safeParse(entityId).success;
/** @deprecated */
export const ClaimSchema = z.object({
    claimNumber: z.string(),
    policyNumber: z.string(),
    lossDetailsId: z.string(),
    lossLocationId: z.string(),
    externalSystemIds: ExternalSystemIdsSchema.optional(),
});
