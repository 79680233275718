import { CohortEnumSchema } from '@eigtech/auth0-types';
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { CornSchema } from '@eigtech/shared-corn';
import { z } from 'zod';
export const GetUploadUrlApiSchema = {
    headers: ApiLambdalerAuthHeaderSchema,
    params: z.object({
        cohort: CohortEnumSchema,
        entityId: CornSchema,
    }),
    response: z.object({
        fields: z.record(z.string()),
        url: z.string(),
    }),
};
export const GetUploadUrlRequestSchema = GetUploadUrlApiSchema.params.merge(GetUploadUrlApiSchema.headers);
export const GetUploadUrlResponseSchema = GetUploadUrlApiSchema.response;
