import { z } from 'zod';
export const ClaimReviewerSchema = z.object({
    auth0Id: z.string(),
    name: z.string(),
});
export const CompletedReviewSchema = z.object({
    dateFlagged: z.string().datetime(),
    dateReviewed: z.string().datetime(),
    flaggedBy: z.string(),
    flaggerNotes: z.string(),
    reviewedBy: z.string(),
    reviewers: ClaimReviewerSchema.array(),
    reviewerNotes: z.string(),
});
export const PendingReviewSchema = CompletedReviewSchema.omit({
    dateReviewed: true,
    reviewedBy: true,
    reviewerNotes: true,
});
export const ClaimReviewSchema = z.union([CompletedReviewSchema, PendingReviewSchema]);
export const ClaimReviewDetailsSchema = z.object({
    pending: PendingReviewSchema.optional(),
    completed: CompletedReviewSchema.array().optional(),
});
