import { CornSchema } from '@eigtech/shared-corn';
import z from 'zod';
const MediaCapturePipelineSchema = z.object({
    MediaPipelineId: z.string().optional(),
    MediaPipelineArn: z.string().optional(),
    SourceType: z.string().optional(),
    SourceArn: z.string().optional(),
    Status: z.string().optional(),
    SinkType: z.string().optional(),
    SinkArn: z.string().optional(),
    CreatedTimestamp: z.string().or(z.date()).optional(),
    UpdatedTimestamp: z.string().or(z.date()).optional(),
    ChimeSdkMeetingConfiguration: z
        .object({
        SourceConfiguration: z
            .object({
            SelectedVideoStreams: z
                .object({
                AttendeeIds: z.string().array().optional(),
                ExternalUserIds: z.string().array().optional(),
            })
                .optional(),
        })
            .optional(),
        ArtifactsConfiguration: z
            .object({
            Audio: z.object({ MuxType: z.string().optional() }).optional(),
            Video: z
                .object({
                State: z.string().optional(),
                MuxType: z.string().optional(),
            })
                .optional(),
            Content: z
                .object({
                State: z.string().optional(),
                MuxType: z.string().optional(),
            })
                .optional(),
            CompositedVideo: z
                .object({
                Layout: z.string().optional(),
                Resolution: z.string().optional(),
                GridViewConfiguration: z
                    .object({
                    ContentShareLayout: z.string().optional(),
                    PresenterOnlyConfiguration: z
                        .object({
                        PresenterPosition: z.string().optional(),
                    })
                        .optional(),
                })
                    .optional(),
            })
                .optional(),
        })
            .optional(),
    })
        .optional(),
});
export const MeetingCaptureSchema = z.object({
    entityId: CornSchema,
    meetingId: z.string(),
    meetingStartTime: z.string(),
    meetingEndTime: z.string().optional(),
    mediaCapturePipeline: MediaCapturePipelineSchema,
});
