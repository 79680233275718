import { ContactSchema } from '@eigtech/contacts-types';
import z from 'zod';
import { BaseClaimSchema, BaseLossDetailsSchema } from './base.js';
export const PublicationTargetsSchema = z.enum(['XactAnalysis']);
export const PublishableLossDetailsSchema = BaseLossDetailsSchema.merge(z.object({
    lossDescription: z.string().min(1),
})).required({
    // catCode: false,
    dateOfLoss: true,
    lossDescription: true,
    lossType: true,
    severity: true,
});
export const PublishableCoverage = z.object({
    applyTo: z.enum(['R/C', 'ACV', 'Both']),
});
export const PublishablePolicySchema = z.object({
    policyEffectiveDate: z.string().datetime(),
    policyCoverageStartDate: z.string().datetime(),
    policyExpirationDate: z.string().datetime(),
    isCommercial: z.boolean(),
    applyDeductibleAcrossAllCoverages: z.boolean(),
    coverages: z.object({
        coverageA: PublishableCoverage,
        coverageB: PublishableCoverage,
        coverageC: PublishableCoverage,
    }),
});
export const PublicationTargets = PublicationTargetsSchema.Values;
export const PublishableClaimSchema = BaseClaimSchema.required({
    carrier: true,
    claimNumber: true,
    dateReceived: true,
    lossLocation: true,
    policyNumber: true,
}).merge(z.object({
    lossDetails: PublishableLossDetailsSchema,
    policy: PublishablePolicySchema.optional(), // only need policy holder name
    policyHolder: ContactSchema,
    publicationTarget: PublicationTargetsSchema,
}));
export const IsPublishableClaimSchema = PublishableClaimSchema.merge(z.object({ publicationTarget: PublicationTargetsSchema.optional() }));
