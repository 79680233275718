import z from 'zod';
import { PublicationTargetsSchema } from '../model/index.js';
import { ClaimNumberSchema } from '../model/base.js';
import { BaseHeaderSchema } from './base.js';
const MarkClaimForPublicationSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: z.object({ publicationTarget: PublicationTargetsSchema }),
    headers: BaseHeaderSchema,
};
export const MarkClaimForPublicationRequestSchema = MarkClaimForPublicationSchemas.params
    .merge(MarkClaimForPublicationSchemas.body)
    .merge(MarkClaimForPublicationSchemas.headers);
export const MarkClaimForPublicationApiSchema = MarkClaimForPublicationSchemas;
