import z from 'zod';
import { ClaimNumberSchema, ClosedOutcomeInternalReasonSchema, ClosedOutcomeReasonSchema, OutcomeSchema, ReopenedOutcomeSchema, } from '../model/index.js';
import { BaseHeaderSchema } from './base.js';
const ReopenClaimSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: OutcomeSchema.omit({ isClosed: true }).merge(z.object({
        reason: ClosedOutcomeReasonSchema.or(z.string()).optional(),
    })),
    headers: BaseHeaderSchema,
    response: z
        .object({
        claimNumber: ClaimNumberSchema,
    })
        .merge(ReopenedOutcomeSchema),
};
export const ReopenClaimRequestSchema = ReopenClaimSchemas.params
    .merge(ReopenClaimSchemas.body)
    .merge(ReopenClaimSchemas.headers);
export const ReopenClaimInputSchema = ReopenClaimRequestSchema.merge(z.object({
    reason: ClosedOutcomeReasonSchema.or(ClosedOutcomeInternalReasonSchema)
        .or(z.string())
        .optional(),
}));
export const ReopenClaimApiSchema = ReopenClaimSchemas;
