import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { BaseJobSchema } from '../../base/baseJob.js'
import { JobSchema, JobTypeSchema } from '../../base/job.js'
import { JobServicesSchema } from '../../base/jobService.js'

export const ReceiveJobInternalRequestBodySchema = BaseJobSchema.omit({
  jobId: true,
  jobNumber: true,
  status: true,
}).merge(
  z.object({
    jobType: JobTypeSchema,
    services: JobServicesSchema,
  })
)
export const ReceiveJobInternalRequestSchema = ReceiveJobInternalRequestBodySchema
export const ReceiveJobInternalResponseSchema = JobSchema
export const ReceiveJobInternalApiSchema: ApiSchema = {
  body: ReceiveJobInternalRequestBodySchema,
}

export type ReceiveJobInternalRequestBody = z.infer<typeof ReceiveJobInternalRequestBodySchema>

export type ReceiveJobInternalRequest = z.infer<typeof ReceiveJobInternalRequestSchema>
export type ReceiveJobInternalResponse = z.infer<typeof ReceiveJobInternalResponseSchema>
export type ReceiveJobInternalHandler = Handler<
  ReceiveJobInternalRequest,
  ReceiveJobInternalResponse
>
