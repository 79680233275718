import { z } from 'zod';
import { GlobalBookCornCanonSchema } from '../../../base/books.js';
export const ListGlobalBooksRequestQueryStringSchema = z.object({
    name: z.string().optional(),
});
export const ListGlobalBooksRequestSchema = ListGlobalBooksRequestQueryStringSchema;
/**
 * Allow deprecated book IDs so they can be deleted manually
 */
export const ListGlobalBooksResponseItemSchema = z.object({
    bookId: GlobalBookCornCanonSchema,
    name: z.string(),
    numContacts: z.number(),
});
export const ListGlobalBooksResponseSchema = z.object({
    books: ListGlobalBooksResponseItemSchema.array(),
});
export const ListGlobalBooksApiSchema = {
    query: ListGlobalBooksRequestQueryStringSchema,
    response: ListGlobalBooksResponseSchema,
};
