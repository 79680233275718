import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { JobIdSchema } from '../../base/ids.js';
import { JobDetailsSchema } from '../../base/jobDetails.js';
export const GetJobDetailsApiSchema = {
    params: z.object({
        jobId: JobIdSchema,
    }),
    headers: ApiLambdalerAuthHeaderSchema.pick({ requestActor: true, requestToken: true }).required({
        requestActor: true,
        requestToken: true,
    }),
    response: z.object({ job: JobDetailsSchema }),
};
export const GetJobDetailsRequestSchema = GetJobDetailsApiSchema.params.merge(GetJobDetailsApiSchema.headers);
