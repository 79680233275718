import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { VendorRatesSchema } from '../../base/vendor.js';
export const GetVendorRatesApiSchema = {
    params: z.object({
        vendorRatesId: VendorRatesSchema.shape.id,
    }),
    headers: ApiLambdalerAuthHeaderSchema,
    response: z.object({
        vendorRates: VendorRatesSchema,
    }),
};
