import z from 'zod';
import { AssignableTypeEnumSchema, AssigneeSchema, AssigneeTypeEnumSchema, } from '../entities/assignment.js';
import { AssignmentsServiceEventBaseSchema, serviceEventType } from './service.js';
export const assigneeUpdatedEventType = (assigneeType) => serviceEventType(assigneeType, 'updated');
export const AssigneeUpdatedEventSchema = AssignmentsServiceEventBaseSchema.merge(z.object({
    type: assigneeUpdatedEventType(),
    metadata: z.object({
        assignableType: AssignableTypeEnumSchema,
        assigneeType: AssigneeTypeEnumSchema,
        assigneeRelationship: z.string(),
    }),
    assignment: AssigneeSchema,
}));
