import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { ClaimNumberSchema } from '../model/index.js';
export const UpdateCatCodeSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: z.object({
        catCode: z.string(),
    }),
    headers: ApiLambdalerAuthHeaderSchema.pick({ requestActor: true }).required({
        requestActor: true,
    }),
    response: z.object({
        claimNumber: ClaimNumberSchema,
        lossDetails: z.object({
            catCode: z.string(),
        }),
    }),
};
export const UpdateCatCodeRequestSchema = UpdateCatCodeSchemas.params
    .merge(UpdateCatCodeSchemas.body)
    .merge(UpdateCatCodeSchemas.headers);
export const UpdateCatCodeApiSchema = UpdateCatCodeSchemas;
