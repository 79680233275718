import { ContactCornSchema } from '@eigtech/contacts-types';
import z from 'zod';
import { JobExternalIdSchema } from './externalIds.js';
export const SubcontractorPaymentSchema = z.object({
    externalId: JobExternalIdSchema.optional(), // e.g. transaction ID
    paidDate: z.string().datetime().optional(),
    paidInFull: z.boolean(),
});
export const JobDepositPaymentSchema = z.object({
    paidBy: ContactCornSchema.or(z.string()),
    paidDate: z.string().datetime(),
    paidInFull: z.boolean(),
    externalId: JobExternalIdSchema.optional(),
});
export const JobDepositOverrideApprovalSchema = z.object({
    approvedBy: ContactCornSchema.or(z.string()),
    approvedDate: z.string().datetime(),
    externalId: JobExternalIdSchema.optional(),
});
export const JobBillCollectionSchema = z.object({
    externalId: JobExternalIdSchema.optional(),
    sentDate: z.string().datetime(),
    collectorName: z.string().optional(),
});
export const CarrierPaymentSchema = z.object({
    externalId: JobExternalIdSchema.optional(),
    paidBy: ContactCornSchema.or(z.string()),
    paidDate: z.string().datetime(),
    paidInFull: z.boolean(),
});
export const CarrierInvoiceSchema = z.object({
    externalId: JobExternalIdSchema.optional(),
    carrier: ContactCornSchema.or(z.string()),
    invoicedDate: z.string().datetime(),
    notes: z.string().optional(),
});
