import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { JobIdSchema, SubcontractorReceivedDocumentSchema, SubcontractorSchema, } from '../../base/index.js';
export const RecordSubcontractorDocumentReceivedRequestBodySchema = z.object({
    subcontractorDocument: SubcontractorReceivedDocumentSchema.partial({
        subcontractorDocumentId: true,
    }),
    subcontractor: SubcontractorSchema,
});
export const RecordSubcontractorDocumentReceivedRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({ requestActor: true });
export const RecordSubcontractorDocumentReceivedRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const RecordSubcontractorDocumentReceivedRequestSchema = RecordSubcontractorDocumentReceivedRequestBodySchema.merge(RecordSubcontractorDocumentReceivedRequestHeadersSchema).merge(RecordSubcontractorDocumentReceivedRequestPathSchema);
export const RecordSubcontractorDocumentReceivedResponseSchema = z.object({
    jobId: JobIdSchema,
    subcontractorDocument: SubcontractorReceivedDocumentSchema,
    subcontractor: SubcontractorSchema,
});
export const RecordSubcontractorDocumentReceivedApiSchema = {
    body: RecordSubcontractorDocumentReceivedRequestBodySchema,
    headers: RecordSubcontractorDocumentReceivedRequestHeadersSchema,
    params: RecordSubcontractorDocumentReceivedRequestPathSchema,
    response: RecordSubcontractorDocumentReceivedResponseSchema,
};
