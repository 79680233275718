import { z } from 'zod';
import { EstimateReviewOutcomeSchema, EstimateReviewSchema, EstimateReviewerRoleSchema, } from '../model/estimate.js';
import { BaseEstimateSchema, RequestActorSchema } from '../model/base.js';
import { ClaimsV2ServiceEventBaseSchema, claimsV2ServiceEventType } from './service.js';
export const EstimateEventTypeSchema = z.enum(['received', 'reviewed']);
export const EstimateEventType = EstimateEventTypeSchema.Enum;
export const estimateEventType = (eventName) => claimsV2ServiceEventType('estimate', eventName);
export const EstimateEventSchema = ClaimsV2ServiceEventBaseSchema.merge(z.object({
    type: estimateEventType(),
    estimate: z.unknown(),
    metadata: ClaimsV2ServiceEventBaseSchema.shape.metadata.and(z.object({
        requestActor: RequestActorSchema,
    })),
}));
export const EstimateReceivedEventSchema = EstimateEventSchema.merge(z.object({
    type: estimateEventType(EstimateEventType.received),
    estimate: BaseEstimateSchema,
}));
export const isEstimateReceivedEvent = (event) => EstimateReceivedEventSchema.safeParse(event).success;
export const EstimateReviewedEventSchema = EstimateEventSchema.merge(z.object({
    type: estimateEventType(EstimateEventType.reviewed),
    estimate: z.object({
        review: EstimateReviewSchema,
        estimate: BaseEstimateSchema,
    }),
    metadata: EstimateEventSchema.shape.metadata.and(z.object({
        outcome: EstimateReviewOutcomeSchema,
        publishToXa: z.boolean(),
        reviewerRole: EstimateReviewerRoleSchema,
    })),
}));
export const isEstimateReviewedEvent = (event) => EstimateReviewedEventSchema.safeParse(event).success;
