import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { ClaimNumberSchema } from '../model/index.js';
export const UpdateDateOfLossSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: z.object({
        dateOfLoss: z.string().datetime(),
    }),
    headers: ApiLambdalerAuthHeaderSchema.pick({ requestActor: true }).required({
        requestActor: true,
    }),
    response: z.object({
        claimNumber: ClaimNumberSchema,
        lossDetails: z.object({
            dateOfLoss: z.string(),
        }),
    }),
};
export const UpdateDateOfLossRequestSchema = UpdateDateOfLossSchemas.params
    .merge(UpdateDateOfLossSchemas.body)
    .merge(UpdateDateOfLossSchemas.headers);
export const UpdateDateOfLossApiSchema = UpdateDateOfLossSchemas;
