import { z } from 'zod';
import { ParsedExportBaseSchema } from './parsedBase.js';
import { ParsedContactSchema } from './parsedContact.js';
/** This type is temporary and should be updated when we understand more about what this event will look like*/
export const ParsedStatusExportSchema = ParsedExportBaseSchema.merge(z.object({
    type: z.string(),
    claimNumber: z.string(),
    contacts: ParsedContactSchema.array(),
    origTransactionId: z.string().optional(),
    recipientsXNAddress: z.string(),
    recipientsXM8UserId: z.string(),
    senderReviewerEmail: z.string().optional(),
    senderReviewerName: z.string().optional(),
}));
