import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { ClaimStatusLaxSchema } from '../../aggregates/claimStatus.js';
/** @deprecated */
export const UpsertClaimStatusRequestBodySchema = ClaimStatusLaxSchema.omit({ id: true });
/** @deprecated */
export const UpsertClaimStatusRequestHeaderSchema = ApiLambdalerAuthHeaderSchema;
/** @deprecated */
export const SetStatusRequestSchema = UpsertClaimStatusRequestBodySchema.merge(UpsertClaimStatusRequestHeaderSchema);
/** @deprecated */
export const UpsertClaimStatusResponseSchema = z.void();
