import { States } from '@eigtech/function-utils';
import { JobCornSchema } from '@eigtech/restoration-types';
import { z } from 'zod';
import { MechanicalTypeSchema, RegionCornSchema, RoofPitchSchema, SalesLineItemSchema, SubtotalLineItemSchema, TaxLineItemSchema, TimeOfServiceSchema, VendorRatesCornSchema, } from '../../base/index.js';
export const EmsTarpingDetailsSchema = z.object({
    isMultiStory: z.boolean(),
    mechanicalType: MechanicalTypeSchema,
    roofPitch: RoofPitchSchema,
    state: States.UsStatesAbbreviatedSchema,
    tarpSquareFeet: z.number(),
    timeOfService: TimeOfServiceSchema,
});
export const CalculateEmsTarpingApiSchema = {
    params: z.object({
        jobId: JobCornSchema,
        regionId: RegionCornSchema,
        vendorRatesId: VendorRatesCornSchema,
    }),
    response: z.object({
        bill: z.object({
            description: z.string(),
            lineItems: z.object({
                subtotal: SubtotalLineItemSchema,
                stateTax: TaxLineItemSchema,
                surtax: SalesLineItemSchema.optional(),
            }),
            total: z.number(),
        }),
        invoice: z.object({
            description: z.string(),
            lineItems: z.object({
                subtotal: SubtotalLineItemSchema,
                stateTax: TaxLineItemSchema,
                surtax: SalesLineItemSchema.optional(),
            }),
            total: z.number(),
        }),
    }),
};
