import { z } from 'zod';
export var UsStates;
(function (UsStates) {
    UsStates["AL"] = "Alabama";
    UsStates["AK"] = "Alaska";
    UsStates["AS"] = "American Samoa";
    UsStates["AZ"] = "Arizona";
    UsStates["AR"] = "Arkansas";
    UsStates["CA"] = "California";
    UsStates["CO"] = "Colorado";
    UsStates["CT"] = "Connecticut";
    UsStates["DE"] = "Delaware";
    UsStates["DC"] = "District Of Columbia";
    UsStates["FM"] = "Federated States Of Micronesia";
    UsStates["FL"] = "Florida";
    UsStates["GA"] = "Georgia";
    UsStates["GU"] = "Guam";
    UsStates["HI"] = "Hawaii";
    UsStates["ID"] = "Idaho";
    UsStates["IL"] = "Illinois";
    UsStates["IN"] = "Indiana";
    UsStates["IA"] = "Iowa";
    UsStates["KS"] = "Kansas";
    UsStates["KY"] = "Kentucky";
    UsStates["LA"] = "Louisiana";
    UsStates["ME"] = "Maine";
    UsStates["MH"] = "Marshall Islands";
    UsStates["MD"] = "Maryland";
    UsStates["MA"] = "Massachusetts";
    UsStates["MI"] = "Michigan";
    UsStates["MN"] = "Minnesota";
    UsStates["MS"] = "Mississippi";
    UsStates["MO"] = "Missouri";
    UsStates["MT"] = "Montana";
    UsStates["NE"] = "Nebraska";
    UsStates["NV"] = "Nevada";
    UsStates["NH"] = "New Hampshire";
    UsStates["NJ"] = "New Jersey";
    UsStates["NM"] = "New Mexico";
    UsStates["NY"] = "New York";
    UsStates["NC"] = "North Carolina";
    UsStates["ND"] = "North Dakota";
    UsStates["MP"] = "Northern Mariana Islands";
    UsStates["OH"] = "Ohio";
    UsStates["OK"] = "Oklahoma";
    UsStates["OR"] = "Oregon";
    UsStates["PW"] = "Palau";
    UsStates["PA"] = "Pennsylvania";
    UsStates["PR"] = "Puerto Rico";
    UsStates["RI"] = "Rhode Island";
    UsStates["SC"] = "South Carolina";
    UsStates["SD"] = "South Dakota";
    UsStates["TN"] = "Tennessee";
    UsStates["TX"] = "Texas";
    UsStates["UT"] = "Utah";
    UsStates["VT"] = "Vermont";
    UsStates["VI"] = "Virgin Islands";
    UsStates["VA"] = "Virginia";
    UsStates["WA"] = "Washington";
    UsStates["WV"] = "West Virginia";
    UsStates["WI"] = "Wisconsin";
    UsStates["WY"] = "Wyoming";
})(UsStates || (UsStates = {}));
export const UsStatesSchema = z.nativeEnum(UsStates);
export const US_STATES = UsStatesSchema.enum;
export const UsStatesAbbreviatedSchema = z.enum([
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
]);
export const US_STATES_ABBREVIATED = UsStatesAbbreviatedSchema.Enum;
