import { z } from 'zod';
import { BaseClaimSchema } from '../model/index.js';
import { ClosedOutcomeInternalReasonSchema, ClosedOutcomeReasonSchema, ClosedOutcomeSchema, OutcomeSchema, OutcomeTypeEnum, ReopenedOutcomeSchema, } from '../model/outcome.js';
import { ClaimEventSchema, claimEventType } from './claim.js';
export const OutcomeAddedEventSchema = ClaimEventSchema.merge(z.object({
    claim: BaseClaimSchema.merge(z.object({
        outcome: OutcomeSchema,
    })),
    metadata: ClaimEventSchema.shape.metadata.and(z.object({
        reason: ClosedOutcomeReasonSchema.or(ClosedOutcomeInternalReasonSchema).or(z.string().optional()),
    })),
}));
export const ClaimClosedEventSchema = OutcomeAddedEventSchema.merge(z.object({
    claim: BaseClaimSchema.merge(z.object({
        outcome: ClosedOutcomeSchema,
    })),
    metadata: ClaimEventSchema.shape.metadata.and(z.object({
        reason: ClosedOutcomeReasonSchema.or(z.string()),
        publishToXa: z.boolean(),
    })),
    type: claimEventType(OutcomeTypeEnum.closed),
}));
export const ClaimReopenedEventSchema = OutcomeAddedEventSchema.merge(z.object({
    type: claimEventType(OutcomeTypeEnum.reopened),
    claim: BaseClaimSchema.merge(z.object({
        outcome: ReopenedOutcomeSchema,
    })),
}));
