import { ContactCornSchema } from '@eigtech/contacts-types';
import { z } from 'zod';
import { DocumentTransitMethodSchema } from './document.js';
import { WorkAuthorizationIdSchema } from './ids.js';
export const WorkAuthorizationStatusEnumSchema = z.enum([
    'completed',
    'declined',
    'expired',
    'override_approved',
    'recalled',
    'sent',
    'signed',
]);
export const WorkAuthorizationStatus = WorkAuthorizationStatusEnumSchema.Enum;
/**
 * Currently using email as the fallback when no contact CORN is provided
 */
export const WorkAuthorizationRecipientSchema = ContactCornSchema.or(z.string());
export const WorkAuthorizationSchema = z.object({
    approvedBy: WorkAuthorizationRecipientSchema.optional(),
    recipient: WorkAuthorizationRecipientSchema,
    dateClosed: z.string().datetime().optional(),
    effectiveDate: z.string().datetime().optional(),
    sentDate: z.string().datetime(),
    sentMethod: DocumentTransitMethodSchema,
    signer: WorkAuthorizationRecipientSchema.optional(),
    signedOn: z.string().datetime().optional(),
    status: WorkAuthorizationStatusEnumSchema,
    workAuthorizationId: WorkAuthorizationIdSchema,
});
