import z from 'zod';
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
export const ImportClaimAssignmentRequestBodySchema = z.object({
    claimNumber: z.string(),
    transactionId: z.string().optional(),
    xactnetId: z.string().min(1),
});
export const ImportClaimAssignmentRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
});
export const ImportApiResponseSchema = z.object({
    success: z.boolean(),
    message: z.string(),
});
export const ImportClaimAssignmentRequestSchema = ImportClaimAssignmentRequestBodySchema.merge(ImportClaimAssignmentRequestHeadersSchema);
export const ImportClaimAssignmentApiSchema = {
    body: ImportClaimAssignmentRequestBodySchema,
    headers: ImportClaimAssignmentRequestHeadersSchema,
    response: ImportApiResponseSchema,
};
