import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { BookCornSchema, BookSchema } from '../../../base/books.js';
import { ContactSchema } from '../../../base/contacts.js';
export const DeleteBookRequestPathParametersSchema = z.object({
    bookId: BookCornSchema,
});
export const DeleteBookRequestHeadersSchema = ApiLambdalerAuthHeaderWithRequestActorSchema.pick({
    requestActor: true,
});
export const DeleteBookRequestSchema = DeleteBookRequestPathParametersSchema.merge(DeleteBookRequestHeadersSchema);
export const DeleteBookResponseSchema = z.object({
    book: BookSchema.extend({
        contacts: z.array(ContactSchema),
    }).optional(),
});
export const DeleteBookApiSchema = {
    headers: DeleteBookRequestHeadersSchema,
    params: DeleteBookRequestPathParametersSchema,
    response: DeleteBookResponseSchema,
};
