import z from 'zod';
import { makeSummaryFilterSchema } from './pagination.js';
export const TimelineSummaryFieldSchema = z.enum([
    'chronicleDate',
    'chronicleType',
    'fieldAdjusterId',
    'timelineType',
]);
export const TimelineSummaryQueryFieldSchema = z.enum([
    ...TimelineSummaryFieldSchema.options,
    'timelineId',
]);
export const TimelineSummaryFilterSchema = makeSummaryFilterSchema(TimelineSummaryFieldSchema);
export const TimelineSummaryFilterQuerySchema = makeSummaryFilterSchema(TimelineSummaryQueryFieldSchema);
