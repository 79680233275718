import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { CornSchema } from '@eigtech/shared-corn';
import { z } from 'zod';
import { PublicationTargetsSchema } from '../aggregates/index.js';
import { DocumentMarkedForPublicationEventSchemaV2 } from '../events/documents.js';
/** Mark  for publication */
export const MarkForPublicationApiSchema = {
    headers: ApiLambdalerAuthHeaderSchema,
    params: z.object({
        entityId: CornSchema,
        documentCorn: z.string(),
    }),
    body: z.object({
        publicationTargets: PublicationTargetsSchema.array(),
        publicationAttributes: DocumentMarkedForPublicationEventSchemaV2.shape.document.shape.publicationAttributes,
    }),
};
export const MarkForPublicationRequestSchema = MarkForPublicationApiSchema.body
    .merge(MarkForPublicationApiSchema.params)
    .merge(MarkForPublicationApiSchema.headers);
