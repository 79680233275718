import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { JobIdSchema } from '../../base/ids.js';
export const JobClosedReasonEnumSchema = z.enum(['completed', 'withdrawn', 'cancelled']);
export const CloseJobRequestBodySchema = z.object({
    closedReason: JobClosedReasonEnumSchema,
    closingNotes: z.string().optional(),
    closedDate: z.string().datetime(),
});
export const CloseJobRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const CloseJobRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
});
export const CloseJobRequestSchema = CloseJobRequestBodySchema.merge(CloseJobRequestHeadersSchema).merge(CloseJobRequestPathSchema);
export const CloseJobResponseSchema = z.void();
export const CloseJobApiSchema = {
    body: CloseJobRequestBodySchema,
    headers: CloseJobRequestHeadersSchema,
    params: CloseJobRequestPathSchema,
};
