import { CornSchema } from '@eigtech/shared-corn';
import z from 'zod';
import { MeetingDetailsSchema } from '../base/meetingDetails.js';
export const EndMeetingApiSchema = {
    params: z.object({
        entityId: CornSchema,
        startTime: z.string().datetime(),
    }),
    response: MeetingDetailsSchema,
};
export const EndMeetingRequestPathSchema = EndMeetingApiSchema.params;
export const EndMeetingRequestSchema = EndMeetingRequestPathSchema;
export const EndMeetingResponseSchema = EndMeetingApiSchema.response;
