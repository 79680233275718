import { z } from 'zod';
import { ClaimsServiceEventBaseSchema, claimsServiceEventType } from './service.js';
import { LossLocationSchema } from '../aggregates/lossLocation.js';
/** @deprecated */
export const lossLocationEntityName = 'lossLocation';
const lossLocationEventType = (eventName) => claimsServiceEventType(lossLocationEntityName, eventName);
/** @deprecated */
export const LossLocationEventSchema = ClaimsServiceEventBaseSchema.merge(z.object({
    type: lossLocationEventType(),
    lossLocation: z.unknown(),
}));
/** @deprecated */
export const LossLocationCreatedEventSchema = LossLocationEventSchema.merge(z.object({
    type: lossLocationEventType('created'),
    lossLocation: LossLocationSchema,
}));
/** @deprecated */
export const LossLocationUpdatedEventSchema = LossLocationEventSchema.merge(z.object({
    type: lossLocationEventType('updated'),
    lossLocation: LossLocationSchema,
}));
/** @deprecated */
export const LossLocationDeletedEventSchema = LossLocationEventSchema.merge(z.object({
    type: lossLocationEventType('deleted'),
    lossLocation: z.object({
        id: LossLocationSchema.shape.id,
    }),
}));
/** @deprecated */
export const isLossLocationCreatedEvent = (event) => LossLocationCreatedEventSchema.safeParse(event).success;
/** @deprecated */
export const isLossLocationUpdatedEvent = (event) => LossLocationUpdatedEventSchema.safeParse(event).success;
/** @deprecated */
export const isLossLocationDeletedEvent = (event) => LossLocationDeletedEventSchema.safeParse(event).success;
