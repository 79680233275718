import z from 'zod';
/**
 * ApiLambdaler specific
 */
export const ApiLambdalerAuthHeaderSchema = z.object({
    requestActor: z.string().optional(),
    requestToken: z
        .record(z.string(), z.union([z.string(), z.number(), z.array(z.string())]))
        .optional(),
});
/**
 * ApiLambdaler headers with required request actor
 */
export const ApiLambdalerAuthHeaderWithRequestActorSchema = ApiLambdalerAuthHeaderSchema.merge(z.object({
    requestActor: z.string(),
}));
/**
 * isKeyOfT is a Type Guard for telling typescript key is a keyof T
 */
export const isKeyOfT = (schema) => (key) => {
    return schema[key] !== undefined;
};
