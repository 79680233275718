import { z } from 'zod';
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
export const GetProfilePhotoUploadUrlRequestHeadersSchema = ApiLambdalerAuthHeaderSchema;
export const GetProfilePhotoUploadUrlRequestSchema = GetProfilePhotoUploadUrlRequestHeadersSchema;
export const GetProfilePhotoUploadUrlResponseSchema = z.object({
    fields: z.record(z.string()),
    url: z.string(),
});
export const GetProfilePhotoUploadUrlApiSchema = {
    headers: GetProfilePhotoUploadUrlRequestHeadersSchema,
    response: GetProfilePhotoUploadUrlResponseSchema,
};
