import z from 'zod';
import { BaseClaimSchema, ClaimDataSourceSchema, ClaimNumberSchema, } from '../model/index.js';
import { BaseHeaderSchema } from './base.js';
export const UpdateDateReceivedApiSchema = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: BaseClaimSchema.pick({
        dateReceived: true,
    }).merge(z.object({
        dataSource: ClaimDataSourceSchema.optional(),
        fromInAssignQueue: z.boolean(),
    })),
    headers: BaseHeaderSchema,
    response: BaseClaimSchema.pick({
        claimNumber: true,
        dateReceived: true,
    }),
};
export const UpdateDateReceivedRequestSchema = UpdateDateReceivedApiSchema.params
    .merge(UpdateDateReceivedApiSchema.body)
    .merge(UpdateDateReceivedApiSchema.headers);
