import { cornType } from '@eigtech/shared-corn';
import { z } from 'zod';
export const contactsServiceCorn = (eventName) => cornType('contacts', eventName);
export const ContactsServiceCornSchema = contactsServiceCorn();
// These types are based on the HL7 segment types for Contact Data
// See here https://hl7-definition.caristix.com/v2/HL7v2.5.1/Segments/CTD for more
export const ContactCornSchema = contactsServiceCorn('contact');
export const isContactCorn = (contactCorn) => ContactCornSchema.safeParse(contactCorn).success;
export const ContactNameSchema = z.object({
    id: z.string().optional(),
    familyName: z.optional(z.object({
        surname: z.string(),
        ownSurnamePrefix: z.optional(z.string()),
        ownSurname: z.optional(z.string()),
        surnamePrefixFromPartner: z.optional(z.string()),
        surnameFromPartner: z.optional(z.string()),
    })),
    givenName: z.string(),
    middleName: z.optional(z.string()),
    suffix: z.optional(z.string()),
    prefix: z.optional(z.string()),
    degree: z.optional(z.string()),
    nameType: z.optional(z.string()), //Convert to enum https://hl7-definition.caristix.com/v2/HL7v2.5.1/Tables/0200
    nameAssemblyOrder: z.optional(z.enum(['Prefix Family Middle Given Suffix', 'Prefix Given Middle Family Suffix'])),
    effectiveDate: z.optional(z.string()),
    expirationDate: z.optional(z.string()),
    professionalSuffix: z.optional(z.string()),
    rawName: z.string(),
});
export const ContactLocationSchema = z.object({
    accuracy: z.enum([
        'ADDRESS',
        'STREET',
        'ZIP',
        'CITY',
        'STATE',
        'COUNTRY',
        'CITY BLOCK',
        'INTERSECTION',
        'ZIP7',
        'ZIP9',
    ]),
    latitude: z.number(),
    longitude: z.number(),
});
// based on https://hl7-definition.caristix.com/v2/HL7v2.5.1/Tables/0190
export const ContactAddressTypeSchema = z.enum([
    'Firm or Business',
    'Bad Address',
    'Current or Temporary',
    'Home',
    'Legal Address',
    'Mailing',
    'Office',
    'Permanent',
    'Registry Home',
]);
export const ContactAddressSchema = z.object({
    id: z.string().optional(),
    streetAddress: z.optional(z.object({
        streetOrMailingAddress: z.optional(z.string()),
        streetName: z.optional(z.string()),
        dwellingNumber: z.optional(z.string()),
    })),
    otherDesignation: z.optional(z.string()),
    city: z.optional(z.string()),
    stateOrProvince: z.optional(z.string()),
    zipOrPostalCode: z.optional(z.string()),
    country: z.optional(z.string()),
    addressType: z.optional(ContactAddressTypeSchema.or(z.string())),
    otherGeographicDesignation: z.optional(z.string()),
    countyOrParishCode: z.optional(z.string()),
    censusTract: z.optional(z.string()),
    effectiveDate: z.optional(z.string()),
    expirationDate: z.optional(z.string()),
    location: z.optional(ContactLocationSchema),
    rawAddress: z.string(),
});
//Based on https://hl7-definition.caristix.com/v2/HL7v2.5.1/Tables/0201
export const TelecommunicationUseCodeSchema = z.enum([
    'Beeper',
    'Emergency',
    'Network',
    'Secondary',
    'Work',
    'Primary',
]);
//Based on https://hl7-definition.caristix.com/v2/HL7v2.5.1/Tables/0202
export const TelecommunicationEquipmentTypeSchema = z.enum([
    'Beeper',
    'Mobile',
    'Fax',
    'Internet',
    'Telephone',
    'TDD',
    'TTY',
    'Email',
]);
export const ContactCommunicationInformationSchema = z.object({
    id: z.string().optional(),
    telephoneNumber: z.string().nullish(),
    telecommunicationUseCode: z.optional(TelecommunicationUseCodeSchema),
    telecommunicationEquipmentType: z.optional(TelecommunicationEquipmentTypeSchema),
    emailAddress: z.optional(z.string()),
    countryCode: z.optional(z.number()),
    areaCode: z.optional(z.number()),
    extension: z.optional(z.string()),
    extensionPrefix: z.optional(z.string()),
    unformattedTelephoneNumber: z.optional(z.string().regex(/^[^\D]+$/, 'unformattedTelephoneNumber should only contain numbers')),
    effectiveStartDate: z.optional(z.string()),
    expirationDate: z.optional(z.string()),
    expirationReason: z.optional(z.string()),
    preferenceOrder: z.optional(z.number()),
});
export const DeprecatedContactRoleSchema = z.enum([
    /** @deprecated */
    'fileReviewer',
    /** @deprecated */
    'otherClaimContact',
    /** @deprecated */
    'primaryClaimContact',
]);
export const ContactRoleProducerSchema = z.enum([
    'agent',
    'approver',
    'carrier',
    'coordinator',
    'csr',
    'deskAdjuster',
    'dispatcher',
    'fieldAdjuster',
    'fieldTechnician',
    'legalRepresentation',
    'mortgageHolder',
    'other',
    'otherContact',
    'policyHolder',
    'primaryContact',
    'projectManager',
    'propertyAccessContact',
    'qa',
    'reviewer',
    'subcontractor',
    'thirdPartyClaimant',
    'inspectionTechnician',
]);
export const ContactRoleConsumerInSchema = z.enum([
    ...ContactRoleProducerSchema.options,
    ...DeprecatedContactRoleSchema.options,
]);
export const ContactRole = ContactRoleConsumerInSchema.Enum;
export const ContactRoleConsumerSchema = z
    .enum([...ContactRoleConsumerInSchema.options])
    .transform((role) => {
    switch (role) {
        case 'fileReviewer':
            return ContactRoleProducerSchema.Enum.reviewer;
        case 'otherClaimContact':
            return ContactRoleProducerSchema.Enum.otherContact;
        case 'primaryClaimContact':
            return ContactRoleProducerSchema.Enum.primaryContact;
        default:
            return role;
    }
});
export const contactRoleConsumerToProducer = (role) => {
    switch (role) {
        case 'fileReviewer':
            return ContactRoleProducerSchema.Enum.reviewer;
        case 'otherClaimContact':
            return ContactRoleProducerSchema.Enum.otherContact;
        case 'primaryClaimContact':
            return ContactRoleProducerSchema.Enum.primaryContact;
        default:
            return role;
    }
};
export const ContactRolesConsumerSchema = z.union([
    z.array(ContactRoleConsumerSchema),
    z.set(ContactRoleConsumerSchema),
]);
export const ContactMethodSchema = TelecommunicationUseCodeSchema.or(z.literal('unset'));
export const ContactDataSourceSchema = z.enum([
    'Auth0',
    'Verisk',
    'CSR',
    'Estimator',
    'Symbility',
    'Email',
]);
export const ContactDataSource = ContactDataSourceSchema.Values;
export const ExternalSystemId = z.enum(['auth0', 'xactnet', 'symbility']);
export const ExternalSystemIdsSchema = z.record(ExternalSystemId, z.string());
export const ContactProducerSchema = z.object({
    addresses: ContactAddressSchema.array(),
    communicationInformation: z.array(ContactCommunicationInformationSchema),
    contactId: ContactCornSchema,
    description: z.string().optional().nullable(),
    location: z.optional(ContactLocationSchema),
    picture: z.string().nullable().optional(),
    metadata: z
        .object({
        dataSource: ContactDataSourceSchema.or(z.string()),
        licenseNumber: z.string().optional().nullable(),
        removed: z.boolean().optional(),
        removedOn: z.string().optional(),
        inactive: z.boolean().optional(),
        inactiveOn: z.string().optional(),
    })
        .catchall(z.union([z.string(), z.number(), z.boolean(), z.null()])),
    name: z.array(ContactNameSchema),
    preferredMethodOfContact: z.optional(ContactMethodSchema),
    roles: z.array(ContactRoleProducerSchema),
    externalSystemIds: ExternalSystemIdsSchema.optional(),
});
/**
 * The `roles` property here must be a `ContactRoleConsumerIn` array. Ideally,
 * it would be a `ContactRoleConsumer` array, which should take in deprecated
 * values and normalize them, but Zod and TS aren't quite behaving this way.
 * If we keep having issues with this pattern, we might need to revisit e.g.
 * schema versions or only convert potentially historical (DB/event) data.
 */
export const ContactSchema = ContactProducerSchema.merge(z.object({
    roles: z.array(ContactRoleConsumerInSchema),
}));
export const ContactMergeStrategySchema = z.enum([
    'appendAll',
    'appendSelective',
    'takeLeft',
    'takeRight',
]);
