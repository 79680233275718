import z from 'zod';
import { TimelineEntityTypeSchema } from './timeline.js';
export const TimelineSyncStateSchema = z.enum(['running', 'failed', 'succeeded']);
export const TimelineSyncState = TimelineSyncStateSchema.Enum;
export const TimelineSyncResultsSchema = z.object({
    dateCompleted: z.string().datetime().optional(),
    dateStarted: z.string().datetime(),
    entityType: TimelineEntityTypeSchema,
    lastSucceeded: z.string().datetime().optional(),
    syncState: TimelineSyncStateSchema,
    run: z
        .object({
        error: z.string().optional(),
        executionId: z.string(),
        totalPages: z.number().optional(),
        lastPageProcessed: z.number().optional(),
    })
        .optional(),
});
