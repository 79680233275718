import z from 'zod';
import { AssignmentCanonSchema } from '../entities/assignment.js';
export const GetAssignmentsForAssignableApiSchema = {
    params: z.object({
        assignableId: z.string(),
    }),
    response: AssignmentCanonSchema.array(),
};
export const GetAssignmentsForAssignablePathRequestSchema = GetAssignmentsForAssignableApiSchema.params;
export const GetAssignmentsForAssignableRequestSchema = GetAssignmentsForAssignablePathRequestSchema;
export const GetAssignmentsForAssignableResponseSchema = GetAssignmentsForAssignableApiSchema.response;
