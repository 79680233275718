export var ClaimReviewerTimelineLabel;
(function (ClaimReviewerTimelineLabel) {
    ClaimReviewerTimelineLabel["qaApproved"] = "QA Approved";
    ClaimReviewerTimelineLabel["qaRejected"] = "QA Rejected";
    ClaimReviewerTimelineLabel["qaApprovedSupplementReport"] = "QA Approved Supplement Report";
})(ClaimReviewerTimelineLabel || (ClaimReviewerTimelineLabel = {}));
export const claimReviewerChronicleLabelMap = {
    qaApproved: ClaimReviewerTimelineLabel.qaApproved,
    qaRejected: ClaimReviewerTimelineLabel.qaRejected,
    qaApprovedSupplementReport: ClaimReviewerTimelineLabel.qaApproved,
};
