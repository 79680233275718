import z from 'zod';
import { ClaimNumberSchema, ExternalSystemIdSchema, ExternalSystemRecordSchema, } from '../model/base.js';
import { BaseHeaderSchema } from './base.js';
const UpsertExternalSystemIdSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: z.object({
        key: ExternalSystemIdSchema,
        value: z.string(),
    }),
    headers: BaseHeaderSchema,
    response: z.object({
        claimNumber: ClaimNumberSchema,
        externalSystemIds: ExternalSystemRecordSchema,
    }),
};
export const UpsertExternalSystemIdRequestSchema = UpsertExternalSystemIdSchemas.params
    .merge(UpsertExternalSystemIdSchemas.body)
    .merge(UpsertExternalSystemIdSchemas.headers);
export const UpsertExternalSystemIdApiSchema = UpsertExternalSystemIdSchemas;
