import z from 'zod';
import { TimelineEntityTypeSchema } from '../timeline.js';
import { StepFunctionTaskContextSchema } from './context.js';
export const SetupTimelineSyncInputSchema = z.object({
    entityType: TimelineEntityTypeSchema,
});
export const SetupTimelineSyncRequestSchema = z.object({
    context: StepFunctionTaskContextSchema,
    input: SetupTimelineSyncInputSchema,
});
export const SetupTimelineSyncResponseSchema = z.object({
    entityType: TimelineEntityTypeSchema,
    executionId: z.string(),
    pageSize: z.number(),
    totalPages: z.number(),
});
