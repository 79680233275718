import { zodRecordWithEnum } from '@eigtech/function-utils';
import { z } from 'zod';
export const TimeOfServiceSchema = z.enum(['afterBusinessHours', 'duringBusinessHours']);
export const TimeOfServiceEnum = TimeOfServiceSchema.Enum;
export const HourlyCostSchema = z.object({
    [TimeOfServiceEnum.afterBusinessHours]: z.number(),
    [TimeOfServiceEnum.duringBusinessHours]: z.number(),
});
export const MechanicalTypeSchema = z.enum(['mechanical', 'sandbag']);
export const TarpingCostSchema = zodRecordWithEnum(MechanicalTypeSchema, HourlyCostSchema.extend({
    materialCost: z.number(),
}));
export const RoofPitchSchema = z.enum([
    '07_12AndUnder',
    '07_12To09_12',
    '10_12To12_12',
    'over12_12',
]);
export const RoofPitchRatesSchema = zodRecordWithEnum(RoofPitchSchema, z.number());
export const CarrierRatesSchema = z.object({
    baseRate: HourlyCostSchema,
    highRoof: z.number(),
    roofPitch: RoofPitchRatesSchema,
    tarping: TarpingCostSchema,
});
