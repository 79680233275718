import { DocumentCornSchema } from '@eigtech/documents-types';
import { z } from 'zod';
export const LossOfUseTypeSchema = z.enum([
    'housing',
    'food',
    'transportation',
    'storage',
    'laundryAndCleaning',
    'petBoarding',
    'other',
]);
export const lossOfUseType = LossOfUseTypeSchema.Values;
export const LossOfUseStatusSchema = z.enum(['approved', 'denied', 'cancelled', 'pending']);
export const lossOfUseStatus = LossOfUseStatusSchema.Values;
export const LossOfUseReceiptSchema = z.object({
    documentCorn: DocumentCornSchema,
    note: z.string().optional(),
    receiptDateReceived: z.string().datetime(),
});
export const LossOfUseReceiptRemovedSchema = z.object({
    documentCorn: DocumentCornSchema,
    receiptDateRemoved: z.string().datetime(),
});
export const LossOfUseSchema = z.object({
    id: z.string(),
    status: LossOfUseStatusSchema,
    requestedDate: z.string().datetime(),
    durationInDays: z.number().min(0),
    type: LossOfUseTypeSchema,
    amountRequested: z.number().min(0),
    lastModified: z.string().datetime().optional(),
    receipts: z.record(DocumentCornSchema, LossOfUseReceiptSchema).optional(),
    description: z.string().optional(),
    justification: z.string().optional(),
});
