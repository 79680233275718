import { z } from 'zod';
import { BaseClaimSchema, ClaimNumberSchema } from '../model/base.js';
import { CompletedReviewSchema, PendingReviewSchema } from '../model/reviews.js';
import { ClaimEventSchema, ClaimEventType, claimEventType } from './claim.js';
export const ClaimReviewCompletedEventSchema = ClaimEventSchema.merge(z.object({
    type: claimEventType(ClaimEventType.reviewCompleted),
    claim: BaseClaimSchema.merge(z.object({
        review: CompletedReviewSchema,
    })),
}));
export const ClaimReviewCompletedEventInputSchema = z
    .object({
    claimNumber: ClaimNumberSchema,
})
    .merge(CompletedReviewSchema.pick({ dateReviewed: true, reviewedBy: true, reviewerNotes: true }));
export const ClaimReviewRequestedEventSchema = ClaimEventSchema.merge(z.object({
    type: claimEventType(ClaimEventType.reviewRequested),
    claim: BaseClaimSchema.merge(z.object({
        review: PendingReviewSchema,
    })),
}));
export const ClaimReviewRequestedEventInputSchema = z
    .object({
    claimNumber: ClaimNumberSchema,
})
    .merge(PendingReviewSchema);
