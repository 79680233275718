import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { CornSchema } from '@eigtech/shared-corn';
import z from 'zod';
import { MeetingInviteSchema, MeetingInviteeSchema } from '../base/meetingInvite.js';
export const SendMeetingInviteRequestPathSchema = z.object({
    entityId: CornSchema,
    meetingId: z.string(),
});
export const SendMeetingInviteRequestBodySchema = z.object({
    from: MeetingInviteSchema.shape.from.omit({ auth0Id: true }),
    to: MeetingInviteeSchema.array(),
});
export const SendMeetingInviteRequestHeaderSchema = ApiLambdalerAuthHeaderSchema;
export const SendMeetingInviteRequestSchema = SendMeetingInviteRequestBodySchema.merge(SendMeetingInviteRequestPathSchema).merge(SendMeetingInviteRequestHeaderSchema);
export const InviteeResponseSchema = MeetingInviteeSchema.and(z.object({ channel: z.enum(['text', 'email']), success: z.boolean() }));
export const SendMeetingInviteResponseSchema = z.array(InviteeResponseSchema);
