import z from 'zod';
import { SuccessApiOpDetailsSchema } from './base/auth0Events/SuccessApiOperationDetails.js';
// description: "Create a role"
// path /api/v2/roles
export const RoleCreatedBodySchema = SuccessApiOpDetailsSchema.extend({
    request: SuccessApiOpDetailsSchema.shape.request.extend({
        method: z.literal('post'),
        query: z.object({}),
        body: z.object({
            name: z.string(),
            description: z.string(),
        }),
    }),
    response: SuccessApiOpDetailsSchema.shape.response.extend({
        body: z.object({
            id: z.string(),
            name: z.string(),
            description: z.string(),
        }),
    }),
});
export const RoleCreatedRequestSchema = RoleCreatedBodySchema;
