import z from 'zod';
import { ClaimNumberSchema } from '../model/base.js';
import { CustomerCommunicationSchema } from '../model/customerCommunication.js';
import { PublicationTargetsSchema } from '../model/publishable.js';
import { BaseHeaderSchema } from './base.js';
const AddCustomerCommunicationSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: z.object({
        customerCommunication: CustomerCommunicationSchema,
        publicationTargets: PublicationTargetsSchema.array().optional(),
    }),
    headers: BaseHeaderSchema,
    response: z.object({
        claimNumber: ClaimNumberSchema,
        customerCommunication: CustomerCommunicationSchema,
    }),
};
export const AddCustomerCommunicationRequestSchema = AddCustomerCommunicationSchemas.params
    .merge(AddCustomerCommunicationSchemas.body)
    .merge(AddCustomerCommunicationSchemas.headers);
export const AddCustomerCommunicationApiSchema = AddCustomerCommunicationSchemas;
