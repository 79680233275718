import { CohortEnumSchema } from '@eigtech/auth0-types';
import z from 'zod';
/** Add cohort to note */
export const AddCohortToNoteBodyRequestSchema = z.object({
    cohort: CohortEnumSchema,
});
export const AddCohortToNotePathRequestSchema = z.object({
    noteId: z.string(),
});
export const AddCohortToNoteRequestSchema = AddCohortToNoteBodyRequestSchema.merge(AddCohortToNotePathRequestSchema);
