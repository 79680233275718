import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { JobIdSchema } from '../../base/ids.js';
export const RecordJobStartedRequestBodySchema = z.object({
    startedNotes: z.string().optional(),
    startedDate: z.string().datetime(),
});
export const RecordJobStartedRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const RecordJobStartedRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
});
export const RecordJobStartedRequestSchema = RecordJobStartedRequestBodySchema.merge(RecordJobStartedRequestHeadersSchema).merge(RecordJobStartedRequestPathSchema);
export const RecordJobStartedResponseSchema = z.void();
export const RecordJobStartedApiSchema = {
    body: RecordJobStartedRequestBodySchema,
    headers: RecordJobStartedRequestHeadersSchema,
    params: RecordJobStartedRequestPathSchema,
};
