import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { BaseClaimSchema, BaseLossDetailsSchema, ClaimPrecursorSchema, LocationSchema, } from '../model/index.js';
export const CreateClaimBodyRequestSchema = ClaimPrecursorSchema.omit({ dataSource: true }).merge(z.object({
    carrier: z.string(),
    lossDetails: BaseLossDetailsSchema.required({ dateOfLoss: true }),
    lossLocation: LocationSchema,
}));
export const CreateClaimHeaderRequestSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
}).required({ requestActor: true });
export const CreateClaimInternalRequestSchema = CreateClaimBodyRequestSchema;
export const CreateClaimRequestSchema = CreateClaimBodyRequestSchema.merge(CreateClaimHeaderRequestSchema);
export const CreateClaimRequestInputSchema = BaseClaimSchema;
export const CreateClaimResponseSchema = BaseClaimSchema;
export const CreateClaimApiSchema = {
    body: CreateClaimBodyRequestSchema,
    headers: CreateClaimHeaderRequestSchema,
};
