import z from 'zod';
import { SuccessApiOpDetailsSchema } from './base/auth0Events/SuccessApiOperationDetails.js';
// description: "Update a role"
// path /api/v2/roles/rol_jf7f2mXdWfM7Xt10
export const RoleUpdatedBodySchema = SuccessApiOpDetailsSchema.extend({
    request: SuccessApiOpDetailsSchema.shape.request.extend({
        method: z.literal('patch'),
        query: z.object({}),
        body: z.object({
            name: z.string(),
            description: z.string(),
        }),
    }),
    response: SuccessApiOpDetailsSchema.shape.response.extend({
        body: z.object({
            id: z.string(),
            name: z.string(),
            description: z.string(),
        }),
    }),
});
export const RoleUpdatedPathSchema = z.object({
    roleId: z.string(),
});
export const RoleUpdatedRequestSchema = RoleUpdatedBodySchema.merge(RoleUpdatedPathSchema);
