import z from 'zod';
import { AssignmentCanonSchema } from '../entities/assignment.js';
export const GetAssignmentsForAssigneeApiSchema = {
    params: z.object({
        assigneeId: z.string(),
    }),
    response: AssignmentCanonSchema.array(),
};
export const GetAssignmentsForAssigneePathRequestSchema = GetAssignmentsForAssigneeApiSchema.params;
export const GetAssignmentsForAssigneeRequestSchema = GetAssignmentsForAssigneePathRequestSchema;
export const GetAssignmentsForAssigneeResponseSchema = GetAssignmentsForAssigneeApiSchema.response;
