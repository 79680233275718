import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { CornSchema } from '@eigtech/shared-corn';
import { z } from 'zod';
import { DocumentPublicationRecordSchema } from '../aggregates/index.js';
// get document Publications
export const GetDocumentPublicationsApiSchema = {
    headers: ApiLambdalerAuthHeaderSchema,
    params: z.object({
        entityId: CornSchema,
        documentCorn: z.string(),
    }),
    response: DocumentPublicationRecordSchema.omit({
        PK: true,
        SK: true,
    }).array(),
};
export const GetDocumentPublicationsRequestSchema = GetDocumentPublicationsApiSchema.params.merge(GetDocumentPublicationsApiSchema.headers);
