import z from 'zod';
const PromptBaseSchema = z.object({
    name: z.string(),
    completedAt: z.number(),
    elapsedTime: z.number().nullable(),
});
const Stats = z.object({
    loginsCount: z.number().optional(),
});
const PromptAuthenticateSchema = PromptBaseSchema.merge(z.object({
    name: z.literal('prompt-authenticate'),
    connection: z.string(),
    connection_id: z.string(),
    strategy: z.enum(['auth0']),
    identity: z.string(),
    stats: Stats.optional(),
}));
const PromptUniversalLoginSchema = PromptBaseSchema.merge(z.object({
    name: z.literal('login'),
    flow: z.string(),
    initiatedAt: z.number().optional(),
    user_id: z.string(),
    user_name: z.string(),
    timers: z.object({
        rules: z.number(),
    }),
}));
const PromptConsentSchema = PromptBaseSchema.merge(z.object({
    name: z.literal('consent'),
    flow: z.string(),
    initiatedAt: z.number().optional(),
    grantInfo: z.object({
        id: z.string(),
        audience: z.string(),
        scope: z.string(),
        expiration: z.null().or(z.string()).or(z.number()),
    }),
}));
const PromptFederatedAuthenticateSchema = PromptBaseSchema.merge(z.object({
    name: z.literal('federated-authenticate'),
    initiatedAt: z.number().optional(),
    connection: z.string(),
    connection_id: z.string(),
    strategy: z.string(),
    identity: z.string(),
    stats: Stats.optional(),
}));
export const SuccessLoginDetailsPromptsSchema = z.discriminatedUnion('name', [
    PromptAuthenticateSchema,
    PromptUniversalLoginSchema,
    PromptConsentSchema,
    PromptFederatedAuthenticateSchema,
]);
export const SuccessLoginDetailsSchema = z.object({
    prompts: z.array(SuccessLoginDetailsPromptsSchema.or(z.unknown())).optional(),
    initiatedAt: z.number().optional(),
    completedAt: z.number().optional(),
    elapsedTime: z.number().nullable(),
    session_id: z.string(),
    stats: Stats.optional(),
});
