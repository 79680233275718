import { z } from 'zod';
import { MeetingTranscriptCreatedSchema } from '../base/meetingTranscriptCreated.js';
import { MeetingTranscriptIndexCreatedSchema } from '../base/meetingTranscriptIndexCreated.js';
import { MeetingsServiceEventBaseSchema, serviceEventType } from './service.js';
export const transcriptEventType = (eventName) => serviceEventType('transcript', eventName);
export const MeetingTranscriptCreatedEventSchema = MeetingsServiceEventBaseSchema.merge(z.object({
    type: transcriptEventType('created'),
    meeting: MeetingTranscriptCreatedSchema,
}));
export const MeetingTranscriptIndexCreatedEventSchema = MeetingsServiceEventBaseSchema.merge(z.object({
    type: transcriptEventType('indexCreated'),
    index: MeetingTranscriptIndexCreatedSchema,
}));
