import { ContactCornSchema, ContactSchema } from '@eigtech/contacts-types';
import { JobCornSchema, JobIdSchema, JobSchema } from '@eigtech/restoration-types';
import { CurrentClaimCornSchema } from '@eigtech/shared-corn-helper';
import { VendorRatesCornSchema } from '@eigtech/tarpulator-types';
import z from 'zod';
// #region Enums
export const AssignableTypeEnumSchema = z.enum(['claim', 'job', 'vendorRates']);
export const AssignableTypeEnum = AssignableTypeEnumSchema.Enum;
export const AssigneeTypeEnumSchema = z.enum(['contact', 'job']);
export const AssigneeTypeEnum = AssigneeTypeEnumSchema.Enum;
export const DeprecatedClaimAssigneeRelationshipSchema = z.enum(['fileReviewer']);
export const ClaimAssigneeRelationshipProducerSchema = z.enum([
    'carrier',
    'coordinator',
    'fieldAdjuster',
    'inspectionTechnician',
    'job',
    'legalRep',
    'policyHolder',
    'primaryContact',
    'projectManager',
    'reviewer',
]);
export const ClaimAssigneeRelationshipLaxSchema = z.enum([
    ...ClaimAssigneeRelationshipProducerSchema.options,
    ...DeprecatedClaimAssigneeRelationshipSchema.options,
]);
export const ClaimAssigneeRelationshipCanonSchema = z
    .enum([...ClaimAssigneeRelationshipLaxSchema.options])
    .transform((relationship) => {
    switch (relationship) {
        case 'fileReviewer':
            return ClaimAssigneeRelationshipProducerSchema.Enum.reviewer;
        default:
            return relationship;
    }
});
export const ClaimAssigneeRelationshipCanonEnum = ClaimAssigneeRelationshipProducerSchema.Enum;
export const ClaimAssigneeRelationshipLaxEnum = ClaimAssigneeRelationshipLaxSchema.Enum;
export const JobAssigneeRelationshipEnumSchema = z.enum([
    'approver',
    'coordinator',
    'dispatcher',
    'fieldTechnician',
    'primaryContact',
    'projectManager',
    'subcontractor',
]);
export const JobAssigneeRelationshipEnum = JobAssigneeRelationshipEnumSchema.Enum;
export const VendorRatesAssigneeRelationshipEnumSchema = z.enum(['vendor']);
export const VendorRatesAssigneeRelationshipEnum = VendorRatesAssigneeRelationshipEnumSchema.Enum;
// #endregion
// #region Assignees
export const ContactAssigneeSchema = z.object({
    type: z.literal(AssigneeTypeEnum.contact),
    assigneeId: ContactCornSchema,
    contact: ContactSchema,
});
export const JobAssigneeSchema = z.object({
    type: z.literal(AssigneeTypeEnum.job),
    assigneeId: JobCornSchema,
    job: JobSchema.and(z.object({ isClosed: z.boolean().optional() })),
});
export const AssigneeSchema = z.discriminatedUnion('type', [
    ContactAssigneeSchema,
    JobAssigneeSchema,
]);
// #endregion
// #region Assignable Entities
export const ClaimAssignableLaxSchema = z.object({
    type: z.literal(AssignableTypeEnum.claim),
    assigneeRelationship: ClaimAssigneeRelationshipLaxSchema,
    assignableId: CurrentClaimCornSchema,
    claim: z.object({
        claimNumber: CurrentClaimCornSchema,
    }),
});
export const ClaimAssignableCanonSchema = z.object({
    type: z.literal(AssignableTypeEnum.claim),
    assigneeRelationship: ClaimAssigneeRelationshipCanonSchema,
    assignableId: CurrentClaimCornSchema,
    claim: z.object({
        claimNumber: CurrentClaimCornSchema,
    }),
});
export const JobAssignableSchema = z.object({
    type: z.literal(AssignableTypeEnum.job),
    assigneeRelationship: JobAssigneeRelationshipEnumSchema,
    assignableId: JobCornSchema,
    job: z.object({
        jobId: JobIdSchema,
    }),
});
export const VendorRatesAssignableSchema = z.object({
    type: z.literal(AssignableTypeEnum.vendorRates),
    assigneeRelationship: VendorRatesAssigneeRelationshipEnumSchema,
    assignableId: VendorRatesCornSchema,
    vendorRates: z.object({
        vendorRatesId: VendorRatesCornSchema,
    }),
});
export const AssignableLaxSchema = z.discriminatedUnion('type', [
    ClaimAssignableLaxSchema,
    JobAssignableSchema,
    VendorRatesAssignableSchema,
]);
export const AssignableCanonSchema = z.discriminatedUnion('type', [
    ClaimAssignableCanonSchema,
    JobAssignableSchema,
    VendorRatesAssignableSchema,
]);
export const AssigneeRelationshipCanonSchema = z.union([
    ClaimAssigneeRelationshipCanonSchema,
    JobAssigneeRelationshipEnumSchema,
    VendorRatesAssigneeRelationshipEnumSchema,
]);
// #region Assignments
export const AssignmentCanonSchema = z.object({
    assignable: AssignableCanonSchema,
    assignee: AssigneeSchema,
    requestActor: z.string(),
    assignmentDate: z.string().datetime(),
});
export const AssignmentLaxSchema = z.object({
    assignable: AssignableLaxSchema,
    assignee: AssigneeSchema,
    requestActor: z.string(),
    assignmentDate: z.string().datetime(),
});
export const DatabaseAssignmentSchema = AssignmentCanonSchema.merge(z.object({
    PK: z.string(),
    SK: z.string(),
    'GSI-PK-1': z.string(),
    'GSI-SK-1': z.string(),
}));
// #endregion
