import z from 'zod';
import { CohortEnumSchema } from '@eigtech/auth0-types';
/** Remove cohort from note */
export const RemoveCohortFromNoteBodyRequestSchema = z.object({
    cohort: CohortEnumSchema,
});
export const RemoveCohortFromNotePathRequestSchema = z.object({
    noteId: z.string(),
});
export const RemoveCohortFromNoteRequestSchema = RemoveCohortFromNoteBodyRequestSchema.merge(RemoveCohortFromNotePathRequestSchema);
