import type { AppMetadata, User, UserMetadata } from 'auth0'
import z from 'zod'

// all codes and descriptions: https://auth0.com/docs/deploy-monitor/logs/log-event-type-codes
export const TypeCodeSchema = z.enum([
  'admin_update_launch',
  'api_limit',
  'cls',
  'cs',
  'depnote',
  'du',
  'f',
  'fapi',
  'fc',
  'fce',
  'fco',
  'fcoa',
  'fcp',
  'fcph',
  'fcpn',
  'fcpr',
  'fcpro',
  'fcu',
  'fd',
  'fdeac',
  'fdeaz',
  'fdecc',
  'fdu',
  'feacft',
  'feccft',
  'fede',
  'fens',
  'feoobft',
  'feotpft',
  'fepft',
  'fepotpft',
  'fercft',
  'fertft',
  'ferrt',
  'fi',
  'flo',
  'fn',
  'fp',
  'fs',
  'fsa',
  'fu',
  'fui',
  'fv',
  'fvr',
  'gd_auth_failed',
  'gd_auth_rejected',
  'gd_auth_succeed',
  'gd_enrollment_complete',
  'gd_otp_rate_limit_exceed',
  'gd_recovery_failed',
  'gd_recovery_rate_limit_exceed',
  'gd_recovery_succeed',
  'gd_send_pn',
  'gd_send_sms',
  'gd_send_sms_failure',
  'gd_send_voice',
  'gd_send_voice_failure',
  'gd_start_auth',
  'gd_start_enroll',
  'gd_tenant_update',
  'gd_unenroll',
  'gd_update_device_account',
  'limit_delegation',
  'limit_mu',
  'limit_wc',
  'limit_sul',
  'mfar',
  'mgmt_api_read',
  'pla',
  'pwd_leak',
  's',
  'sapi',
  'sce',
  'scoa',
  'scp',
  'scph',
  'scpn',
  'scpr',
  'scu',
  'sd',
  'sdu',
  'seacft',
  'seccft',
  'sede',
  'sens',
  'seoobft',
  'seotpft',
  'sepft',
  'sercft',
  'sertft',
  'si',
  'srrt',
  'slo',
  'ss',
  'ssa',
  'sui',
  'sv',
  'svr',
  'sys_os_update_end',
  'sys_os_update_start',
  'sys_update_end',
  'sys_update_start',
  'ublkdu',
  'w',
])

export const TypeCodeMapSchema = z.object({
  SuccessApiOperation: z.literal(TypeCodeSchema.Values.sapi),
  SuccessLogin: z.literal(TypeCodeSchema.Values.s),
  SuccessLogout: z.literal(TypeCodeSchema.Values.slo),
  SuccessSignup: z.literal(TypeCodeSchema.Values.ss),
})

export const LogEventSchema = z.object({
  audience: z.string().optional(),
  client_id: z.string().optional(),
  client_name: z.string().optional(),
  connection: z.string().optional(),
  connection_id: z.string().optional(),
  date: z.string().optional(),
  description: z.string().optional(),
  details: z.unknown(),
  hostname: z.string().optional(),
  ip: z.string().optional(),
  isMobile: z.boolean().optional(),
  location_info: z
    .object({
      city_name: z.string().optional(),
      continent_code: z.string().optional(),
      country_code: z.string().optional(),
      country_code3: z.string().optional(),
      country_name: z.string().optional(),
      latitude: z.string().optional(),
      longitude: z.string().optional(),
      time_zone: z.string().optional(),
    })
    .optional(),
  log_id: z.string().optional(),
  scope: z.string().optional(),
  strategy: z.string().optional(),
  strategy_type: z.string().optional(),
  type: TypeCodeSchema.optional(),
  user_agent: z.string().optional(),
  user_id: z.string().optional(),
  user_name: z.string().optional(),
})

export const Auth0EventTypeSchema = z.enum([
  'roleAssignedToOrgUser',
  'roleCreated',
  'roleDeleted',
  'roleRemovedFromOrgUser',
  'roleUpdated',
  'userAddedToOrg',
  'userCreated',
  'userDeleted',
  'userRemovedFromOrg',
  'userUpdated',
])

export const OrganizationMemberSchema = z.object({
  email: z.string().optional(),
  name: z.string().optional(),
  picture: z.string().optional(),
  user_id: z.string().optional(),
})

export const Auth0UserSchema = z.object({
  _id: z.string().optional(),
  user_id: z.string().optional(),
  email: z.string().optional(),
  email_verified: z.boolean().optional(),
  username: z.string().optional(),
  phone_number: z.string().optional(),
  phone_verified: z.boolean().optional(),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  identities: z
    .array(
      z.object({
        access_token: z.string().optional(),
        refresh_token: z.string().optional(),
        connection: z.string(),
        user_id: z.string(),
        provider: z.string(),
        isSocial: z.boolean(),
        profileData: z
          .object({
            email: z.string().optional(),
            email_verified: z.boolean().optional(),
            name: z.string().optional(),
            phone_number: z.string().optional(),
            phone_verified: z.boolean().optional(),
            request_language: z.string().optional(),
          })
          .optional(),
      })
    )
    .optional(),
  app_metadata: z.record(z.string(), z.unknown()).optional(),
  user_metadata: z.record(z.string(), z.unknown()).optional(),
  picture: z.string().optional(),
  name: z.string().optional(),
  nickname: z.string().optional(),
  multifactor: z.string().array().optional(),
  last_ip: z.string().optional(),
  last_login: z.string().optional(),
  logins_count: z.number().optional(),
  blocked: z.boolean().optional(),
  given_name: z.string().optional(),
  family_name: z.string().optional(),
}) satisfies z.ZodType<User<AppMetadata, UserMetadata>>

export const RoleSchema = z.object({
  id: z.string().optional(),
  name: z.string().optional(),
  description: z.string().optional(),
})

export const ConnectionSchema = z.object({
  id: z.string(),
  strategy: z.string().optional(),
  name: z.string(),
  enabled_clients: z.array(z.string()).optional(),
  realms: z.array(z.string()).optional(),
})

export type OrganizationMember = z.infer<typeof OrganizationMemberSchema>

export type ApiLevel1 = 'organizations' | 'roles' | 'users'
export type TypeCodeMap = z.infer<typeof TypeCodeMapSchema>
export type Auth0EventType = z.infer<typeof Auth0EventTypeSchema>
export type Auth0User = z.infer<typeof Auth0UserSchema>
export type Auth0Role = z.infer<typeof RoleSchema>
export type Auth0Connection = z.infer<typeof ConnectionSchema>

// POST /api/v2/organizations/org_Miqv9tEm8irQwL17/members/auth0%7C63377b17eb80942f6f0b4b3a/roles
// POST /api/v2/roles
// DELETE /api/v2/roles/rol_jf7f2mXdWfM7Xt10
// DELETE /api/v2/organizations/org_Miqv9tEm8irQwL17/members/auth0%7C63377b17eb80942f6f0b4b3a/roles
// PATCH /api/v2/roles/rol_jf7f2mXdWfM7Xt10
// POST /api/v2/organizations/org_Miqv9tEm8irQwL17/members
// DELETE /api/v2/organizations/org_Miqv9tEm8irQwL17/members
// PATCH /api/v2/users/auth0%7C63377dca5965a4fa66570452
