import { ContactCornSchema } from '@eigtech/contacts-types';
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { AppointmentChannelSchema, AppointmentTypeSchema } from '../../base/appointment.js';
import { JobAppointmentIdSchema, JobIdSchema } from '../../base/ids.js';
export const RecordAppointmentScheduledRequestBodySchema = z.object({
    appointmentChannel: AppointmentChannelSchema,
    appointmentDate: z.string().datetime(),
    appointmentDescription: z.string().optional(),
    appointmentType: AppointmentTypeSchema,
    participants: z.array(ContactCornSchema.or(z.string())),
});
export const RecordAppointmentScheduledRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
});
export const RecordAppointmentScheduledRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const RecordAppointmentScheduledRequestSchema = RecordAppointmentScheduledRequestBodySchema.merge(RecordAppointmentScheduledRequestHeadersSchema).merge(RecordAppointmentScheduledRequestPathSchema);
export const RecordAppointmentScheduledResponseSchema = z.object({
    jobId: JobIdSchema,
    appointmentId: JobAppointmentIdSchema,
    appointmentChannel: AppointmentChannelSchema,
    appointmentDate: z.string().datetime(),
    appointmentDescription: z.string().optional(),
    appointmentType: AppointmentTypeSchema,
    participants: z.array(ContactCornSchema.or(z.string())),
});
export const RecordAppointmentScheduledApiSchema = {
    body: RecordAppointmentScheduledRequestBodySchema,
    headers: RecordAppointmentScheduledRequestHeadersSchema,
    params: RecordAppointmentScheduledRequestPathSchema,
    response: RecordAppointmentScheduledResponseSchema,
};
