import z from 'zod';
import { AppointmentChannelSchema, BaseJobSchema, CarrierInvoiceSchema, CarrierPaymentSchema, CustomerContactAttemptSchema, CustomerContactOutcomeSchema, DocumentTransitMethodSchema, JobAppointmentSchema, JobBillCollectionSchema, JobDepositOverrideApprovalSchema, JobDepositPaymentSchema, JobLocationSchema, JobSchema, JobServiceSchema, JobStatus, JobTypeSchema, SubcontractorDocumentKindSchema, SubcontractorIssuedDocumentSchema, SubcontractorPaymentSchema, SubcontractorReceivedDocumentSchema, SubcontractorSchema, WorkAuthorizationIdSchema, WorkAuthorizationRecipientSchema, WorkAuthorizationStatus, } from '../base/index.js';
import { WorkDetailsSchema } from '../base/workDetails.js';
import { RestorationServiceEventBaseSchema, restorationServiceEventType } from './service.js';
export const jobEventType = (eventName) => restorationServiceEventType('job', eventName);
export const JobEventTypeSchema = z.enum([
    'appointmentCompleted',
    'appointmentScheduled',
    'billSentToCollections',
    'cancelled',
    'carrierInvoiced',
    'carrierMadePayment',
    'completed',
    'customerContactAttempted',
    'depositOverrideApproved',
    'depositPaid',
    'descriptionUpdated',
    'locationUpdated',
    'received',
    'reopened',
    'servicesAdded',
    'servicesRemoved',
    'started',
    'subcontractorPaid',
    'subdocumentIssued',
    'subdocumentReceived',
    'withdrawn',
    'workAuthorizationCompleted',
    'workAuthorizationDeclined',
    'workAuthorizationExpired',
    'workAuthorizationOverrideApproved',
    'workAuthorizationReassigned',
    'workAuthorizationRecalled',
    'workAuthorizationRecipientSigned',
    'workAuthorizationSent',
    'workDetailsReceived',
]);
export const JobEventType = JobEventTypeSchema.Enum;
export const JobEventSchema = RestorationServiceEventBaseSchema.merge(z.object({
    type: jobEventType(),
    job: JobSchema.and(z.unknown()),
    metadata: RestorationServiceEventBaseSchema.shape.metadata.and(z.object({
        jobType: JobTypeSchema,
        requestActor: z.string(),
    })),
}));
export const isJobEvent = (event) => JobEventSchema.safeParse(event).success;
// #endregion
// #region JobReceivedEvent
export const JobReceivedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.received),
    job: JobSchema,
}));
export const isJobReceivedEvent = (event) => JobReceivedEventSchema.safeParse(event).success;
// #endregion
// #region JobStartedEventDetails
export const JobStartedEventDetailsSchema = z.object({
    notes: z.string().optional(),
    occurredAt: z.string().datetime(),
});
export const JobStartedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.started),
    job: JobSchema.and(JobStartedEventDetailsSchema).and(z.object({
        status: z.literal(JobStatus.created),
    })),
}));
export const isJobStartedEvent = (event) => JobStartedEventSchema.safeParse(event).success;
// #endregion
// #region CustomerContactAttemptedEventDetails
export const CustomerContactAttemptedEventDetailsSchema = CustomerContactAttemptSchema;
export const CustomerContactAttemptedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.customerContactAttempted),
    metadata: JobEventSchema.shape.metadata.and(z.object({
        contactOutcome: CustomerContactOutcomeSchema,
    })),
    job: JobSchema.and(CustomerContactAttemptedEventDetailsSchema),
}));
export const isCustomerContactAttemptedEvent = (event) => CustomerContactAttemptedEventSchema.safeParse(event).success;
// #endregion
// #region JobAppointmentScheduledEventDetails
export const JobAppointmentScheduledEventDetailsSchema = JobAppointmentSchema.omit({
    appointmentNotes: true,
});
export const JobAppointmentScheduledEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.appointmentScheduled),
    metadata: JobEventSchema.shape.metadata.and(z.object({
        appointmentChannel: AppointmentChannelSchema,
    })),
    job: JobSchema.and(JobAppointmentScheduledEventDetailsSchema).and(z.object({
        status: z.literal(JobStatus.scheduled),
    })),
}));
export const isJobAppointmentScheduledEvent = (event) => JobAppointmentScheduledEventSchema.safeParse(event).success;
// #endregion
// #region JobAppointmentCompletedEventDetails
export const JobAppointmentCompletedEventDetailsSchema = JobAppointmentScheduledEventDetailsSchema.and(z.object({
    appointmentId: z.string().optional(),
    appointmentNotes: z.string().optional(),
}));
export const JobAppointmentCompletedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.appointmentCompleted),
    metadata: JobAppointmentScheduledEventSchema.shape.metadata,
    job: JobAppointmentScheduledEventSchema.shape.job.and(JobAppointmentCompletedEventDetailsSchema),
}));
export const isJobAppointmentCompletedEvent = (event) => JobAppointmentCompletedEventSchema.safeParse(event).success;
// #endregion
// #region JobSubcontractorDocumentIssuedEventDetails
export const JobSubcontractorDocumentIssuedEventDetailsSchema = z.object({
    subcontractorDocument: SubcontractorIssuedDocumentSchema,
    subcontractor: SubcontractorSchema,
});
export const JobSubcontractorDocumentIssuedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.subdocumentIssued),
    metadata: JobEventSchema.shape.metadata.and(z.object({
        documentKind: SubcontractorDocumentKindSchema.or(z.string()),
        documentIssuedMethod: DocumentTransitMethodSchema.or(z.string()),
    })),
    job: JobSchema.and(JobSubcontractorDocumentIssuedEventDetailsSchema),
}));
export const isJobSubcontractorDocumentIssuedEvent = (event) => JobSubcontractorDocumentIssuedEventSchema.safeParse(event).success;
// #endregion
// #region JobSubcontractorDocumentReceivedEventDetails
export const JobSubcontractorDocumentReceivedEventDetailsSchema = z.object({
    subcontractorDocument: SubcontractorReceivedDocumentSchema,
    subcontractor: SubcontractorSchema,
});
export const JobSubcontractorDocumentReceivedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.subdocumentReceived),
    metadata: JobEventSchema.shape.metadata.and(z.object({
        documentKind: SubcontractorDocumentKindSchema.or(z.string()),
        documentReceivedMethod: DocumentTransitMethodSchema.or(z.string()),
    })),
    job: JobSchema.and(JobSubcontractorDocumentReceivedEventDetailsSchema),
}));
export const isJobSubcontractorDocumentReceivedEvent = (event) => JobSubcontractorDocumentReceivedEventSchema.safeParse(event).success;
// #endregion
// #region JobWorkAuthorizationSentEventDetails
export const JobWorkAuthorizationSentEventDetailsSchema = z.object({
    workAuthorization: z.object({
        sentDate: z.string().datetime(),
        recipient: WorkAuthorizationRecipientSchema,
        sentMethod: DocumentTransitMethodSchema,
        status: z.literal(WorkAuthorizationStatus.sent),
        workAuthorizationId: WorkAuthorizationIdSchema,
    }),
});
export const JobWorkAuthorizationSentEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.workAuthorizationSent),
    metadata: JobEventSchema.shape.metadata.and(z.object({
        documentTransitMethod: DocumentTransitMethodSchema.or(z.string()),
    })),
    job: JobSchema.and(JobWorkAuthorizationSentEventDetailsSchema),
}));
export const isJobWorkAuthorizationSentEvent = (event) => JobWorkAuthorizationSentEventSchema.safeParse(event).success;
// #endregion
// #region JobWorkAuthorizationCompletedEventDetails
export const JobWorkAuthorizationCompletedEventDetailsSchema = z.object({
    workAuthorization: z.object({
        approvedBy: WorkAuthorizationRecipientSchema,
        dateClosed: z.string().datetime(),
        effectiveDate: z.string().datetime(),
        signer: WorkAuthorizationRecipientSchema,
        status: z.literal(WorkAuthorizationStatus.completed),
        workAuthorizationId: WorkAuthorizationIdSchema,
    }),
});
export const JobWorkAuthorizationCompletedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.workAuthorizationCompleted),
    metadata: JobEventSchema.shape.metadata.and(z.object({
        documentTransitMethod: DocumentTransitMethodSchema.or(z.string()),
    })),
    job: JobSchema.and(JobWorkAuthorizationCompletedEventDetailsSchema),
}));
export const isJobWorkAuthorizationCompletedEvent = (event) => JobWorkAuthorizationCompletedEventSchema.safeParse(event).success;
// #endregion
//#region RecipientSigned
export const JobWorkAuthorizationRecipientSignedEventDetailsSchema = z.object({
    workAuthorization: z.object({
        signedOn: z.string().datetime(),
        workAuthorizationId: WorkAuthorizationIdSchema,
        status: z.literal(WorkAuthorizationStatus.signed),
        signer: WorkAuthorizationRecipientSchema,
    }),
});
export const JobWorkAuthorizationRecipientSignedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.workAuthorizationRecipientSigned),
    job: JobSchema.and(JobWorkAuthorizationRecipientSignedEventDetailsSchema),
}));
export const isJobWorkAuthorizationRecipientSignedEvent = (event) => JobWorkAuthorizationRecipientSignedEventSchema.safeParse(event).success;
// #endregion
// #region JobWorkAuthorizationRecalledEventDetails
export const JobWorkAuthorizationRecalledEventDetailsSchema = z.object({
    workAuthorization: z.object({
        dateClosed: z.string().datetime(),
        status: z.literal(WorkAuthorizationStatus.recalled),
        workAuthorizationId: WorkAuthorizationIdSchema,
    }),
});
export const JobWorkAuthorizationRecalledEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.workAuthorizationRecalled),
    metadata: JobEventSchema.shape.metadata.and(z.object({
        documentTransitMethod: DocumentTransitMethodSchema.or(z.string()),
    })),
    job: JobSchema.and(JobWorkAuthorizationRecalledEventDetailsSchema),
}));
export const isJobWorkAuthorizationRecalledEvent = (event) => JobWorkAuthorizationRecalledEventSchema.safeParse(event).success;
// #endregion
// #region JobWorkAuthorizationExpiredEventDetails
export const JobWorkAuthorizationExpiredEventDetailsSchema = z.object({
    workAuthorization: z.object({
        dateClosed: z.string().datetime(),
        status: z.literal(WorkAuthorizationStatus.expired),
        workAuthorizationId: WorkAuthorizationIdSchema,
    }),
});
export const JobWorkAuthorizationExpiredEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.workAuthorizationExpired),
    metadata: JobEventSchema.shape.metadata.and(z.object({
        documentTransitMethod: DocumentTransitMethodSchema.or(z.string()),
    })),
    job: JobSchema.and(JobWorkAuthorizationExpiredEventDetailsSchema),
}));
export const isJobWorkAuthorizationExpiredEvent = (event) => JobWorkAuthorizationExpiredEventSchema.safeParse(event).success;
// #endregion
// #region JobWorkAuthorizationDeclinedEventDetails
export const JobWorkAuthorizationDeclinedEventDetailsSchema = z.object({
    workAuthorization: z.object({
        dateClosed: z.string().datetime(),
        status: z.literal(WorkAuthorizationStatus.declined),
        workAuthorizationId: WorkAuthorizationIdSchema,
    }),
});
export const JobWorkAuthorizationDeclinedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.workAuthorizationDeclined),
    metadata: JobEventSchema.shape.metadata.and(z.object({
        documentTransitMethod: DocumentTransitMethodSchema.or(z.string()),
    })),
    job: JobSchema.and(JobWorkAuthorizationDeclinedEventDetailsSchema),
}));
export const isJobWorkAuthorizationDeclinedEvent = (event) => JobWorkAuthorizationDeclinedEventSchema.safeParse(event).success;
// #endregion
// #region JobWorkAuthorizationReassignedEventDetails
export const JobWorkAuthorizationReassignedEventDetailsSchema = z.object({
    workAuthorization: z.object({
        dateReassigned: z.string().datetime(),
        recipient: WorkAuthorizationRecipientSchema,
        workAuthorizationId: WorkAuthorizationIdSchema,
    }),
});
export const JobWorkAuthorizationReassignedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.workAuthorizationReassigned),
    metadata: JobEventSchema.shape.metadata.and(z.object({
        documentTransitMethod: DocumentTransitMethodSchema.or(z.string()),
    })),
    job: JobSchema.and(JobWorkAuthorizationReassignedEventDetailsSchema),
}));
export const isJobWorkAuthorizationReassignedEvent = (event) => JobWorkAuthorizationReassignedEventSchema.safeParse(event).success;
// #endregion
// #region JobWorkAuthorizationOverrideApprovedEventDetails
export const JobWorkAuthorizationOverrideApprovedEventDetailsSchema = z.object({
    workAuthorization: z.object({
        effectiveDate: z.string().datetime(),
        approvedBy: WorkAuthorizationRecipientSchema,
        status: z.literal(WorkAuthorizationStatus.override_approved),
        workAuthorizationId: WorkAuthorizationIdSchema,
    }),
});
export const JobWorkAuthorizationOverrideApprovedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.workAuthorizationOverrideApproved),
    job: JobSchema.and(JobWorkAuthorizationOverrideApprovedEventDetailsSchema),
}));
export const isJobWorkAuthorizationOverrideApprovedEvent = (event) => JobWorkAuthorizationOverrideApprovedEventSchema.safeParse(event).success;
// #endregion
// #region JobCarrierInvoicedEventDetails
export const JobCarrierInvoicedEventDetailsSchema = z.object({
    carrierInvoice: CarrierInvoiceSchema,
});
export const JobCarrierInvoicedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.carrierInvoiced),
    job: JobSchema.and(JobCarrierInvoicedEventDetailsSchema).and(z.object({
        status: z.literal(JobStatus.paymentPending),
    })),
}));
export const isJobCarrierInvoicedEvent = (event) => JobCarrierInvoicedEventSchema.safeParse(event).success;
// #endregion
// #region JobCarrierMadePaymentEventDetails
export const JobCarrierMadePaymentEventDetailsSchema = z.object({
    carrierPayment: CarrierPaymentSchema,
});
export const JobCarrierMadePaymentEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.carrierMadePayment),
    job: JobSchema.and(JobCarrierMadePaymentEventDetailsSchema).and(z.object({
        status: z.literal(JobStatus.paymentReceived),
    })),
}));
export const isJobCarrierMadePaymentEvent = (event) => JobCarrierMadePaymentEventSchema.safeParse(event).success;
// #endregion
// #region JobBillSentToCollectionsEventDetails
export const JobBillSentToCollectionsEventDetailsSchema = z.object({
    billCollection: JobBillCollectionSchema,
});
export const JobBillSentToCollectionsEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.billSentToCollections),
    job: JobSchema.and(JobBillSentToCollectionsEventDetailsSchema),
}));
// #endregion
// #region JobSubcontractorPaidEventDetails
export const JobSubcontractorPaidEventDetailsSchema = z.object({
    subcontractorPayment: SubcontractorPaymentSchema,
    subcontractor: SubcontractorSchema,
});
export const JobSubcontractorPaidEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.subcontractorPaid),
    job: JobSchema.and(JobSubcontractorPaidEventDetailsSchema),
}));
export const isJobSubcontractorPaidEvent = (event) => JobSubcontractorPaidEventSchema.safeParse(event).success;
// #endregion
// #region JobLocationUpdatedEventDetails
export const JobLocationUpdatedEventDetailsSchema = z.object({
    previousLocation: JobLocationSchema,
});
export const JobLocationUpdatedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.locationUpdated),
    job: JobSchema.and(JobLocationUpdatedEventDetailsSchema),
}));
// #endregion
// #region JobDescriptionUpdatedEventDetails
export const JobDescriptionUpdatedEventDetailsSchema = z.object({
    previousDescription: BaseJobSchema.shape.jobDescription,
});
export const JobDescriptionUpdatedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.descriptionUpdated),
    job: JobSchema.and(JobDescriptionUpdatedEventDetailsSchema),
}));
// #endregion
// #region JobCompletedEventDetails
export const JobCompletedEventDetailsSchema = z.object({
    notes: z.string().optional(),
    occurredAt: z.string().datetime(),
});
export const JobCompletedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.completed),
    job: JobSchema.and(JobCompletedEventDetailsSchema).and(z.object({
        status: z.literal(JobStatus.closed),
    })),
}));
// #endregion
// #region JobWithdrawnEventDetails
export const JobWithdrawnEventDetailsSchema = z.object({
    notes: z.string().optional(),
    occurredAt: z.string().datetime(),
});
export const JobWithdrawnEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.withdrawn),
    job: JobSchema.and(JobWithdrawnEventDetailsSchema).and(z.object({
        status: z.literal(JobStatus.closed),
    })),
}));
// #endregion
// #region JobReopenedEventDetails
export const JobReopenedEventDetailsSchema = z.object({
    notes: z.string().optional(),
    occurredAt: z.string().datetime(),
});
export const JobReopenedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.reopened),
    job: JobSchema.and(JobReopenedEventDetailsSchema).and(z.object({
        status: z.literal(JobStatus.reopened),
    })),
}));
// #endregion
// #region JobCancelledEventDetails
export const JobCancelledEventDetailsSchema = z.object({
    notes: z.string().optional(),
    occurredAt: z.string().datetime(),
});
export const JobCancelledEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.cancelled),
    job: JobSchema.and(JobCancelledEventDetailsSchema).and(z.object({
        status: z.literal(JobStatus.closed),
    })),
}));
export const isJobCancelledEvent = (event) => JobCancelledEventSchema.safeParse(event).success;
// #endregion
// #region JobDepositPaidEventDetails
export const JobDepositPaidEventDetailsSchema = JobDepositPaymentSchema;
export const JobDepositPaidEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.depositPaid),
    metadata: JobEventSchema.shape.metadata.and(z.object({
        paidInFull: z.boolean(),
    })),
    job: JobSchema.and(JobDepositPaidEventDetailsSchema),
}));
export const isJobDepositPaidEvent = (event) => JobDepositPaidEventSchema.safeParse(event).success;
// #endregion
// #region JobDepositOverrideApprovedEventDetails
export const JobDepositOverrideApprovedEventDetailsSchema = JobDepositOverrideApprovalSchema;
export const JobDepositOverrideApprovedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.depositOverrideApproved),
    job: JobSchema.and(JobDepositOverrideApprovedEventDetailsSchema),
}));
// #endregion
// #region JobServicesAddedEventDetails
export const JobServicesAddedEventDetailsSchema = z.object({
    servicesAdded: JobServiceSchema.array(),
});
export const JobServicesAddedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.servicesAdded),
    job: JobSchema.and(JobServicesAddedEventDetailsSchema),
}));
export const isJobServicesAddedEvent = (event) => JobServicesAddedEventSchema.safeParse(event).success;
// #endregion
// #region JobServicesRemovedEventDetails
export const JobServicesRemovedEventDetailsSchema = z.object({
    servicesRemoved: JobServiceSchema.array(),
});
export const JobServicesRemovedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.servicesRemoved),
    job: JobSchema.and(JobServicesRemovedEventDetailsSchema),
}));
export const isJobServicesRemovedEvent = (event) => JobServicesRemovedEventSchema.safeParse(event).success;
// #endregion
// #region JobWorkDetailsReceived
export const JobWorkDetailsReceivedEventDetailsSchema = z.object({
    workDetails: WorkDetailsSchema,
    status: z.literal(JobStatus.readyForPayment),
});
export const JobWorkDetailsReceivedEventSchema = JobEventSchema.merge(z.object({
    type: jobEventType(JobEventType.workDetailsReceived),
    job: JobSchema.and(JobWorkDetailsReceivedEventDetailsSchema),
}));
export const isJobWorkDetailsReceivedEvent = (event) => JobWorkDetailsReceivedEventSchema.safeParse(event).success;
