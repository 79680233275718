import z from 'zod';
import { VediImportSchema } from '../vediImport.js';
export const RetryClaimImportApiSchema = {
    body: z.object({
        vediImport: VediImportSchema.passthrough(),
        overrides: z.object({
            forceSend: z.boolean(),
        }),
    }),
    params: z.object({
        claimNumber: z.string(),
    }),
    response: z.object({
        result: VediImportSchema,
    }),
};
export const RetryClaimImportRequestSchema = RetryClaimImportApiSchema.params.merge(RetryClaimImportApiSchema.body);
