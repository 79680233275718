import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { CornSchema } from '@eigtech/shared-corn';
import { z } from 'zod';
// get document url
export const GetDocumentUrlApiSchema = {
    headers: ApiLambdalerAuthHeaderSchema,
    params: z.object({
        entityId: CornSchema,
        documentCorn: z.string(),
    }),
};
export const GetDocumentUrlRequestSchema = GetDocumentUrlApiSchema.params.merge(GetDocumentUrlApiSchema.headers);
