import { MeetingEventTypeName } from '@eigtech/meetings-types';
import z from 'zod';
import { ClaimAssignmentChronicleType, ClaimMeetingChronicleType, ClaimV1ChronicleType, ClaimV2ChronicleType, EstimateChronicleType, } from './claimChronicles.js';
export const ClaimDefaultChronicleTypeSchema = z.enum([
    // #region claim v2 events
    ClaimV2ChronicleType.catCodeUpdated,
    ClaimV2ChronicleType.closed,
    ClaimV2ChronicleType.customerContactedEmail,
    ClaimV2ChronicleType.customerContactedLvm,
    ClaimV2ChronicleType.customerNoContact,
    ClaimV2ChronicleType.initialCustomerContactAttempted,
    ClaimV2ChronicleType.initialCustomerContactFailed,
    ClaimV2ChronicleType.initialCustomerContactSuccess,
    ClaimV2ChronicleType.inspectionCanceled,
    ClaimV2ChronicleType.inspectionCompleted,
    ClaimV2ChronicleType.inspectionScheduled,
    ClaimV2ChronicleType.invalidContactInformation,
    ClaimV2ChronicleType.lossOfUseAdded,
    ClaimV2ChronicleType.lossOfUseApproved,
    ClaimV2ChronicleType.lossOfUseCanceled,
    ClaimV2ChronicleType.lossOfUseDenied,
    ClaimV2ChronicleType.lossOfUsePending,
    ClaimV2ChronicleType.lossOfUseReceiptAdded,
    ClaimV2ChronicleType.lossOfUseReceiptRemoved,
    ClaimV2ChronicleType.lossSeverityUpdated,
    ClaimV2ChronicleType.lossTypeUpdated,
    ClaimV2ChronicleType.markedForPublication,
    ClaimV2ChronicleType.reInspectionRequested,
    ClaimV2ChronicleType.reopened,
    ClaimV2ChronicleType.reviewCompleted,
    ClaimV2ChronicleType.reviewRequested,
    // #endregion claim v2 events
    // #region claim v1 status events
    ClaimV1ChronicleType.canceled,
    ClaimV1ChronicleType.carrierApprovedEstimate,
    ClaimV1ChronicleType.carrierApprovedEstimateWithExceptions,
    ClaimV1ChronicleType.carrierRejectedEstimate,
    ClaimV1ChronicleType.clientApproved,
    ClaimV1ChronicleType.clientRejected,
    ClaimV1ChronicleType.customerContactedEmail,
    ClaimV1ChronicleType.customerContactedLvm,
    ClaimV1ChronicleType.customerNoContact,
    ClaimV1ChronicleType.dateReceived,
    ClaimV1ChronicleType.estimateRevisionRequested,
    ClaimV1ChronicleType.estimateSentToCarrier,
    ClaimV1ChronicleType.fieldAdjusterContactedCustomer,
    ClaimV1ChronicleType.fieldAdjusterCorrectionUploaded,
    ClaimV1ChronicleType.fieldAdjusterEstimateUploaded,
    ClaimV1ChronicleType.inAssignQueue,
    ClaimV1ChronicleType.initialCustomerContactAttempted,
    ClaimV1ChronicleType.initialCustomerContactFailed,
    ClaimV1ChronicleType.initialCustomerContactSuccess,
    ClaimV2ChronicleType.inspectionCompleted,
    ClaimV2ChronicleType.inspectionScheduled,
    ClaimV1ChronicleType.invalidContactInformation,
    ClaimV1ChronicleType.jobCompleted,
    ClaimV1ChronicleType.jobContracted,
    ClaimV1ChronicleType.jobNotSold,
    ClaimV1ChronicleType.jobSold,
    ClaimV1ChronicleType.jobStarted,
    ClaimV1ChronicleType.lossDate,
    ClaimV1ChronicleType.qaApproved,
    ClaimV1ChronicleType.qaApprovedPreliminaryReport,
    ClaimV1ChronicleType.qaApprovedSupplementReport,
    ClaimV1ChronicleType.qaRejected,
    ClaimV1ChronicleType.reInspectionRequested,
    ClaimV1ChronicleType.surveySentToCustomer,
    // #endregion claim v1 status events
    // #region claim assignment events
    ClaimAssignmentChronicleType.carrierAssignedToClaim,
    ClaimAssignmentChronicleType.carrierUnassignedFromClaim,
    ClaimAssignmentChronicleType.coordinatorAssignedToClaim,
    ClaimAssignmentChronicleType.coordinatorUnassignedFromClaim,
    ClaimAssignmentChronicleType.fieldAdjusterAssignedToClaim,
    ClaimAssignmentChronicleType.fieldAdjusterUnassignedFromClaim,
    ClaimAssignmentChronicleType.jobAssignedToClaim,
    ClaimAssignmentChronicleType.jobUnassignedFromClaim,
    ClaimAssignmentChronicleType.legalRepAssignedToClaim,
    ClaimAssignmentChronicleType.legalRepUnassignedFromClaim,
    ClaimAssignmentChronicleType.policyHolderAssignedToClaim,
    ClaimAssignmentChronicleType.policyHolderUnassignedFromClaim,
    ClaimAssignmentChronicleType.primaryContactAssignedToClaim,
    ClaimAssignmentChronicleType.primaryContactUnassignedFromClaim,
    ClaimAssignmentChronicleType.projectManagerAssignedToClaim,
    ClaimAssignmentChronicleType.projectManagerUnassignedFromClaim,
    ClaimAssignmentChronicleType.reviewerAssignedToClaim,
    ClaimAssignmentChronicleType.reviewerUnassignedFromClaim,
    ClaimAssignmentChronicleType.inspectionTechnicianAssignedToClaim,
    ClaimAssignmentChronicleType.inspectionTechnicianUnassignedFromClaim,
    // #endregion claim assignment events
    // #region estimate events
    EstimateChronicleType.carrierRejected,
    EstimateChronicleType.carrierReviewed,
    EstimateChronicleType.carrierReviewedWithExceptions,
    EstimateChronicleType.clientApproved,
    EstimateChronicleType.clientRejected,
    EstimateChronicleType.qaApproved,
    EstimateChronicleType.qaRejected,
    EstimateChronicleType.estimateReceived,
    // #endregion estimate events
    // #region meeting events
    ClaimMeetingChronicleType.claimMeetingEnded,
    // #endregion meeting events
]);
export const ClaimDefaultChronicleType = ClaimDefaultChronicleTypeSchema.Enum;
export const isClaimDefaultChronicle = (chronicle) => ClaimDefaultChronicleTypeSchema.safeParse(chronicle.type).success;
export const ClaimDefaultSourceTypeSchema = z.enum([
    ...ClaimDefaultChronicleTypeSchema.options,
    ClaimV2ChronicleType.claimCreated,
    ClaimV2ChronicleType.dateOfLossUpdated,
    ClaimV2ChronicleType.dateReceivedUpdated,
    MeetingEventTypeName.ended,
    'statusPublishConfirmed',
]);
export const isClaimDefaultSourceChronicle = (chronicle) => ClaimDefaultSourceTypeSchema.safeParse(chronicle.type).success;
