import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { JobIdSchema, SubcontractorIssuedDocumentSchema, SubcontractorSchema, } from '../../base/index.js';
export const RecordSubcontractorDocumentIssuedRequestBodySchema = z.object({
    subcontractorDocument: SubcontractorIssuedDocumentSchema.omit({ subcontractorDocumentId: true }),
    subcontractor: SubcontractorSchema,
});
export const RecordSubcontractorDocumentIssuedRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({ requestActor: true });
export const RecordSubcontractorDocumentIssuedRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const RecordSubcontractorDocumentIssuedRequestSchema = RecordSubcontractorDocumentIssuedRequestBodySchema.merge(RecordSubcontractorDocumentIssuedRequestHeadersSchema).merge(RecordSubcontractorDocumentIssuedRequestPathSchema);
export const RecordSubcontractorDocumentIssuedResponseSchema = z.object({
    jobId: JobIdSchema,
    subcontractorDocument: SubcontractorIssuedDocumentSchema,
    subcontractor: SubcontractorSchema,
});
export const RecordSubcontractorDocumentIssuedApiSchema = {
    body: RecordSubcontractorDocumentIssuedRequestBodySchema,
    headers: RecordSubcontractorDocumentIssuedRequestHeadersSchema,
    params: RecordSubcontractorDocumentIssuedRequestPathSchema,
    response: RecordSubcontractorDocumentIssuedResponseSchema,
};
