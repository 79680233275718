import z from 'zod';
import { TypeID } from '../core/index.js';
/**
 * Creates a schema that can be used for a deterministic ID type.
 * -------
 * TypeIDs are canonically encoded as lowercase strings consisting of three parts:
 * 1) A type prefix (at most 63 characters in all lowercase ASCII [a-z]).
 * 2) An underscore '_' separator.
 * 3) A 128-bit UUIDv7 encoded as a 26-character string using a modified base32 encoding.
 * ```ts
 * export const JobIdSchema = makeTypeIdSchema<'job'>()
 * export type JobId = z.infer<typeof JobIdSchema>
 *
 * const jobId1: JobId = JobIdSchema.parse('job_1') // error
 * const jobId2: JobId = JobIdSchema.parse('job_asdf') // error
 * const jobId2: JobId = JobIdSchema.parse(typeid('notajob').toString()) // error
 * const jobId2: JobId = JobIdSchema.parse(typeid('job').toString()) // good
 * ```
 *
 * @param aggregateType The aggregate type, e.g. 'job' or 'claim'
 * @param aggregateValue The aggregate value, e.g. '123' or '2023-07-13T22:11:53.310Z'
 * @returns A ZodSchema
 */
export const makeTypeIdSchema = (aggregateType) => z.custom((val) => {
    try {
        return (new RegExp(`^${aggregateType}_\\w+`, 'g').test(val) &&
            TypeID.fromString(val));
    }
    catch (err) {
        return false;
    }
}, 'invalid TypeId');
/**
 * Creates a schema that can be used for a deterministic ID type.
 * ```ts
 * export const JobIdSchema = mimicTypeIdSchema<'job'>('job')
 * export type JobId = z.infer<typeof JobIdSchema>
 *
 * const jobId1: JobId = 'job_1' // valid
 * const jobId2: JobId = 'job2' // invalid
 * ```
 *
 * @param aggregateType The aggregate type, e.g. 'job' or 'claim'
 * @param aggregateValue The aggregate value, e.g. '123' or '2023-07-13T22:11:53.310Z'
 * @returns A ZodSchema
 */
export const mimicTypeIdSchema = (aggregateType, aggregateValue) => z.custom((val) => new RegExp(`^${aggregateType}_${aggregateValue ? aggregateValue : '(\\w+-?)+'}$`, 'g').test(val));
/**
 * Creates a schema that can be used for a deterministic ID type without specifying the type.
 * This is useful when we need a typeId, but don't care what it is.
 *
 * ```ts
 * export const EmptySchema = emptyTypeIdSchema()
 * export type EmptyTypeId = z.infer<typeof EmptySchema>
 *
 * const jobId1: EmptyTypeId = 'job_1' // valid
 * const jobId2: EmptyTypeId = 'job2' // invalid
 * ```
 *
 * @returns A ZodSchema
 */
export const emptyTypeIdSchema = () => z.custom((val) => new RegExp(`^\\w+_\\w+$`, 'g').test(val));
