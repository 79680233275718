import z from 'zod';
import { VediImportFilterOptionsSchema, VediImportSchema } from '../vediImport.js';
export const RetrieveAllImportsApiSchema = {
    query: VediImportFilterOptionsSchema.merge(z.object({
        importStates: z.string().optional(),
        nextToken: z.string().optional(),
        limit: z.coerce.number().optional(),
    })),
    response: z.object({
        nextToken: z.string().optional(),
        results: VediImportSchema.array(),
    }),
};
