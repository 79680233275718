import { CornSchema } from '@eigtech/shared-corn';
import z from 'zod';
import { MeetingDetailsSchema } from '../base/meetingDetails.js';
export const GetMeetingRequestPathSchema = z.object({
    entityId: CornSchema,
    meetingId: z.string(),
    startTime: z.string().datetime(),
});
export const GetMeetingRequestSchema = GetMeetingRequestPathSchema;
export const GetMeetingResponseSchema = MeetingDetailsSchema;
