import z from 'zod'
import { PortalsServiceEventBaseSchema, portalsServiceEventType } from './service.js'

const portalDatabaseEventType = <V extends string | undefined>(eventName?: V) =>
  portalsServiceEventType('database', eventName)

export const PortalDatabaseEventSchema = PortalsServiceEventBaseSchema.merge(
  z.object({
    type: portalDatabaseEventType(),
  })
)

export const PortalDatabaseWipedEventSchema = PortalDatabaseEventSchema.merge(
  z.object({
    type: portalDatabaseEventType('wiped'),
    table: z.string(),
    recordCount: z.number(),
    success: z.boolean(),
  })
)

export type PortalDatabaseEvent = z.infer<typeof PortalDatabaseEventSchema>
export type PortalDatabaseWipedEvent = z.infer<typeof PortalDatabaseWipedEventSchema>
