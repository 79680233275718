import { CornSchema } from '@eigtech/shared-corn';
import z from 'zod';
export const UpdateScreenshotDescriptionRequestPathSchema = z.object({
    entityId: CornSchema,
    startTime: z.string().datetime(),
    dateAdded: z.string().datetime(),
});
export const UpdateScreenshotDescriptionRequestBodySchema = z.object({
    description: z.string(),
    label: z.string(),
});
export const UpdateScreenshotDescriptionRequestSchema = UpdateScreenshotDescriptionRequestBodySchema.merge(UpdateScreenshotDescriptionRequestPathSchema);
export const UpdateScreenshotDescriptionResponseSchema = z.void();
