import { z } from 'zod';
import { LossDetailsSchema } from '../../aggregates/lossDetails.js';
// Create Loss Details
/** @deprecated */
export const CreateLossDetailsRequestBodySchema = LossDetailsSchema.omit({ id: true });
/** @deprecated */
export const CreateLossDetailsRequestSchema = CreateLossDetailsRequestBodySchema;
/** @deprecated */
export const CreateLossDetailsResponseSchema = z.object({
    id: LossDetailsSchema.shape.id,
});
// Update Loss Details
/** @deprecated */
export const UpdateLossDetailsRequestBodySchema = LossDetailsSchema.omit({
    id: true,
    claimNumber: true,
}).partial();
/** @deprecated */
export const UpdateLossDetailsRequestPathSchema = z.object({
    claimNumber: z.string(),
});
/** @deprecated */
export const UpdateLossDetailsRequestSchema = UpdateLossDetailsRequestBodySchema.merge(UpdateLossDetailsRequestPathSchema);
/** @deprecated */
export const UpdateLossDetailsResponseSchema = z.void();
// Delete Loss Details
/** @deprecated */
export const DeleteLossDetailsRequestBodySchema = z.void();
/** @deprecated */
export const DeleteLossDetailsRequestPathSchema = z.object({
    lossDetailsId: LossDetailsSchema.shape.id,
});
/** @deprecated */
export const DeleteLossDetailsRequestSchema = DeleteLossDetailsRequestPathSchema;
/** @deprecated */
export const DeleteLossDetailsResponseSchema = z.void();
