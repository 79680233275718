import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
export const JobSizeEnumSchema = z.enum(['1', '2', '4', '8', '16', '32']);
export const XACoverageSchema = z.object({
    applyTo: z.enum(['R/C', 'ACV', 'Both']),
    deductible: z.number().optional(),
    policyLimit: z.number().optional(),
    policyDeductible: z.number().optional(),
    deductibleCredit: z.number().optional(),
    availDedCredit: z.number().optional(),
});
export const XALossTypeEnumSchema = z.enum([
    'COLLAPSE',
    'DRAIN BCK',
    'EARTHQUAKE',
    'FIRE',
    'FLOOD',
    'FREEZE',
    'HAIL',
    'HURRICANE',
    'ICE/SNOW',
    'LIGHTNING',
    'OTHER',
    'SEWAGE',
    'SMOKE',
    'THEFT',
    'TORNADO',
    'VANDALISM',
    'VEHICLE',
    'WATER',
    'WIND',
]);
export const XAAddressSchema = z.object({
    street: z.string().optional(),
    street2: z.string().optional(),
    street3: z.string().optional(),
    city: z.string(),
    state: z.string(),
    zipCode: z.string().optional(),
    country: z.enum(['US', 'CA', 'GB', 'BE', 'NL', 'IE', 'AU', 'FR', 'DE', 'NZ']),
    county: z.string().optional(),
    lat: z.number().optional(),
    lon: z.number().optional(),
    accuracy: z
        .enum([
        'ADDRESS',
        'STREET',
        'ZIP',
        'CITY',
        'STATE',
        'COUNTRY',
        'CITY_BLOCK',
        'INTERSECTION',
        'ZIP7',
        'ZIP9',
    ])
        .optional(),
});
export const XAOtherAddressSchema = XAAddressSchema.merge(z.object({ type: z.enum(['Home', 'Property', 'Business', 'Billing']) }));
export const XAPhoneSchema = z.object({
    type: z.enum(['Home', 'Business', 'Cell', 'Pager', 'Other']),
    number: z.string(),
    extension: z.string().optional(),
});
export const XAEstimatorContactSchema = z.object({
    adjusterNumber: z.string(),
    name: z.string(),
});
export const XAGeneralContactSchema = z.object({
    name: z.string(),
    address: XAOtherAddressSchema.optional(),
    primaryPhone: XAPhoneSchema,
    additionalPhones: XAPhoneSchema.array().optional(),
    email: z.string().optional(),
});
export const XAOtherContactSchema = XAGeneralContactSchema.merge(z.object({
    type: z.enum(['Estimator', 'ClaimRep', 'Claimant']),
}));
export const XAAttachmentSchema = z.object({
    fileName: z.string(),
    base64Contents: z.string().optional(),
    s3Contents: z
        .object({
        bucket: z.string(),
        key: z.string(),
    })
        .optional(),
    description: z.string(),
    mimeType: z.string(),
    approved: z.boolean(),
    sendToXm8: z.boolean().optional(),
    internal: z.boolean().optional(),
    note: z.string().optional(),
});
export const XAImportAssignmentRequestSchema = z.object({
    claimNumber: z.string(),
    isEmergency: z.boolean().optional(),
    jobSize: JobSizeEnumSchema,
    cinnamonUserId: z.string().optional(),
    loss: z.object({
        catCode: z.string().optional(),
        dateOfLoss: z.string().datetime(),
        dateReported: z.string().datetime(),
        lossDescription: z.string(),
        lossType: XALossTypeEnumSchema,
    }),
    contacts: z.object({
        access: z
            .object({
            name: z.string(),
            phone: z.string(),
            phoneExt: z.string().optional(),
        })
            .optional(),
        policyHolder: XAGeneralContactSchema,
        claimant: XAGeneralContactSchema.optional(),
        otherContacts: XAOtherContactSchema.array().optional(),
    }),
    property: XAAddressSchema.merge(z.object({
        roof: z
            .object({
            roofAge: z.number().optional(),
            roofType: z.string().optional(),
        })
            .optional(),
        mortgages: z
            .object({
            loanNumber: z.string().optional(),
            mortgagee: z.string(),
        })
            .array()
            .optional(),
    })),
    policy: z.object({
        policyNumber: z.string(),
        policyEffectiveDate: z.string().optional(),
        policyCoverageStartDate: z.string().optional(),
        policyExpirationDate: z.string().optional(),
        policyType: z.number().optional(),
        isCommercial: z.boolean().optional(),
        coverage: z
            .object({
            coverageA: XACoverageSchema,
            coverageB: XACoverageSchema,
            coverageC: XACoverageSchema,
            coverageD: XACoverageSchema.optional(),
            coverageE: XACoverageSchema.optional(),
            coverageF: XACoverageSchema.optional(),
            otherCoverages: z.record(z.string(), XACoverageSchema).optional(),
            doNotApplyLimits: z.boolean().optional(),
            applyDeductibleAcrossAllCoverages: z.boolean(),
        })
            .optional(),
        forms: z
            .object({
            editionDate: z.string().datetime(),
            name: z.string(),
        })
            .array()
            .optional(),
    }),
    attachments: XAAttachmentSchema.array().optional(),
    visibility: z
        .object({
        showDeskAdjuster: z.boolean().optional(),
        showIADeskAdjuster: z.boolean().optional(),
    })
        .optional(),
});
export const ImportAssignmentRequestBodySchema = XAImportAssignmentRequestSchema;
export const ImportAssignmentRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
});
export const ImportAssignmentRequestSchema = ImportAssignmentRequestBodySchema.merge(ImportAssignmentRequestHeadersSchema);
export const ImportAssignmentApiSchema = {
    body: ImportAssignmentRequestBodySchema,
    headers: ImportAssignmentRequestHeadersSchema,
};
