import z from 'zod';
import { SuccessApiOpDetailsSchema } from './base/auth0Events/SuccessApiOperationDetails.js';
//description: "Delete a role"
//path "/api/v2/roles/rol_jf7f2mXdWfM7Xt10"
export const RoleDeletedBodySchema = SuccessApiOpDetailsSchema.extend({
    request: SuccessApiOpDetailsSchema.shape.request.extend({
        method: z.literal('delete'),
        query: z.object({}),
        body: z.object({}),
    }),
    response: SuccessApiOpDetailsSchema.shape.response.extend({
        body: z.object({}),
    }),
});
export const RoleDeletedPathSchema = z.object({
    roleId: z.string(),
});
export const RoleDeletedRequestSchema = RoleDeletedBodySchema.merge(RoleDeletedPathSchema);
