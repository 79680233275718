import { CohortEnumSchema } from '@eigtech/auth0-types';
import { z } from 'zod';
import { DocumentSchemaV2 } from '../aggregates/index.js';
export const AddDocumentFromS3ApiSchema = {
    body: DocumentSchemaV2.pick({
        type: true,
        parentFilename: true,
        entityId: true,
        filename: true,
        notes: true,
        source: true,
        externalSystemId: true,
    }).merge(z.object({
        description: z.string().optional(),
        bucketName: z.string(),
        key: z.string(),
        userCohorts: CohortEnumSchema.array().optional(),
    })),
    response: DocumentSchemaV2,
};
export const AddDocumentFromS3RequestSchema = AddDocumentFromS3ApiSchema.body;
export const AddDocumentFromS3ResponseSchema = AddDocumentFromS3ApiSchema.response;
