import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { JobIdSchema } from '../../base/ids.js';
import { JobServicesSchema } from '../../base/jobService.js';
export const RemoveJobServicesRequestBodySchema = z.object({
    services: JobServicesSchema,
});
export const RemoveJobServicesRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const RemoveJobServicesRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
});
export const RemoveJobServicesRequestSchema = RemoveJobServicesRequestBodySchema.merge(RemoveJobServicesRequestHeadersSchema).merge(RemoveJobServicesRequestPathSchema);
export const RemoveJobServicesResponseSchema = z.object({
    jobId: JobIdSchema,
    services: JobServicesSchema,
});
export const RemoveJobServicesApiSchema = {
    body: RemoveJobServicesRequestBodySchema,
    headers: RemoveJobServicesRequestHeadersSchema,
    params: RemoveJobServicesRequestPathSchema,
    response: RemoveJobServicesResponseSchema,
};
