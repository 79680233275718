import { ContactCornSchema } from '@eigtech/contacts-types';
import { z } from 'zod';
export const SubcontractorSchema = z.object({
    contactId: ContactCornSchema.or(z.string()),
    companyName: z.string().optional(),
    contactName: z.string(),
    email: z.string().optional(),
    phone: z.string().optional(),
    address: z
        .object({
        street1: z.string(),
        street2: z.string().optional(),
        addressType: z.string().optional(),
        city: z.string().optional(),
        country: z.string().optional(),
        countyOrParishCode: z.string().optional(),
        stateOrProvince: z.string().optional(),
        zipOrPostalCode: z.string().optional(),
    })
        .optional(),
});
