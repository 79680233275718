import { zodMerge } from '@eigtech/function-utils';
import z from 'zod';
export const AnyOfOperatorSchema = z.literal('ANYOF');
export const IncludesOperatorSchema = z.literal('INCLUDES');
export const NullOperatorSchema = z.enum(['IS', 'IS NOT']);
export const StringOperatorSchema = z.enum([
    '=',
    '<',
    '>',
    '>=',
    '<=',
    '!=',
    'LIKE',
    'NOT LIKE',
    'ILIKE',
    'NOT ILIKE',
]);
export const OperatorSchema = z.enum([
    AnyOfOperatorSchema.value,
    IncludesOperatorSchema.value,
    ...NullOperatorSchema.options,
    ...StringOperatorSchema.options,
]);
export const StringFilterSchema = z.object({
    value: z.string(),
    operator: StringOperatorSchema.optional(),
});
export const isStringFilter = (filterProps) => StringFilterSchema.safeParse(filterProps).success;
export const AnyOfFilterSchema = z.object({
    value: z.string().or(z.string().array()),
    operator: AnyOfOperatorSchema,
});
export const isAnyOfFilter = (filterProps) => AnyOfFilterSchema.safeParse(filterProps).success;
export const IncludesFilterSchema = z.object({
    value: z.string(),
    operator: IncludesOperatorSchema,
});
export const isIncludesFilter = (filterProps) => IncludesFilterSchema.safeParse(filterProps).success;
export const NullFilterSchema = z.object({
    value: z.literal('NULL'),
    operator: NullOperatorSchema,
});
export const isNullFilter = (filterProps) => NullFilterSchema.safeParse(filterProps).success;
export const FilterBaseSchema = z.union([
    AnyOfFilterSchema,
    IncludesFilterSchema,
    NullFilterSchema,
    StringFilterSchema,
]);
export const FilterBaseParamsSchema = z.object({
    page: z.number().min(1),
    pageSize: z.number().min(10),
    sortAscending: z.boolean(),
});
export const OperatorEnum = OperatorSchema.Enum;
export const makeSummaryFilterSchema = (fieldSchema) => zodMerge(FilterBaseSchema, z.object({
    field: fieldSchema,
}));
export const makeSummaryParamsSchema = (fieldSchema, filterSchema) => FilterBaseParamsSchema.merge(z.object({
    sortBy: fieldSchema,
    filter: z.array(filterSchema),
}));
