// This code is ripped from [typeid-js](https://github.com/jetpack-io/typeid-js/tree/main)
import { uuidv7obj, UUID } from 'uuidv7';
import { parseUUID } from './parse_uuid.js';
import { encode, decode } from './base32.js';
function isValidPrefix(str) {
    if (str.length > 63) {
        return false;
    }
    let code;
    let i;
    let len;
    for (i = 0, len = str.length; i < len; i += 1) {
        code = str.charCodeAt(i);
        if (!(code > 96 && code < 123)) {
            // lower alpha (a-z)
            return false;
        }
    }
    return true;
}
export class TypeID {
    constructor(prefix, suffix = '') {
        this.prefix = prefix;
        this.suffix = suffix;
        if (!isValidPrefix(prefix)) {
            throw new Error('Invalid prefix. Must be at most 63 ascii letters [a-z]');
        }
        this.prefix = prefix;
        if (suffix) {
            this.suffix = suffix;
        }
        else {
            const uuid = uuidv7obj();
            this.suffix = encode(uuid.bytes);
        }
        if (this.suffix.length !== 26) {
            throw new Error(`Invalid length. Suffix should have 26 characters, got ${suffix.length}`);
        }
        if (this.suffix[0] > '7') {
            throw new Error('Invalid suffix. First character must be in the range [0-7]');
        }
        // Validate the suffix by decoding it. If it's invalid, an error will be thrown.
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const unused = decode(this.suffix);
    }
    getType() {
        return this.prefix;
    }
    getSuffix() {
        return this.suffix;
    }
    asType(prefix) {
        const self = this;
        if (self.prefix !== prefix) {
            throw new Error(`Cannot convert TypeID of type ${self.prefix} to type ${prefix}`);
        }
        return self;
    }
    toUUIDBytes() {
        return decode(this.suffix);
    }
    toUUID() {
        const uuid = new UUID(this.toUUIDBytes());
        return uuid.toString();
    }
    toString() {
        if (this.prefix === '') {
            return this.suffix;
        }
        return `${this.prefix}_${this.suffix}`;
    }
    static fromString(str) {
        const parts = str.split('_');
        if (parts.length === 1) {
            return new TypeID('', parts[0]);
        }
        if (parts.length === 2) {
            if (parts[0] === '') {
                throw new Error(`Invalid TypeID. Prefix cannot be empty when there's a separator: ${str}`);
            }
            return new TypeID(parts[0], parts[1]);
        }
        throw new Error(`Invalid TypeID string: ${str}`);
    }
    static fromUUIDBytes(prefix, bytes) {
        const suffix = encode(bytes);
        return new TypeID(prefix, suffix);
    }
    static fromUUID(prefix, uuid) {
        const suffix = encode(parseUUID(uuid));
        return new TypeID(prefix, suffix);
    }
}
export function typeid(prefix = '', suffix = '') {
    return new TypeID(prefix, suffix);
}
