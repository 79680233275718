import { isISOish } from '@eigtech/function-utils';
import { z } from 'zod';
import { claimsServiceCorn } from '../base/service.js';
/** @deprecated */
export const ClaimStatusCornSchema = claimsServiceCorn('status');
/** @deprecated */
export const ClaimStatusAdminEventNameSchema = z.enum(['idUpdated', 'deleted']);
/** @deprecated */
export const DeprecatedClaimStatusEventNameEnumSchema = z.enum([
    'fileReviewerAssigned',
    'fileReviewerRemoved',
]);
/** @deprecated */
export const ProducerClaimStatusEventNameEnumSchema = z.enum([
    'canceled',
    'carrierApprovedEstimate',
    'carrierApprovedEstimateWithExceptions',
    'carrierRejectedEstimate',
    'claimInvoiceCreated',
    'clientApproved',
    'clientRejected',
    'coordinatorAssigned',
    'coordinatorRemoved',
    'customerContactedEmail',
    'customerContactedLvm',
    'customerNoContact',
    'dateReceived',
    'estimateRevisionRequested',
    'estimateSentToCarrier',
    'fieldAdjusterAssigned',
    'fieldAdjusterContactedCustomer',
    'fieldAdjusterCorrectionUploaded',
    'fieldAdjusterEstimateUploaded',
    'fieldAdjusterInspectionCompleted',
    'fieldAdjusterInspectionScheduled',
    'fieldAdjusterReassigned',
    'fieldAdjusterRemoved',
    'inAssignQueue',
    'initialCustomerContactAttempted',
    'initialCustomerContactFailed',
    'initialCustomerContactSuccess',
    'invalidContactInformation',
    'jobCompleted',
    'jobContracted',
    'jobNotSold',
    'jobSold',
    'jobStarted',
    'lossDate',
    'otrInvoked',
    'projectManagerAssigned',
    'projectManagerRemoved',
    'qaApproved',
    'qaApprovedPreliminaryReport',
    'qaApprovedSupplementReport',
    'qaRejected',
    'reInspectionRequested',
    'reviewerAssigned',
    'reviewerRemoved',
    'surveySentToCustomer',
    'wadSentToCustomer',
]);
/** @deprecated */
export const ClaimStatusEventNameLaxSchema = z.enum([
    ...ProducerClaimStatusEventNameEnumSchema.options,
    ...DeprecatedClaimStatusEventNameEnumSchema.options,
]);
/** @deprecated */
export const ClaimStatusEventNameCanonSchema = z
    .enum([...ClaimStatusEventNameLaxSchema.options])
    .transform((eventName) => {
    switch (eventName) {
        case 'fileReviewerAssigned':
            return ProducerClaimStatusEventNameEnumSchema.Enum.reviewerAssigned;
        case 'fileReviewerRemoved':
            return ProducerClaimStatusEventNameEnumSchema.Enum.reviewerRemoved;
        default:
            return eventName;
    }
});
/** @deprecated */
export const ClaimStatusSourceSchema = z.enum([
    'Verisk',
    'CSR',
    'MOCKERY',
    'Assignments',
    'Estimator',
    'Symbility',
]);
/** @deprecated */
export const ClaimStatusSource = ClaimStatusSourceSchema.Values;
/** @deprecated */
export const ClaimStatusCanonSchema = z.object({
    id: z.string(),
    claimNumber: z.string(),
    statusEvent: ClaimStatusEventNameCanonSchema,
    date: z.string().refine(isISOish, { message: 'Not nearly ISO enough' }),
    dataSource: ClaimStatusSourceSchema.or(z.string()),
    createdBy: z.string().optional(),
    publicationTargets: z.string().array().optional(),
    notes: z.string().optional(),
});
/** @deprecated */
export const ClaimStatusLaxSchema = z.object({
    id: z.string(),
    claimNumber: z.string(),
    statusEvent: ClaimStatusEventNameLaxSchema,
    date: z.string().refine(isISOish, { message: 'Not nearly ISO enough' }),
    dataSource: ClaimStatusSourceSchema.or(z.string()),
    createdBy: z.string().optional(),
    publicationTargets: z.string().array().optional(),
});
/** @deprecated */
export const PublishableEventSchema = z.enum([
    ProducerClaimStatusEventNameEnumSchema.Enum.carrierApprovedEstimate,
    ProducerClaimStatusEventNameEnumSchema.Enum.carrierRejectedEstimate,
    ProducerClaimStatusEventNameEnumSchema.Enum.clientApproved,
    ProducerClaimStatusEventNameEnumSchema.Enum.clientRejected,
    ProducerClaimStatusEventNameEnumSchema.Enum.customerContactedEmail,
    ProducerClaimStatusEventNameEnumSchema.Enum.customerContactedLvm,
    ProducerClaimStatusEventNameEnumSchema.Enum.customerNoContact,
    ProducerClaimStatusEventNameEnumSchema.Enum.fieldAdjusterContactedCustomer,
    ProducerClaimStatusEventNameEnumSchema.Enum.fieldAdjusterInspectionCompleted,
    ProducerClaimStatusEventNameEnumSchema.Enum.fieldAdjusterInspectionScheduled,
    ProducerClaimStatusEventNameEnumSchema.Enum.initialCustomerContactSuccess,
    ProducerClaimStatusEventNameEnumSchema.Enum.jobCompleted,
    ProducerClaimStatusEventNameEnumSchema.Enum.jobContracted,
    ProducerClaimStatusEventNameEnumSchema.Enum.jobNotSold,
    ProducerClaimStatusEventNameEnumSchema.Enum.jobStarted,
    ProducerClaimStatusEventNameEnumSchema.Enum.qaApproved,
    ProducerClaimStatusEventNameEnumSchema.Enum.qaRejected,
    ProducerClaimStatusEventNameEnumSchema.Enum.reInspectionRequested,
]);
/** @deprecated */
export const publishableEvents = PublishableEventSchema.options;
