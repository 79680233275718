import z from 'zod';
export const SuccessApiOpDetailsSchema = z.object({
    request: z.object({
        method: z.enum(['post', 'delete', 'patch']),
        path: z.string(),
        query: z.unknown(),
        userAgent: z.string(),
        body: z.unknown(),
        channel: z.string(),
        ip: z.string(),
        auth: z.object({
            user: z
                .object({
                user_id: z.string(),
                name: z.string(),
                email: z.string(),
            })
                .or(z.unknown()),
            strategy: z.string(),
            credentials: z
                .object({
                jti: z.string(),
            })
                .or(z.unknown()),
        }),
    }),
    response: z.object({
        statusCode: z.number(),
        body: z.unknown(),
    }),
});
