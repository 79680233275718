import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { ContactCornSchema, ContactSchema } from '../../../base/contacts.js';
export const SetExternalIdsRequestPathSchema = z.object({ contactId: ContactCornSchema });
export const SetExternalIdsRequestHeadersSchema = ApiLambdalerAuthHeaderWithRequestActorSchema.pick({ requestActor: true });
export const SetExternalIdsRequestBodySchema = z
    .object({
    externalIds: ContactSchema.shape.externalSystemIds,
})
    .required();
export const SetExternalIdsRequestSchema = SetExternalIdsRequestBodySchema.merge(SetExternalIdsRequestPathSchema).merge(SetExternalIdsRequestHeadersSchema);
export const SetExternalIdsApiSchema = {
    body: SetExternalIdsRequestBodySchema,
    headers: SetExternalIdsRequestHeadersSchema,
    params: SetExternalIdsRequestPathSchema,
};
