import z from 'zod';
import { SuccessApiOpDetailsSchema } from './base/auth0Events/SuccessApiOperationDetails.js';
//description "Add members to an organization"
//path "/api/v2/organizations/org_Miqv9tEm8irQwL17/members"
export const UserAddedToOrgBodySchema = SuccessApiOpDetailsSchema.extend({
    request: SuccessApiOpDetailsSchema.shape.request.extend({
        method: z.literal('post'),
        query: z.object({}),
        body: z.object({
            members: z.string().array(),
        }),
    }),
    response: SuccessApiOpDetailsSchema.shape.response.extend({
        body: z.object({}),
    }),
});
export const UserAddedToOrgPathSchema = z.object({
    orgId: z.string(),
});
export const UserAddedToOrgRequestSchema = UserAddedToOrgBodySchema.merge(UserAddedToOrgPathSchema);
