import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { ContactCornSchema, ContactSchema } from '../../../base/contacts.js';
export const DeleteContactRequestPathSchema = z.object({
    contactId: ContactCornSchema,
});
export const DeleteContactRequestHeadersSchema = ApiLambdalerAuthHeaderWithRequestActorSchema.pick({
    requestActor: true,
});
export const DeleteContactRequestQuerySchema = z.object({ force: z.literal('true').optional() });
export const DeleteContactRequestSchema = DeleteContactRequestPathSchema.merge(DeleteContactRequestQuerySchema).merge(DeleteContactRequestHeadersSchema);
export const DeleteContactResponseSchema = z.object({
    contact: ContactSchema.optional(),
});
export const DeleteContactApiSchema = {
    headers: DeleteContactRequestHeadersSchema,
    params: DeleteContactRequestPathSchema,
    query: DeleteContactRequestQuerySchema,
    response: DeleteContactResponseSchema,
};
