import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { CornSchema } from '@eigtech/shared-corn';
import { z } from 'zod';
export const GetScreenshotUrlRequestPathSchema = z.object({
    entityId: CornSchema,
    startTime: z.string(),
    dateAdded: z.string().datetime(),
});
export const GetScreenshotUrlRequestHeaderSchema = ApiLambdalerAuthHeaderSchema;
export const GetScreenshotUrlRequestSchema = GetScreenshotUrlRequestPathSchema.merge(GetScreenshotUrlRequestHeaderSchema);
