import z from 'zod';
import { TimelineEntityTypeSchema } from '../timeline.js';
import { StepFunctionTaskContextSchema } from './context.js';
import { SetupTimelineSyncResponseSchema } from './setup.js';
export const IncrementPageResponseSchema = z.object({
    entityType: TimelineEntityTypeSchema,
    executionId: z.string(),
    hasMorePages: z.boolean(),
    nextPage: z.number(),
});
export const IncrementPageInputSchema = z.object({
    setupResults: SetupTimelineSyncResponseSchema,
    incrementPageResults: IncrementPageResponseSchema.optional(),
});
export const IncrementPageRequestSchema = z.object({
    context: StepFunctionTaskContextSchema,
    input: IncrementPageInputSchema,
});
