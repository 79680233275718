import z from 'zod'

/**
 * All possible job statuses in the application.
 * May need filtering down to specific subset, depending on use case.
 */
export const JobStatusSchema = z.enum([
  'closed',
  'created',
  'paymentPending',
  'paymentReceived',
  'readyForPayment',
  'reopened',
  'scheduled',
])

export const JobStatus = JobStatusSchema.Values

export type JobStatusEnum = z.infer<typeof JobStatusSchema>
