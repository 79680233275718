import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { ClaimLossTypeSchema, ClaimNumberSchema } from '../model/index.js';
export const UpdateLossTypeSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: z.object({
        lossType: ClaimLossTypeSchema,
    }),
    headers: ApiLambdalerAuthHeaderSchema.pick({ requestActor: true }).required({
        requestActor: true,
    }),
    response: z.object({
        claimNumber: ClaimNumberSchema,
        lossDetails: z.object({
            lossType: ClaimLossTypeSchema,
        }),
    }),
};
export const UpdateLossTypeRequestSchema = UpdateLossTypeSchemas.params
    .merge(UpdateLossTypeSchemas.body)
    .merge(UpdateLossTypeSchemas.headers);
export const UpdateLossTypeApiSchema = UpdateLossTypeSchemas;
