import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { CornSchema } from '@eigtech/shared-corn';
import z from 'zod';
export const GetMeetingVideoShareLinkRequestPathSchema = z.object({
    entityId: CornSchema,
    meetingId: z.string(),
});
export const GetMeetingVideoShareLinkRequestHeadersSchema = ApiLambdalerAuthHeaderSchema;
export const GetMeetingVideoShareLinkRequestSchema = GetMeetingVideoShareLinkRequestPathSchema.merge(GetMeetingVideoShareLinkRequestHeadersSchema);
export const GetMeetingVideoShareLinkResponseSchema = z.object({
    shareLink: z.string(),
});
