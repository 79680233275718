export const jobDefaultChronicleLabelMap = {
    appointmentCompleted: 'Appointment Completed',
    appointmentScheduled: 'Appointment Scheduled',
    approverAssignedToJob: 'Approver Assigned to Job',
    approverUnassignedFromJob: 'Approver Unassigned from Job',
    billSentToCollections: 'Bill Sent to Collections',
    cancelled: 'Job Cancelled',
    carrierInvoiced: 'Carrier Invoiced',
    carrierMadePayment: 'Carrier Made Payment',
    completed: 'Job Completed',
    coordinatorAssignedToJob: 'Coordinator Assigned to Job',
    coordinatorUnassignedFromJob: 'Coordinator Unassigned from Job',
    customerContactAttempted: 'Customer Contact Attempted',
    depositOverrideApproved: 'Deposit Override Approved',
    depositPaid: 'Deposit Paid',
    descriptionUpdated: 'Description Updated',
    dispatcherAssignedToJob: 'Dispatcher Assigned to Job',
    dispatcherUnassignedFromJob: 'Dispatcher Unassigned from Job',
    fieldTechnicianAssignedToJob: 'Field Technician Assigned to Job',
    fieldTechnicianUnassignedFromJob: 'Field Technician Unassigned from Job',
    jobAssignedToClaim: 'Job Assigned to Claim',
    jobUnassignedFromClaim: 'Job Unassigned from Claim',
    locationUpdated: 'Job Location Updated',
    primaryContactAssignedToJob: 'Primary Contact Assigned to Job',
    primaryContactUnassignedFromJob: 'Primary Contact Unassigned from Job',
    projectManagerAssignedToJob: 'Project Manager Assigned to Job',
    projectManagerUnassignedFromJob: 'Project Manager Unassigned from Job',
    received: 'Job Created',
    reopened: 'Job Reopened',
    servicesAdded: 'Services Added',
    servicesRemoved: 'Services Removed',
    started: 'Job Started',
    subcontractorAssignedToJob: 'Subcontractor Assigned to Job',
    subcontractorPaid: 'Subcontractor Paid',
    subcontractorUnassignedFromJob: 'Subcontractor Unassigned from Job',
    subdocumentIssued: 'Subcontractor Document Issued',
    subdocumentReceived: 'Subcontractor Document Received',
    withdrawn: 'Job Withdrawn',
    workAuthorizationCompleted: 'Work Authorization Signed',
    workAuthorizationDeclined: 'Work Authorization Declined',
    workAuthorizationExpired: 'Work Authorization Expired',
    workAuthorizationOverrideApproved: 'Work Authorization Override',
    workAuthorizationReassigned: 'Work Authorization Reassigned',
    workAuthorizationRecalled: 'Work Authorization Recalled',
    workAuthorizationSent: 'Work Authorization Sent',
    workAuthorizationRecipientSigned: 'Work Authorization Signed',
    workDetailsReceived: 'Work Details Received',
};
