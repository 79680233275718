import z from 'zod';
import { SuccessApiOpDetailsSchema } from './base/auth0Events/SuccessApiOperationDetails.js';
export const UserCreatedBodySchema = SuccessApiOpDetailsSchema.extend({
    request: SuccessApiOpDetailsSchema.shape.request.extend({
        method: z.literal('post'),
        query: z.object({}),
        body: z.object({
            email: z.string(),
            password: z.string(),
            connection: z.string(),
            app_metadata: z
                .object({
                contactIds: z.array(z.string()),
                domainOrigin: z.string(),
            })
                .or(z.unknown()),
            name: z.string(),
            verify_email: z.boolean().optional(),
        }),
    }),
    response: SuccessApiOpDetailsSchema.shape.response.extend({
        body: z.object({
            created_at: z.string(),
            email: z.string(),
            email_verified: z.boolean(),
            identities: z
                .object({
                connection: z.string(),
                user_id: z.string(),
                provider: z.string(),
                isSocial: z.boolean(),
            })
                .array(),
            name: z.string(),
            nickname: z.string(),
            picture: z.string(),
            updated_at: z.string(),
            user_id: z.string(),
            app_metadata: z
                .object({
                contactIds: z.array(z.string()),
                domainOrigin: z.string(),
            })
                .or(z.record(z.string(), z.string()))
                .optional(),
        }),
    }),
});
export const UserCreatedRequestSchema = UserCreatedBodySchema;
