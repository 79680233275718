import { z } from 'zod';
import { AobDetailsSchema } from './aob.js';
import { BaseClaimSchema, BaseEstimateSchema, ClaimNumberSchema, EstimateIdSchema, ExternalSystemIdSchema, } from './base.js';
import { CustomerCommunicationSchema } from './customerCommunication.js';
import { EstimateReviewSchema } from './estimate.js';
import { InitialClaimActionsSchema } from './initialActions.js';
import { InspectionDetailsSchema, InspectorRoleSchema } from './inspections.js';
import { LossOfUseSchema } from './lossOfUse.js';
import { OutcomeSchema } from './outcome.js';
import { PublicationTargetsSchema } from './publishable.js';
import { ClaimReviewDetailsSchema } from './reviews.js';
const ClaimAggregateExternalSystemIdDetails = z.record(ExternalSystemIdSchema, z.object({
    value: z.string().optional(),
    operation: z.enum(['added', 'updated', 'removed']),
}));
export const ClaimAggregateTargetSchema = BaseClaimSchema.merge(z.object({
    aobDetails: AobDetailsSchema.optional(),
    customerCommunication: CustomerCommunicationSchema.array().optional(),
    externalSystemIdDetails: ClaimAggregateExternalSystemIdDetails.optional(),
    initialClaimActions: InitialClaimActionsSchema.array().optional(),
    inspections: z.record(InspectionDetailsSchema).optional(),
    latestInspection: z
        .record(InspectorRoleSchema, InspectionDetailsSchema.required({ inspectorRole: true }))
        .optional(),
    lossOfUse: z.record(LossOfUseSchema).optional(),
    outcome: OutcomeSchema.optional(),
    publicationTargets: PublicationTargetsSchema.array().optional(),
    receivedInAssignQueueEvent: z.boolean().optional(),
    reviews: ClaimReviewDetailsSchema.optional(),
}));
export const EstimateAggregateTargetSchema = BaseEstimateSchema;
export const EstimateReviewsAggregateSchema = z.object({
    claimNumber: ClaimNumberSchema,
    latestEstimate: EstimateIdSchema,
    estimates: z.record(EstimateIdSchema, z.object({ estimate: BaseEstimateSchema, reviews: z.array(EstimateReviewSchema) })),
});
