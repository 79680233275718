import { DocumentCornSchema } from '@eigtech/documents-types';
import z from 'zod';
import { LossOfUseReceiptAddedEventSchema } from '../events/lossOfUse.js';
import { ClaimNumberSchema } from '../model/index.js';
import { BaseHeaderSchema } from './base.js';
const AddLossOfUseReceiptSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
        documentCorn: DocumentCornSchema,
        id: z.string(),
    }),
    body: z.object({
        note: z.string().optional().nullable(),
    }),
    headers: BaseHeaderSchema,
    response: LossOfUseReceiptAddedEventSchema.shape.claim.shape.lossOfUse,
};
export const AddLossOfUseReceiptRequestSchema = AddLossOfUseReceiptSchemas.params
    .merge(AddLossOfUseReceiptSchemas.headers)
    .merge(AddLossOfUseReceiptSchemas.body);
const AddLossOfUseReceiptInputSchema = AddLossOfUseReceiptRequestSchema.merge(z.object({
    documentCorn: DocumentCornSchema,
    receiptDateReceived: z.string().datetime(),
}));
export const AddLossOfUseReceiptApiSchema = AddLossOfUseReceiptSchemas;
