import { ContactCornSchema } from '@eigtech/contacts-types';
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import z from 'zod';
import { CustomerContactMethodSchema, CustomerContactOutcomeSchema, } from '../../base/customerContact.js';
import { JobIdSchema } from '../../base/ids.js';
export const RecordCustomerContactAttemptRequestBodySchema = z.object({
    contactedBy: ContactCornSchema,
    contactAttemptedDate: z.string().datetime(),
    customerContacted: ContactCornSchema.or(z.string()),
    contactOutcome: CustomerContactOutcomeSchema,
    contactMethod: CustomerContactMethodSchema,
});
export const RecordCustomerContactAttemptRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
    requestActor: true,
});
export const RecordCustomerContactAttemptRequestPathSchema = z.object({
    jobId: JobIdSchema,
});
export const RecordCustomerContactAttemptRequestSchema = RecordCustomerContactAttemptRequestBodySchema.merge(RecordCustomerContactAttemptRequestHeadersSchema).merge(RecordCustomerContactAttemptRequestPathSchema);
export const RecordCustomerContactAttemptResponseSchema = z.void();
export const RecordCustomerContactAttemptApiSchema = {
    body: RecordCustomerContactAttemptRequestBodySchema,
    headers: RecordCustomerContactAttemptRequestHeadersSchema,
    params: RecordCustomerContactAttemptRequestPathSchema,
};
