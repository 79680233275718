import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { VendorRatesSchema } from '../../base/index.js';
export const UpdateVendorRatesApiSchema = {
    params: z.object({
        vendorRatesId: VendorRatesSchema.shape.id,
    }),
    headers: ApiLambdalerAuthHeaderWithRequestActorSchema,
    body: z.object({
        vendorRates: VendorRatesSchema.omit({ id: true }),
    }),
    response: z.object({
        vendorRates: VendorRatesSchema,
    }),
};
