import { z } from 'zod';
import { VendorRatesSchema } from '../base/vendor.js';
import { serviceEventType, TarpulatorEntityTypeName, TarpulatorServiceEventBaseSchema, } from './service.js';
export const tarpulatorVendorRatesEventType = (eventName) => serviceEventType(TarpulatorEntityTypeName.vendorRates, eventName);
export const TarpulatorVendorRatesEventTypeNameSchema = z.enum(['created', 'deleted', 'updated']);
export const TarpulatorVendorRatesEventTypeName = TarpulatorVendorRatesEventTypeNameSchema.Values;
export const TarpulatorVendorRatesEventSchema = TarpulatorServiceEventBaseSchema.merge(z.object({
    type: tarpulatorVendorRatesEventType(),
    vendorRates: z.unknown(),
}));
export const TarpulatorVendorRatesCreatedEventSchema = TarpulatorVendorRatesEventSchema.merge(z.object({
    type: tarpulatorVendorRatesEventType(TarpulatorVendorRatesEventTypeName.created),
    vendorRates: VendorRatesSchema,
}));
export const TarpulatorVendorRatesUpdatedEventSchema = TarpulatorVendorRatesEventSchema.merge(z.object({
    type: tarpulatorVendorRatesEventType(TarpulatorVendorRatesEventTypeName.updated),
    vendorRates: VendorRatesSchema,
}));
export const TarpulatorVendorRatesDeletedEventSchema = TarpulatorVendorRatesEventSchema.merge(z.object({
    type: tarpulatorVendorRatesEventType(TarpulatorVendorRatesEventTypeName.deleted),
    vendorRates: VendorRatesSchema,
}));
