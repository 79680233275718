import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { RegionSchema } from '../../base/region.js';
export const DeleteRegionApiSchema = {
    params: z.object({
        regionId: RegionSchema.shape.id,
    }),
    headers: ApiLambdalerAuthHeaderWithRequestActorSchema,
    response: z.object({
        regionId: RegionSchema.shape.id,
    }),
};
