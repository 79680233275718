import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
import { z } from 'zod';
import { ContactSchema } from '../../../base/contacts.js';
export const DeleteContactDescriptionRequestPathSchema = z.object({
    contactId: ContactSchema.shape.contactId,
});
export const DeleteContactDescriptionRequestHeadersSchema = ApiLambdalerAuthHeaderWithRequestActorSchema.pick({ requestActor: true });
export const DeleteContactDescriptionRequestSchema = DeleteContactDescriptionRequestPathSchema.merge(DeleteContactDescriptionRequestHeadersSchema);
export const DeleteContactDescriptionResponseSchema = z.void();
export const DeleteContactDescriptionApiSchema = {
    headers: DeleteContactDescriptionRequestHeadersSchema,
    params: DeleteContactDescriptionRequestPathSchema,
};
