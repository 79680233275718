import { z } from 'zod';
import { ContactSchema } from '../../../base/contacts.js';
import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types';
export const UpsertContactDescriptionRequestBodySchema = z.object({
    description: z.string().min(1),
});
export const UpsertContactDescriptionRequestPathSchema = z.object({
    contactId: ContactSchema.shape.contactId,
});
export const UpsertContactDescriptionRequestHeadersSchema = ApiLambdalerAuthHeaderWithRequestActorSchema.pick({ requestActor: true });
export const UpsertContactDescriptionRequestSchema = UpsertContactDescriptionRequestBodySchema.merge(UpsertContactDescriptionRequestPathSchema).merge(UpsertContactDescriptionRequestHeadersSchema);
export const UpsertContactDescriptionResponseSchema = z.void();
export const UpsertContactDescriptionApiSchema = {
    body: UpsertContactDescriptionRequestBodySchema,
    headers: UpsertContactDescriptionRequestHeadersSchema,
    params: UpsertContactDescriptionRequestPathSchema,
};
