import z from 'zod';
import { ClaimNumberSchema, BaseEstimateSchema, EstimateIdSchema } from '../model/index.js';
import { BaseHeaderSchema } from './base.js';
const ReceiveEstimateSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
        estimateId: EstimateIdSchema,
    }),
    body: BaseEstimateSchema.omit({ claimNumber: true, estimateId: true }),
    headers: BaseHeaderSchema,
};
export const ReceiveEstimateRequestSchema = ReceiveEstimateSchemas.params
    .merge(ReceiveEstimateSchemas.body)
    .merge(ReceiveEstimateSchemas.headers);
export const ReceiveEstimateApiSchema = ReceiveEstimateSchemas;
