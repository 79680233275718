import z from 'zod';
import { ClaimNumberSchema, ClaimFactorsSchema } from '../model/index.js';
import { BaseHeaderSchema } from './base.js';
const RemoveClaimFactorsSchemas = {
    params: z.object({
        claimNumber: ClaimNumberSchema,
    }),
    body: z.object({
        claimFactors: ClaimFactorsSchema.array(),
    }),
    headers: BaseHeaderSchema,
    response: z.object({
        claimNumber: ClaimNumberSchema,
        claimFactors: ClaimFactorsSchema.array(),
    }),
};
export const RemoveClaimFactorsRequestSchema = RemoveClaimFactorsSchemas.params
    .merge(RemoveClaimFactorsSchemas.body)
    .merge(RemoveClaimFactorsSchemas.headers);
export const RemoveClaimFactorsApiSchema = RemoveClaimFactorsSchemas;
