import z from 'zod';
import { SuccessApiOpDetailsSchema } from './base/auth0Events/SuccessApiOperationDetails.js';
// path /api/v2/users/auth0%7C63377b17eb80942f6f0b4b3a
export const UserDeletedBodySchema = SuccessApiOpDetailsSchema.extend({
    request: SuccessApiOpDetailsSchema.shape.request.extend({
        method: z.literal('delete'),
        query: z.object({}),
        body: z.object({}),
    }),
    response: SuccessApiOpDetailsSchema.shape.response.extend({
        body: z.object({}),
    }),
});
export const UserDeletedPathSchema = z.object({
    userId: z.string(),
});
export const UserDeletedRequestSchema = UserDeletedBodySchema.merge(UserDeletedPathSchema);
