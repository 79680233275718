import { z } from 'zod';
export const TranscribeTranscriptionAlternativeSchema = z.object({
    confidence: z.string(),
    content: z.string(),
});
export const TranscribeTranscriptionTranscriptSchema = z.object({
    transcript: z.string(),
});
export const TranscribeTranscriptionItemSchema = z.object({
    id: z.number(),
    type: z.string(),
    alternatives: TranscribeTranscriptionAlternativeSchema.array(),
    start_time: z.string().optional(),
    end_time: z.string().optional(),
});
export const TranscribeTranscriptionAudioSegmentSchema = z.object({
    id: z.number(),
    transcript: z.string(),
    start_time: z.string(),
    end_time: z.string(),
    items: z.number().array(),
});
export const TranscribeTranscriptionSchema = z.object({
    jobName: z.string().optional(),
    accountId: z.string().optional(),
    status: z.string().optional(),
    results: z.object({
        transcripts: TranscribeTranscriptionTranscriptSchema.array(),
        items: TranscribeTranscriptionItemSchema.array(),
        audio_segments: TranscribeTranscriptionAudioSegmentSchema.array(),
    }),
});
