import { CriticalAlertEventSchema } from '@eigtech/alerts-types';
import { NoteCornSchema } from '@eigtech/notes-types';
import { z } from 'zod';
import { VediImportType, VediImportTypeSchema } from '../ediImport/index.js';
export const VediImportProcessingFailedAlertSchema = CriticalAlertEventSchema.merge(z.object({
    type: z.literal('alert:critical:vediImportProcessingFailed'),
    metadata: z.object({
        claimNumber: z.string(),
        service: z.literal('verisk-edi-interface'),
        importType: VediImportTypeSchema,
    }),
}));
export const VediNoteImportProcessingFailedAlertSchema = VediImportProcessingFailedAlertSchema.merge(z.object({
    metadata: VediImportProcessingFailedAlertSchema.shape.metadata.merge(z.object({
        importType: z.literal(VediImportType.note),
        noteId: NoteCornSchema,
    })),
}));
export const VeriskClaimAssignmentFailedAlertSchema = VediImportProcessingFailedAlertSchema.merge(z.object({
    metadata: VediImportProcessingFailedAlertSchema.shape.metadata.merge(z.object({
        importType: z.literal(VediImportType.assignment),
    })),
}));
export const VeriskClaimAssignRecipientFailedAlertSchema = VediImportProcessingFailedAlertSchema.merge(z.object({
    metadata: VediImportProcessingFailedAlertSchema.shape.metadata.merge(z.object({
        importType: z.literal(VediImportType.assignRecipient),
        xactnetId: z.string(),
    })),
}));
