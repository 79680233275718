import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types';
import { PublicationTargetsSchema } from '@eigtech/documents-types';
import { CornSchema } from '@eigtech/shared-corn';
import { z } from 'zod';
export const PublishScreenshotsRequestBodySchema = z.object({
    publishTo: PublicationTargetsSchema.array(),
    dateAdded: z.array(z.string().datetime()).min(1).max(500),
});
export const PublishScreenshotsRequestPathSchema = z.object({
    entityId: CornSchema,
    startTime: z.string(),
});
export const PublishScreenshotsRequestHeaderSchema = ApiLambdalerAuthHeaderSchema;
export const PublishScreenshotsRequestSchema = PublishScreenshotsRequestPathSchema.merge(PublishScreenshotsRequestHeaderSchema).merge(PublishScreenshotsRequestBodySchema);
