import { parsePhoneNumber } from 'awesome-phonenumber';
import { startCase } from 'lodash-es';
import z, { ZodIssueCode } from 'zod';
import { isDefined } from './filter.js';
import { isISOish } from './time.js';
export const OptionalStringSchema = z
    .optional(z.string())
    .transform((str) => (!str ? undefined : str));
export const QueryModelSchema = z.object({
    PK: z.string(),
    SK: z.string(),
    'GSI-PK-1': z.string().optional(),
    'GSI-SK-1': z.string().optional(),
});
export const fromQueryModel = ({ PK: __PK, SK: __SK, 'GSI-PK-1': __gsiPk1, 'GSI-SK-1': __gsiSk1, ...record }) => record;
export const isParseSuccess = (parseResults) => parseResults.success;
export const PhoneNumberSchema = z.string().refine((phone) => {
    const nationalNumber = parsePhoneNumber(phone, {
        regionCode: 'US',
    });
    const plainNumber = parsePhoneNumber(phone);
    return nationalNumber.valid || plainNumber.valid;
}, { message: 'unable to parse phone number' });
export const PhoneNumberOptionalSchema = z
    .string()
    .optional()
    .refine((phone) => !phone || PhoneNumberSchema.safeParse(phone).success, {
    message: 'unable to parse phone number',
});
export const SoftDateSchema = z.string().refine(isISOish, { message: 'Not nearly ISO enough' });
export const friendlyErrorMap = (issue, ctx) => {
    const defaultMessage = issue.message ?? ctx.defaultError;
    // check if a path is attribute or index based
    // ignoring index based paths, unless we want to convert them to ordinal numbers,
    const issuePath = issue.path
        .map((i) => (typeof i === 'string' ? startCase(i) : undefined))
        .filter(isDefined)
        .join(' ');
    switch (issue.code) {
        case ZodIssueCode.too_big:
            return {
                message: `${issuePath} must be greater than${issue.inclusive ? ' or equal' : ''} to ${issue.maximum}`,
            };
        case ZodIssueCode.too_small:
            return {
                message: `${issuePath} must be less than${issue.inclusive ? ' or equal' : ''} to ${issue.minimum}`,
            };
        case ZodIssueCode.invalid_type: {
            // common validation messages
            // "Invalid input: expected string, received number"
            // "Required"
            const isRequired = issue.message?.toLowerCase().startsWith('required') || issue.received === 'undefined';
            const isInvalid = issue.received !== issue.expected;
            let message;
            if (isRequired)
                message = `${issuePath} is required`;
            else if (isInvalid)
                message = `${issuePath} is invalid`;
            else
                message = defaultMessage;
            return {
                message,
            };
        }
        default: {
            return {
                message: defaultMessage,
            };
        }
    }
};
/**
 * Zod's `record` when used with an `enum` key type unfortunately makes every key & value optional,
 * with no ability to override that or e.g. set `default` values:
 * https://github.com/colinhacks/zod/issues/2623
 *
 * So this helper generates an `object` schema instead, with every key required by default and
 * mapped to the given value schema. You can then call `partial()` to behave like Zod's `record`,
 * but you can also set `default()` on the value schema to have a default value per omitted key.
 * This also achieves an exhaustive key check similar to TypeScript's `Record` type.
 */
export function zodRecordWithEnum(enumSchema, valueSchema) {
    return z.object(
    // TODO: Why is this explicit generic parameter needed / `enumSchema.options` typed as `any`?
    _zodShapeWithKeysAndValue(enumSchema.options, valueSchema));
}
function _zodShapeWithKeysAndValue(keys, valueSchema) {
    return Object.fromEntries(keys.map((key) => [key, valueSchema])
    // HACK: This explicit cast is needed bc `Object.fromEntries()` loses precise typing of keys
    // (even with `as [keyof PropsType, ValueType][]` on the `Object.keys(...).map(...)` above).
    // Wish Zod had a helper for mapped types similar to TypeScript.
    );
}
